import {
  combineReducers,
  configureStore,
  getDefaultMiddleware,
} from "@reduxjs/toolkit";
import {
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from "redux-persist";
import storage from "redux-persist/lib/storage";

import logger from "redux-logger";
import { authSlice } from "../redux/auth/slices";
import { ressourcesSlice } from "../redux/ressources/slices";
import { snackbarSlice } from "./../redux/snackbar/slices";
import { userSlice } from "../redux/user/slices";
import { buildingsWorkspacesSlice } from "../redux/buildings/slices";
import { workspaceSlice } from "../redux/workspaces/slices";

const persistConfig = {
  key: "root",
  version: 1,
  storage,
};

const reducer = combineReducers({
  auth: authSlice.reducer,
  snackbar: snackbarSlice.reducer,
  ressources: ressourcesSlice.reducer,
  user: userSlice.reducer,
  buildingsWorkspaces: buildingsWorkspacesSlice.reducer,
  workspace: workspaceSlice.reducer,
});

const rootReducer = (state, action) => {
  if (action.type === "authUser/clearAuthData") {
    state = undefined;
  }
  return reducer(state, action);
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

// const middleware = [...getDefaultMiddleware(), logger];

export default configureStore({
  reducer: persistedReducer,
  middleware: getDefaultMiddleware({
    logger,
    serializableCheck: {
      ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
    },
  }),
});
