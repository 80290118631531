// REACT
import React, { useEffect } from "react";
// REACT ROUTER DOM
import { Switch, Route } from "react-router-dom";
// COMPONENTS
import Layout from "./components/Layout/Layout";
import BackOffice from "./components/Layout/BackOffice";
import PrivateRoute from "./components/PrivateRoute/PrivateRoute";
// PROMISE BASED HTTP
import axios from "axios";
// CONFIG
import { API_URL } from "./config/settings";
// REDUX
import { useDispatch, useSelector } from "react-redux";
import { hydrateStore, hydrateStoreForRessources } from "./redux/hydrate";
import { authData } from "./redux/auth/slices";
import { selectedUser } from "./redux/user/slices";
// APP STYLE
import "./assets/styles/App.scss";
// PAGES
import Login from "./pages/Login/Login";
import SelectUser from "./pages/SelectUser/SelectUser";
import Dashboard from "./pages/Dashboard/Dashboard";
import Workspaces from "./pages/Workspaces/Workspaces";
import CreateWorkspace from "./pages/Workspaces/CreateWorkspace/CreateWorkspace";
import Buildings from "./pages/Buildings/Buildings";
import CreateBuilding from "./pages/Buildings/CreateBuilding/CreateBuilding";
import Building from "./pages/Buildings/Building/Building";
import Workspace from "./pages/Workspaces/Workspace/Workspace";
import Profile from "./pages/Profile/Profile";
import Associations from "./pages/Associations/Associations";
import Advertisement from "./pages/Advertisement/Advertisement";

/**
 * Entry point of the application
 * @returns Component
 */
function App() {
  // REDUX HOOKS
  const dispatch = useDispatch();
  // IS AUTHENTIFIED
  const authUser = useSelector(authData);
  // THE SELECTED USER
  const user = useSelector(selectedUser);
  // AXIOS BASE URL USED FOR EVERY API CALL
  axios.defaults.baseURL = API_URL[authUser.api];

  /**
   * Fetch ressources datas on app load
   */
  useEffect(() => {
    if (authUser.isAuthenticated) {
      try {
        hydrateStoreForRessources(dispatch);
      } catch (e) {
        console.error(e);
      }
    }
  }, [dispatch, authUser.isAuthenticated]);

  /**
   * Fetch all data when user is selected
   */
  useEffect(() => {
    if (user) {
      try {
        hydrateStore(dispatch);
      } catch (e) {
        console.error(e);
      }
    }
  }, [dispatch, user]);

  return (
    <>
      {/* <CssBaseline /> */}
      <Layout>
        <Switch>
          <Route exact path="/" component={Login} />
          <Route exact path="/select-user" component={SelectUser} />

          <BackOffice>
            <PrivateRoute path="/dashboard" component={Dashboard} />
            <PrivateRoute path="/buildings" component={Buildings} />
            <PrivateRoute path="/building/:id" component={Building} />
            <PrivateRoute path="/createBuilding" component={CreateBuilding} />
            <PrivateRoute path="/workspaces" component={Workspaces} />
            <PrivateRoute path="/workspace/:id?" component={Workspace} />
            <PrivateRoute path="/createWorkspace" component={CreateWorkspace} />
            <PrivateRoute path="/profile/:id?" component={Profile} />
            <PrivateRoute path="/associations" component={Associations} />
            <PrivateRoute path="/publicite/:id?" component={Advertisement} />
          </BackOffice>
        </Switch>
      </Layout>
    </>
  );
}

export default App;
