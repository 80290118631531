import { buildingsWorkspacesSlice } from "./slices";

/**
 * Actions of buildingsWorkspacesSlice
 */
export const {
  setBuildings,
  setBuilding,
  pushBuilding,
  updateSelectedBuilding,
  deleteSelectedBuilding,
  removeWorkspaceFromBuilding,
  removeWorkspaceFromBuildings,
  updateWorkspaceInBuildings,
  setLoading,
} = buildingsWorkspacesSlice.actions;
