// REACT
import React, { useEffect, useRef, useState } from "react";
// REACT ROUTER
import { useHistory } from "react-router-dom";
// COMPONENTS
import UserCard from "../../UserCard/UserCard";
import ButtonValid from "./../../Buttons/ButtonValid";
// UTILS
import { formatUserName } from "../../../utils/formatters";
// MATERIAL UI
import SearchIcon from "@material-ui/icons/Search";
import {
  Typography,
  makeStyles,
  Drawer,
  Divider,
  CircularProgress,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
} from "@material-ui/core";
// REDUX
import { useDispatch, useSelector } from "react-redux";
import { searchUser } from "../../../redux/auth/thunks";
import { selectedWorkspace } from "../../../redux/workspaces/slices";
import { changeWorkspaceOwner } from "../../../redux/workspaces/thunks";
import { setSnackbar } from "../../../redux/snackbar/actions";
import { updateCurrentWorkspace } from "../../../redux/workspaces/actions";

function WorkspaceProperty() {
  // REDUX HOOKS
  const dispatch = useDispatch();
  const workspace = useSelector(selectedWorkspace);
  // MATERIAL UI
  const classes = useStyles();
  // REACT ROUTER
  const history = useHistory();
  // REACT HOOKS
  const [search, setSearch] = useState("");
  const [users, setUsers] = useState([]);
  const [selectedUser, setSelectedUser] = useState({});
  const [loading, setLoading] = useState(false);
  const [openConfirmModal, setOpenConfirmModal] = useState(false);
  const [cannotChangePropertyModal, setOpenCannotChangePropertyModal] =
    useState(false);
  const [state, setState] = useState(false);

  const toggleDrawer = (anchor, open) => (event) => {
    console.log("CLICK");
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState(open);
  };

  /**
   * Set search state
   */
  const handleSearch = (e) => {
    setSearch(e.target.value);
  };

  /**
   * Timer reference variable, remains active even with re-rendering
   */
  const timeout = useRef(null);
  /**
   * Function to send the user to search to the function calling the api.
   * We send data only when the user stops writing after 1s
   */
  useEffect(() => {
    let mounted = true;
    if (mounted) {
      if (search?.trim().length !== 0) {
        setLoading(true);
        // We pass the setTimeout function to our reference,
        // in order to remove it once the components are rendered
        timeout.current = setTimeout(() => {
          dispatch(searchUser(0, search)).then((res) => {
            if (res.status === 200) {
              // If everything is ok, we update our state with the returned users
              const entities = res.data.entities.map(
                (entity) => entity.entity.user
              );
              setUsers([...entities]);
              setLoading(false);
            }
          });
        }, 1000);
      } else {
        setUsers([]);
        setLoading(false);
      }
    }
    return () => {
      mounted = false;
      // We clear the timer that points to the reference at each re-render
      clearTimeout(timeout.current);
    };
  }, [search, dispatch]);

  /**
   * Allow us to select user for replacing workspace owner
   * @param {*object} user DTOUser of selected user
   */
  const handleSelectUser = (user) => {
    setSelectedUser(user);
  };

  /**
   * Open the modal that explain we cannot change owner if workspace is linked to building
   */
  const handleOpenCannotChangePropertyModal = () => {
    setOpenCannotChangePropertyModal(true);
  };

  /**
   * Close the modal explanation
   */
  const handleCloseCannotChangePropertyModal = () => {
    setOpenCannotChangePropertyModal(false);
  };

  /**
   * Open the confirmation modal for changing workspace owner
   */
  const handleOpenConfirmModal = () => {
    setOpenConfirmModal(true);
  };

  /**
   * Close the confirmation modal and unselecte user selected
   */
  const handleCloseConfirmModal = () => {
    setOpenConfirmModal(false);
    setSelectedUser({});
  };

  /**
   * Submit to api the selected user for changing workspace owner
   */
  const handleSubmit = (e) => {
    e.preventDefault();

    if (selectedUser) {
      dispatch(changeWorkspaceOwner(workspace.key, selectedUser.key)).then(
        (res) => {
          if (res.status === 200) {
            dispatch(updateCurrentWorkspace(res.data));
            dispatch(
              setSnackbar({
                open: true,
                snackbarMessage: `La propriété de l'espace à bien été transféré à ${formatUserName(
                  selectedUser
                )}`,
              })
            );
            setUsers([]);
            setLoading(false);
            handleCloseConfirmModal();
            history.push("/workspaces");
          }
        }
      );
    }
  };

  return (
    <>
      <div>
        <Typography>Propriétaire actuel:</Typography>
        <UserCard user={workspace?.owner} />
        <div className={classes.changeOwnerButton}>
          {workspace?.buildings.length > 0 ? (
            <ButtonValid
              onClick={handleOpenCannotChangePropertyModal}
              title="Changer de propriétaire"
            />
          ) : (
            <ButtonValid
              onClick={toggleDrawer("right", true)}
              title="Changer de propriétaire"
            />
          )}
        </div>
      </div>
      {/* SELECT NEW OWNER DRAWER */}
      <Drawer
        anchor="right"
        open={state}
        onClose={toggleDrawer("right", false)}
        className={classes.drawer}
      >
        <div className={classes.searchContainer}>
          <div className={classes.selectUser}>
            <SearchIcon className={classes.selectUserIcon} />
            <input
              type="search"
              name=""
              id=""
              onChange={handleSearch}
              className={classes.selectUserInput}
              placeholder="Rechercher un utilisateur..."
            />
          </div>
        </div>
        <Divider />
        <div className={classes.drawerContent}>
          {loading ? (
            <div className={classes.spinner}>
              <CircularProgress />
            </div>
          ) : (
            users
              ?.filter((user) => user.key !== workspace?.owner.key)
              ?.map((user) => (
                <UserCard
                  key={user.key}
                  user={user}
                  speciality
                  onClick={() => handleSelectUser(user)}
                  style={{
                    background: Object.values(selectedUser).includes(user.key)
                      ? "#eee"
                      : "",
                  }}
                />
              ))
          )}
        </div>
        <ButtonValid onClick={handleOpenConfirmModal} title="Valider" />
      </Drawer>
      {/* EXPLANATION MODAL */}
      <Dialog
        open={cannotChangePropertyModal}
        onClose={handleCloseCannotChangePropertyModal}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">Action non autorisé</DialogTitle>
        <Divider />
        <DialogContent>
          <div className={classes.dialogContent}>
            Avant de pouvoir changer la proriété de l'espace, veuillez d'abord
            supprimer les liens entre votre espace et les établissements
          </div>
        </DialogContent>
        <Divider />
        <DialogActions>
          <Button
            onClick={handleCloseCannotChangePropertyModal}
            style={{ width: "50%" }}
          >
            Fermer
          </Button>
        </DialogActions>
      </Dialog>
      {/* CONFIRMATION MODAL */}
      <Dialog
        open={openConfirmModal}
        onClose={handleCloseConfirmModal}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">
          Changement de propriété
        </DialogTitle>
        <Divider />
        <DialogContent>
          <div className={classes.dialogContent}>
            Confirmez le changement du propriétaire de l'espace{" "}
            {workspace?.title}
            <br />
            De <strong>{formatUserName(workspace?.owner)}</strong>
            <br />A <strong>{formatUserName(selectedUser)}</strong>
          </div>
        </DialogContent>
        <Divider />
        <DialogActions>
          <Button onClick={handleCloseConfirmModal} style={{ width: "50%" }}>
            Annuler
          </Button>
          <ButtonValid
            onClick={handleSubmit}
            title="Confirmer"
            style={{ width: "50%" }}
          />
        </DialogActions>
      </Dialog>
    </>
  );
}

const drawerWidth = 400;

const useStyles = makeStyles(({ palette }) => ({
  drawer: {
    position: "relative",
    display: "flex",
    flexDirection: "column",
    width: drawerWidth,
  },
  drawerTitle: {
    padding: 10,
    width: drawerWidth,
    textAlign: "center",
    margin: "0 auto",
  },
  spinner: {
    height: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: drawerWidth,
  },
  drawerContent: {
    overflowY: "auto",
    flex: 1,
    width: drawerWidth,
  },
  searchContainer: {
    // background: "#eee",
    padding: 10,
    width: drawerWidth,
  },
  selectUser: {
    display: "flex",
    alignItems: "center",
    background: "#eee",
    // width: "90%",
    // margin: "auto",
    border: "1px solid #ccc",
    borderRadius: 20,
    padding: "5px 10px",
  },
  selectUserInput: {
    border: "none",
    outline: "none",
    background: "none",
    width: "100%",
    "&:focus": {
      outline: "none",
    },
  },
  changeOwnerButton: {
    display: "flex",
    justifyContent: "center",
    marginTop: 20,
  },
  drawerButton: {
    position: "absolute",
    width: "100%",
    bottom: 0,
    backgroundColor: palette.primary.main,
    color: "white",
    transition: "color .3s ease",
    "&:hover": {
      color: palette.primary.main,
    },
  },
  dialogContent: {
    minWidth: 500,
    padding: 20,
    textAlign: "center",
  },
}));

export default WorkspaceProperty;
