// REACT
import React, { useState } from "react";
// REACT ROUTER
import { useHistory } from "react-router";
// MATERIAL UI
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  InputLabel,
  Select,
  TextField,
  Typography,
} from "@material-ui/core";
// COMPONENTS
import ButtonValid from "../../Buttons/ButtonValid";
// HOOKS
import useBuildingType from "../../../hooks/useBuildingType";
// REDUX
import { useDispatch } from "react-redux";
import { createBuilding } from "../../../redux/buildings/thunks";
import { pushBuilding } from "../../../redux/buildings/actions";
import { setSnackbar } from "../../../redux/snackbar/actions";

function AddBuilding({ building }) {
  // REDUX HOOKS
  const dispatch = useDispatch();
  // REACT ROUTER HOOKS
  const history = useHistory();
  // CUSTOM HOOKS
  const { buildingType } = useBuildingType();
  // REACT STATE
  const [buildingForm, setBuildingForm] = useState({
    categorie: "",
    display: false,
    parent: building?.key,
    type: "",
    name: "",
    nameShort: "",
    externalCode: "",
    street: "",
    city: "",
    pbox: "",
  });

  /**
   * Change the state of the building
   * @param {*string} name fields name
   * @param {*string} value fields value
   */
  const handleChange = ({ target: { name, value } }) => {
    const updatedBuilding = { ...buildingForm };
    if (name === "display") {
      updatedBuilding[name] = !updatedBuilding[name];
    } else {
      updatedBuilding[name] = value;
    }
    setBuildingForm(updatedBuilding);
  };

  /**
   * Function to create a building,
   * after creation it redirects to the newly created building
   */
  const handleSubmit = (e) => {
    e.preventDefault();
    const buildingParameters = {
      type: buildingForm.type,
      display: buildingForm.display,
      name: buildingForm.name,
      nameShort: buildingForm.nameShort,
      externalCode: buildingForm.externalCode,
      parent: buildingForm.parent,
      address: {
        street: buildingForm.street,
        city: buildingForm.city,
        pbox: buildingForm.pbox,
      },
    };

    dispatch(createBuilding(buildingParameters)).then((res) => {
      if (res.status === 200) {
        dispatch(pushBuilding(res.data));
        dispatch(
          setSnackbar({
            open: true,
            snackbarMessage: "L'établissement à bien été crée",
          })
        );
        history.push(`/building/${res.data.key}`);
      }
    });
  };

  return (
    <div className="createBuilding__container">
      <Typography style={{ textAlign: "center" }} color="primary">
        L'établissement que vous allez créer sera rattaché à{" "}
        <strong>{building.name}</strong>
      </Typography>
      <form onSubmit={handleSubmit} className="createBuilding__form">
        <fieldset className="createBuilding__fieldset">
          {/* <FormControl variant="outlined" className="createBuilding__fields">
            <InputLabel htmlFor="outlined-age-native-simple">
              Catégorie d'établissement'
            </InputLabel>
            <Select
              native
              value={buildingForm?.categorie}
              onChange={handleChange}
              label="Catégorie d'établissement"
              inputProps={{
                name: "categorie",
                id: "outlined-age-native-simple",
              }}
              size="small"
            >
              <option value="1">Institution</option>
              <option value="2">CPTS</option>
              <option value="3">HEPAD</option>
              <option value="4">Etablissement</option>
            </Select>
          </FormControl> */}

          <div className="createBuilding__fields">
            <TextField
              error={false}
              label="Rattaché à"
              id="outlined-error-helper-text"
              placeholder="Parent de l'établissement"
              variant="outlined"
              value={building?.name}
              InputLabelProps={{
                shrink: true,
              }}
              size="small"
              fullWidth
              disabled
            />
            {/* <TextField name="parent" value={building?.key} type="hidden" /> */}
          </div>

          <FormControl variant="outlined" className="createBuilding__fields">
            <InputLabel htmlFor="outlined-age-native-simple2">
              Type d'établissement
            </InputLabel>
            <Select
              native
              value={buildingForm?.type}
              onChange={handleChange}
              label="Type d'établissement"
              inputProps={{
                name: "type",
                id: "outlined-age-native-simple2",
              }}
            >
              {buildingType.map((building) => (
                <option value={building.key} key={building.key}>
                  {building.label}
                </option>
              ))}
            </Select>
          </FormControl>

          <div className="createBuilding__fields">
            <TextField
              error={false}
              label="Nom"
              id="outlined-error-helper-text"
              placeholder="Nom de l'établissement"
              helperText="* Le nom de l'établissement est obligatoire"
              variant="outlined"
              name="name"
              value={buildingForm?.name}
              InputLabelProps={{
                shrink: true,
              }}
              size="small"
              fullWidth
              required
              onChange={handleChange}
            />
          </div>

          <div className="createBuilding__fields">
            <TextField
              error={false}
              label="Nom court"
              id="outlined-error-helper-text"
              placeholder="Nom court de l'établissement"
              helperText="* BCH, GHN, SALP..."
              variant="outlined"
              name="nameShort"
              value={buildingForm?.nameShort}
              InputLabelProps={{
                shrink: true,
              }}
              size="small"
              fullWidth
              required
              onChange={handleChange}
            />
          </div>

          <div className="createBuilding__fields">
            <TextField
              label="Code externe"
              id="outlined-error-helper-text"
              placeholder="Code externe"
              variant="outlined"
              name="externalCode"
              helperText="* BCH, GHN, SALP..."
              InputLabelProps={{
                shrink: true,
              }}
              value={buildingForm?.externalCode}
              size="small"
              fullWidth
              onChange={handleChange}
            />
          </div>
        </fieldset>
        <fieldset className="createBuilding__fieldset createBuilding__fieldset--address">
          <div className="createBuilding__fields">
            <TextField
              multiline
              label="Adresse (facultatif )"
              id="outlined-error-helper-text"
              placeholder="Adresse de l'établissement"
              variant="outlined"
              name="street"
              value={buildingForm?.street}
              InputLabelProps={{
                shrink: true,
              }}
              size="small"
              fullWidth
              className="createBuilding__fields"
              onChange={handleChange}
            />
          </div>
          <div className="createBuilding__fields">
            <TextField
              label="Ville"
              id="outlined-error-helper-text"
              placeholder="Ville"
              variant="outlined"
              name="city"
              value={buildingForm?.city}
              InputLabelProps={{
                shrink: true,
              }}
              size="small"
              fullWidth
              className="createBuilding__fields"
              onChange={handleChange}
            />
          </div>
          <div className="createBuilding__fields">
            <TextField
              label="Code postal"
              id="outlined-error-helper-text"
              placeholder="Code postal"
              variant="outlined"
              name="pbox"
              value={buildingForm?.pbox}
              InputLabelProps={{
                shrink: true,
              }}
              size="small"
              fullWidth
              className="createBuilding__fields"
              onChange={handleChange}
            />
          </div>
          <div className="building__fields">
            <FormControlLabel
              control={
                <Checkbox
                  checked={buildingForm.display}
                  onChange={handleChange}
                  name="display"
                />
              }
              label="Affichable"
              className="building__fields"
            />
          </div>
        </fieldset>

        <div className="text-center">
          <ButtonValid
            type="submit"
            name="createBuilding"
            title="Enregistrer"
            style={{ width: "100%" }}
          />
        </div>
      </form>
    </div>
  );
}

export default AddBuilding;
