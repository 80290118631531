// REACT
import React from "react";
// REACT ROUTER
import { NavLink, useHistory } from "react-router-dom";
// MATERIAL UI
import {
  Button,
  ClickAwayListener,
  MenuItem,
  MenuList,
  Paper,
  Popper,
  Tooltip,
  Zoom,
} from "@material-ui/core";
import SettingsOutlinedIcon from "@material-ui/icons/SettingsOutlined";
import ForumOutlinedIcon from "@material-ui/icons/ForumOutlined";
// ASSETS
import BUILDINGS from "../../assets/images/hospital.png";
import WORKSPACES from "../../assets/images/workspaces.png";
import ASSOCIATIONS from "../../assets/images/associations.png";
// REDUX
import { logout } from "../../redux/auth/thunks";
import { useDispatch, useSelector } from "react-redux";
import { selectedUser } from "../../redux/user/slices";
import { clearUser } from "../../redux/user/actions";

function Navbar() {
  // REDUX HOOKS
  const dispatch = useDispatch();
  const user = useSelector(selectedUser);
  // REACT ROUTER HOOKS
  const history = useHistory();
  // REACT STATE
  const [open, setOpen] = React.useState(false);
  // REF SETTING MENU
  const anchorRef = React.useRef(null);
  const prevOpen = React.useRef(open);

  /**
   * Open Setting Menu
   */
  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  /**
   * Close setting Menu
   * @param {*ReactEvent} event
   * @returns bool
   */
  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  };

  /**
   * Close setting Menu
   * @param {*ReactEvent} event
   * @returns bool
   */
  const handleChangeUser = (event) => {
    dispatch(clearUser());
    handleClose(event);
  };

  /**
   * Close setting Menu on click/tab
   * @param {*} event
   */
  function handleListKeyDown(event) {
    if (event.key === "Tab") {
      event.preventDefault();
      setOpen(false);
    }
  }

  React.useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }
    prevOpen.current = open;
  }, [open]);

  return (
    <nav className="navbar">
      <ul className="navbar__links">
        <li className="nav-item dropdown">
          <Tooltip title="Etablissement">
            <NavLink to="/buildings">
              <img src={BUILDINGS} alt="Etablissement" />
            </NavLink>
          </Tooltip>
        </li>
        <li className="nav-item dropdown">
          <Tooltip title="Espaces">
            <NavLink to="/workspaces">
              <img src={WORKSPACES} alt="Services" />
            </NavLink>
          </Tooltip>
        </li>
        <li className="nav-item dropdown">
          <Tooltip title="Associations d'espaces">
            <NavLink to="/associations">
              <img src={ASSOCIATIONS} alt="Associations de groupe" />
            </NavLink>
          </Tooltip>
        </li>
        <li className="nav-item dropdown">
          <Tooltip title="Créer une discussion publicitaire">
            <NavLink to="/publicite">
              <ForumOutlinedIcon />
            </NavLink>
          </Tooltip>
        </li>
      </ul>
      <Button
        ref={anchorRef}
        aria-controls={open ? "menu-list-grow" : undefined}
        aria-haspopup="true"
        onClick={handleToggle}
        className="navbar__settings"
      >
        <SettingsOutlinedIcon />
      </Button>
      <Popper
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
      >
        {({ TransitionProps, placement }) => (
          <Zoom
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === "top" ? "center bottom" : "center top",
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList
                  autoFocusItem={open}
                  id="menu-list-grow"
                  onKeyDown={handleListKeyDown}
                >
                  <MenuItem>
                    <NavLink to="/select-user" onClick={handleChangeUser}>
                      Changer d'utilisateur
                    </NavLink>
                  </MenuItem>
                  <MenuItem
                    onClick={() => history.push(`/profile/${user?.key}`)}
                  >
                    <NavLink to={`/profile/${user?.key}`} onClick={handleClose}>
                      Profil
                    </NavLink>
                  </MenuItem>
                  <MenuItem
                    onClick={() => {
                      dispatch(logout());
                      history.push("/");
                    }}
                  >
                    Déconnexion
                  </MenuItem>
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Zoom>
        )}
      </Popper>
    </nav>
  );
}

export default Navbar;
