import React from "react";
import { Plus } from "../../assets/svg/Plus";

function CreateBuildingCard({ onClick }) {
  return (
    <div className="createBuildingCard" onClick={onClick}>
      <div className="createBuildingCard__container">
        <div className="createBuildingCard__svg">
          <Plus />
        </div>
      </div>
    </div>
  );
}

export default CreateBuildingCard;
