import { getDeviceid } from "./device";

export const settings = {
  version: "BO",
  number: "2",
  deviceUid: getDeviceid(),
  sid: localStorage.getItem("SID"),
};

export const API_URL = {
  dev: "https://dev.teamdoc.fr/td-main/v1/",
  preprod: "https://preprod.teamdoc.fr/td-main/v1/",
  preprodip: "10.88.116.74:8080/td-main/v1/",
  prod: "https://api.teamdoc.app/td-main/v1/",
  prodip: "10.88.116.80:8080/td-main/v1/",
};

export const WHITE_LIST = [
  "tmartin@teamdoc.fr",
  "lvallee@teamdoc.fr",
  "dominique.vallee@cegetel.net",
  "dvallee@teamdoc.fr",
  "sacha@teamdoc.fr",
  "mickael@teamdoc.fr",
  "etienne.hinglais@aphp.fr",
  "teamdoc@teamdoc.fr",
  "tristan.thibaultsogorb@gmail.com",
  "kxu@teamdoc.fr",
];
