// REACT
import React from "react";
// MATERIAL UI
import { Avatar, Typography } from "@material-ui/core";
// FUNCTIONS & UTILS
import { getSpeciality as getSpecies } from "../../functions";
import { formatUserName } from "../../utils/formatters";
// REDUX
import { useSelector } from "react-redux";
import { getSpecialities } from "../../redux/ressources/slices";

/**
 * @param {*Object} user DTOUser
 * @returns User Cardview
 */
function UserCard({ user, speciality, onClick, style, lineRef }) {
  // REDUX HOOKS
  const specialities = useSelector(getSpecialities);

  return (
    <div className="userCard" onClick={onClick} style={style} ref={lineRef}>
      <div className="userCard__identityContainer">
        <div
          className={`userCard__avatarContainer
        ${
          user?.profileStatus === 3 && user?.profileOrigin === 50
            ? "ecps"
            : user?.profileStatus === 3
            ? "td"
            : "bordered"
        }`}
        >
          <Avatar
            src={user.avatar || user.avatarThumb}
            className="userCard__avatar"
            color="secondary"
          >
            {user.initiales}
          </Avatar>
        </div>
        <div className="userCard__identityInfos">
          <div className="userCard__nameContainer">
            <Typography className="userCard__name">
              {formatUserName(user)}
            </Typography>
            {speciality && (
              <Typography className="userCard__speciality">
                {getSpecies(user.speciality, specialities) ||
                  "Pas de spécialité"}
              </Typography>
            )}
          </div>
          <Typography className="userCard__email">{user.email}</Typography>
        </div>
      </div>
    </div>
  );
}

export default UserCard;
