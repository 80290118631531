export const BOAUTHENTICATION = {
  authenticate: "authentications/bo",
};

export const BOUSER = {
  user: "bo/users/",
  updateLicense: "bo/users/license/",
  search: "search/",
};

export const BOSESSION = {
  logout: "sessions/",
};

export const BOCHATROOMS = {
  getAdvertisementChatrooms: "bo/messages/",
  createAdvertisementChatroom: "bo/messages/",
  updateAdvertisementChatroom: "bo/messages/",
  deleteAdvertisementChatroom: "bo/messages/",
};
export const BOBUILDINGS = {
  getBuildingsWorkspacesForUser: "bo/users/",
  buildings: "bo/buildings/",
  updateAvatar: "bo/buildings/avatar/",
};

export const RESSOURCES = {
  getInitRessources: "/res/init",
  getSpeciality: "/res/specialities/",
};

export const BOWORKSPACES = {
  workspaces: "/bo/workspaces/",
  search: "/bo/workspaces/search",
  updateWorkspacesCrossLinks: "/bo/links/",
  getWorkspaceLinks: "/bo/links/",
};

export const BOUNITS = {
  units: "/bo/units/",
  deleteUnits: "/bo/units/units",
};

export const BOBEDS = {
  beds: "/bo/beds/",
  deleteBeds: "/bo/beds/beds",
};
