// REACT
import { useState, useEffect } from "react";
// REDUX
import { useSelector } from "react-redux";
import { getRessources } from "../redux/ressources/slices";

function useBuildingType() {
  // REDUX HOOKS
  const ressources = useSelector(getRessources);
  // REACT STATE
  const [buildingType, setBuildingType] = useState([]);

  /** Affichage des différentes clés et labels concernant les types d'établissement */
  useEffect(() => {
    if (ressources) {
      setBuildingType(
        ressources["building-type"]?.map((spe) => {
          const buildingKeyLabel = { key: spe.key, label: spe.label };
          return buildingKeyLabel;
        })
      );
    }
  }, [ressources]);

  return { buildingType };
}

export default useBuildingType;
