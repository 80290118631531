// REACT
import React from "react";
// MATERIAL UI
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import Avatar from "@material-ui/core/Avatar";
// ASSETS
import advertismentLogo from "../../assets/images/advertisment.png";

const Ads = ({ ads, setSelectedAds }) => {
  return (
    <ListItem button onClick={() => setSelectedAds(ads)}>
      <ListItemAvatar>
        <Avatar src={ads?.avatarThumb ?? advertismentLogo} />
      </ListItemAvatar>
      <ListItemText primary={ads?.title} />
    </ListItem>
  );
};

export default Ads;
