// REACT
import React, { useEffect, useState } from "react";
// MATERIAL UI
import {
  Checkbox,
  FormControl,
  InputLabel,
  Select,
  TextField,
  Divider,
  Button,
  FormControlLabel,
} from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
// COMPONENTS
import ButtonValid from "../../../components/Buttons/ButtonValid";
// UTILS
import { ELicence } from "../../../enums/enums";
import { formatBytes } from "../../../functions";
import { formatUserName } from "./../../../utils/formatters";
// REDUX
import { useDispatch, useSelector } from "react-redux";
import { profileUser } from "../../../redux/user/slices";
import {
  repairAccountlicense,
  updateLicense,
} from "./../../../redux/user/thunks";
import { updateLicenseUser } from "./../../../redux/user/actions";
import { setSnackbar } from "../../../redux/snackbar/actions";

function ProfileLicense() {
  // REDUX HOOKS
  const dispatch = useDispatch();
  const currentUser = useSelector(profileUser);
  // REACT STATE
  const [openConfirmModal, setOpenConfirmModal] = useState(false);
  const [error, setError] = useState(false);
  const [repair, setRepair] = useState(false);
  const [user, setUser] = useState({
    type: "",
    licenses_capacity: "",
    licenses_usage: "",
    medias_capacity: "",
    medias_usage: "",
    workspaces_capacity: "",
    workspaces_usage: "",
    transmissions_capacity: "",
    transmissions_usage: "",
  });

  useEffect(() => {
    setUser({
      type: currentUser?.license.type,
      licenses_capacity: currentUser?.license?.quotas?.licenses?.capacity || "",
      licenses_usage: currentUser?.license?.quotas?.licenses?.usage ?? 0,
      medias_capacity: currentUser?.license?.quotas?.medias?.capacity || "",
      medias_usage: currentUser?.license?.quotas?.medias?.usage || 0,
      workspaces_capacity:
        currentUser?.license?.quotas?.workspaces?.capacity || "",
      workspaces_usage: currentUser?.license?.quotas?.workspaces?.usage ?? 0,
      transmissions_capacity:
        currentUser?.license?.quotas?.transmissions?.capacity,
      transmissions_usage:
        currentUser?.license?.quotas?.transmissions?.usage || 0,
    });
  }, [currentUser]);

  const QUOTAS = {
    104857600: "100 Mo",
    2147483648: "2 Go",
    5368709120: "5 Go",
    10737418240: "10 Go",
    53687091200: "50 Go",
    107374182400: "100 Go",
    268435456000: "250 Go",
    536870912000: "500 Go",
    1649267441664: "1.5 To",
    2968681394995: "2.7 To",
    4617948836660: "4.2 To",
    10995116277760: "10 To",
    21990232555520: "20 To",
    38482906972160: "35 To",
  };

  const handleChange = ({ target: { name, value } }) => {
    const updatedUser = { ...user };
    if (name === "licenses_capacity") {
      setError(false);
    }
    // var regex = /[0-9]|\./;

    if (name === "type") {
      updatedUser["type"] = +value;
    } else {
      updatedUser[name] = value;
    }
    setUser(updatedUser);
  };

  const handleOpenConfirmModal = () => {
    // if (user.type !== currentUser?.license.type) {
    //   if (!user.licenses_capacity) {
    //     setError(true);
    //     return;
    //   } else {
    //     setOpenConfirmModal((conf) => !conf);
    //   }
    // } else
    if (user.type === ELicence.B2B) {
      if (!user.licenses_capacity) {
        setError(true);
        return;
      } else {
        setOpenConfirmModal((conf) => !conf);
      }
    } else {
      setOpenConfirmModal((conf) => !conf);
    }
  };

  const handleClose = () => {
    setOpenConfirmModal((conf) => !conf);
    setRepair(false);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (repair) {
      dispatch(repairAccountlicense(currentUser.key)).then((res) => {
        if (res && res.status === 200) {
          dispatch(updateLicenseUser(res.data));
          dispatch(
            setSnackbar({
              open: true,
              snackbarMessage: `La licence de ${formatUserName(
                currentUser
              )} a bien été réparée`,
            })
          );
          handleClose();
        }
      });
    } else {
      if (!error) {
        let userParameter;
        // SI ON CHANGE DE LICENSE 20 => 30, 30 => 20
        if (user.type !== currentUser?.license.type) {
          // SI ON A CHOISI TYPE 30
          if (user.type === ELicence.B2B) {
            userParameter = {
              licenseQuotas: {
                licenses: {
                  capacity: +user.licenses_capacity || 1000,
                  usage: 0,
                },
                medias: {
                  capacity: +user.medias_capacity,
                  usage: +user.medias_usage,
                },
                transmissions: {
                  capacity: -1,
                  usage: 0,
                },
                workspaces: {
                  capacity: +user.workspaces_capacity,
                  usage: +user.workspaces_usage,
                },
              },
              type: +user.type,
            };
          } else {
            // SI ON A CHOISI TYPE 20
            userParameter = {
              licenseQuotas: {
                licenses: null,
                medias: {
                  capacity: +user.medias_capacity,
                  usage: +user.medias_usage,
                },
                transmissions: {
                  capacity: 25,
                  usage: 0,
                },
                workspaces: {
                  capacity: +user.workspaces_capacity,
                  usage: +user.workspaces_usage,
                },
              },
              type: +user.type,
            };
          }
          // SI ON A RIEN TOUCHE
        } else {
          // SI TYPE 30
          if (user.type === ELicence.B2B) {
            userParameter = {
              licenseQuotas: {
                licenses: {
                  capacity: +user.licenses_capacity,
                  usage: +user.licenses_usage,
                },
                medias: {
                  capacity: +user.medias_capacity,
                  usage: +user.medias_usage,
                },
                transmissions: {
                  capacity: +user.transmissions_capacity,
                  usage: +user.transmissions_usage,
                },
                workspaces: {
                  capacity: +user.workspaces_capacity,
                  usage: +user.workspaces_usage,
                },
              },
              type: +user.type,
            };
          } else {
            // SI TYPE 20
            userParameter = {
              licenseQuotas: {
                licenses: null,
                medias: {
                  capacity: +user.medias_capacity,
                  usage: +user.medias_usage,
                },
                transmissions: {
                  capacity: +user.transmissions_capacity,
                  usage: +user.transmissions_usage,
                },
                workspaces: {
                  capacity: +user.workspaces_capacity,
                  usage: +user.workspaces_usage,
                },
              },
              type: +user.type,
            };
          }
        }

        dispatch(updateLicense(currentUser.key, userParameter)).then((res) => {
          if (res.status === 200) {
            dispatch(updateLicenseUser(res.data));
            dispatch(
              setSnackbar({
                open: true,
                snackbarMessage: `La licence de ${formatUserName(
                  currentUser
                )} a bien été modifié`,
              })
            );
            handleClose();
          }
        });
      } else {
        handleClose();
      }
    }
  };

  return (
    <>
      <form className="profile__form">
        <fieldset className="profile__fieldset">
          <div className="profile__fields profile__fields">
            <FormControl
              variant="outlined"
              className="profile__fields--field"
              fullWidth
            >
              <InputLabel htmlFor="outlined-age-native-simple">
                Changement de licence
              </InputLabel>
              <Select
                native
                value={user.type}
                onChange={handleChange}
                label="Changement de licence"
                inputProps={{
                  name: "type",
                  id: "outlined-age-native-simple",
                }}
                size="small"
                disabled={repair}
              >
                <option value="20">PRO</option>
                <option value="30">B2B</option>
              </Select>
            </FormControl>
          </div>
          {user.type === ELicence.B2B && (
            <div className="profile__fields profile__fields--dual">
              <div className="profile__fields--field">
                <TextField
                  error={error}
                  label="Capacité de licences"
                  id="outlined-error-helper-text"
                  placeholder="Capacité de licences"
                  variant="outlined"
                  name="licenses_capacity"
                  value={user.licenses_capacity}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  size="small"
                  fullWidth
                  required
                  type="number"
                  onChange={handleChange}
                  disabled={repair}
                />
              </div>
              <div className="profile__fields--field">
                <TextField
                  label="Licenses utilisées"
                  id="outlined-error-helper-text"
                  placeholder="Licences utilisées"
                  variant="outlined"
                  name="licences_usage"
                  value={+user.licenses_usage}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  size="small"
                  onChange={handleChange}
                  disabled
                  fullWidth
                />
              </div>
            </div>
          )}
          <div className="profile__fields profile__fields--dual">
            <div className="profile__fields--field">
              <FormControl
                variant="outlined"
                className="profile__fields--field"
                fullWidth
              >
                <InputLabel htmlFor="outlined-age-native-simple">
                  Capacité de médias
                </InputLabel>
                <Select
                  native
                  value={user.medias_capacity}
                  onChange={handleChange}
                  label="Capacité de médias"
                  inputProps={{
                    name: "medias_capacity",
                    id: "outlined-age-native-simple",
                  }}
                  size="small"
                  disabled={repair}
                >
                  {Object.entries(QUOTAS).map(([key, value]) => (
                    <option value={key} key={key}>
                      {value}
                    </option>
                  ))}
                </Select>
              </FormControl>
            </div>
            <div className="profile__fields--field">
              <TextField
                error={false}
                label="Quotas de média utilisé"
                id="outlined-error-helper-text"
                placeholder="Quotas de média utilisé"
                variant="outlined"
                name="medias_usage"
                value={formatBytes(user.medias_usage)}
                InputLabelProps={{
                  shrink: true,
                }}
                onChange={handleChange}
                disabled
                fullWidth
              />
            </div>
          </div>
          <div className="profile__fields profile__fields--dual">
            <div className="profile__fields--field">
              <TextField
                error={false}
                label="Capacité d'espaces"
                id="outlined-error-helper-text"
                placeholder="Capacité d'espaces"
                variant="outlined"
                name="workspaces_capacity"
                value={user.workspaces_capacity}
                InputLabelProps={{
                  shrink: true,
                }}
                size="small"
                fullWidth
                required
                type="number"
                onChange={handleChange}
                disabled={repair}
              />
            </div>
            <div className="profile__fields--field">
              <TextField
                error={false}
                label="Espaces crées"
                id="outlined-error-helper-text"
                placeholder="Espaces crées"
                variant="outlined"
                name="workspaces_usage"
                value={user.workspaces_usage}
                InputLabelProps={{
                  shrink: true,
                }}
                size="small"
                disabled
                fullWidth
              />
            </div>
          </div>
          <div className="profile__fields--field">
            <FormControlLabel
              control={
                <Checkbox
                  checked={repair}
                  onChange={(e) => setRepair((check) => !check)}
                  name="display"
                />
              }
              label="Réparer la licence"
            />
          </div>
        </fieldset>
        <fieldset className="profile__fieldset">
          <ButtonValid
            onClick={handleOpenConfirmModal}
            title="Modifier"
            style={{ width: "100%" }}
          />
        </fieldset>
      </form>
      <Dialog
        open={openConfirmModal}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">
          {repair
            ? "Réparer la licence ?"
            : `Modification de la licence de ${formatUserName(currentUser)}`}
        </DialogTitle>
        <Divider />
        <DialogContent>
          <div className="profile__dialog">
            {repair
              ? `Confirmez-vous la réparation de la licence de ${formatUserName(
                  currentUser
                )}`
              : "Confirmez-vous les modifications effectuées ?"}
          </div>
        </DialogContent>
        <Divider />
        <DialogActions>
          <Button onClick={handleClose} style={{ width: "50%" }}>
            Annuler
          </Button>
          <ButtonValid
            onClick={handleSubmit}
            title="Confirmer"
            style={{ width: "50%" }}
          />
        </DialogActions>
      </Dialog>
    </>
  );
}

export default ProfileLicense;
