// REACT
import React from "react";

function Dashboard() {
  return (
    <div className="dashboard">
      <h1>Accueil</h1>
      <p>
        L'espace d'administration vous permet de gérer l'ensemble des
        informations des établissements, services, utilisateurs.
      </p>
    </div>
  );
}

export default Dashboard;
