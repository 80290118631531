import { EWorkspaceTypeValue } from "./../enums/enums";
/**
 * Return a formatted user name `DR CHRISTINE Mickael`
 * @param {*Object} user DTOUser
 * @returns string
 */
export const formatUserName = (user) => {
  return `${user.proTitle ? user.proTitle : ""} ${
    user.lastName || user.lastname
  } ${user.firstName || user.firstname}`;
};

export const getWorkspaceType = (type) => {
  return EWorkspaceTypeValue[type];
};
