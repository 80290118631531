// REACT
import React, { useEffect, useState } from "react";
// REACT ROUTER
import { useHistory, useParams } from "react-router-dom";
// MATERIAL UI
import { withStyles, makeStyles } from "@material-ui/core/styles";
import { Avatar } from "@material-ui/core";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
// COMPONENTS
import ButtonDelete from "./../../Buttons/ButtonDelete";
import DeleteModal from "../../Modals/DeleteModal";
// UTILS
import { formatUserName } from "../../../utils/formatters";
// REDUX
import { useDispatch, useSelector } from "react-redux";
import { selectedWorkspace } from "../../../redux/workspaces/slices";
import { getWorkspaceLinks } from "../../../redux/workspaces/thunks";
import { deleteWorkspacesLinks } from "./../../../redux/workspaces/thunks";
import { setSnackbar } from "../../../redux/snackbar/actions";

function WorkspaceLinksPermissions() {
  // REDUX HOOKS
  const dispatch = useDispatch();
  const workspace = useSelector(selectedWorkspace);
  // MATERIAL UI HOOKS
  const classes = useStyles();
  // REACT ROUTER
  const history = useHistory();
  let { id } = useParams();
  // REACT STATE
  const [workspacesLinks, setWorkspacesLinks] = useState([]);
  const [openDissociateModal, setOpenDissociateModal] = useState(false);
  const [selectedTarget, setSelectedTarget] = useState({});

  useEffect(() => {
    dispatch(getWorkspaceLinks(id)).then((res) => {
      if (res.status === 200) {
        setWorkspacesLinks([...res.data]);
      }
    });
  }, [id, dispatch]);

  const handleOpenDissociateModal = (workspace) => {
    setSelectedTarget(workspace);
    setOpenDissociateModal(true);
  };

  const handleCloseDissociateModal = () => {
    setSelectedTarget({});
    setOpenDissociateModal(false);
  };

  const handleDissociate = () => {
    const workspaceCrossLinksParameter = {
      links: [selectedTarget.key],
    };
    dispatch(deleteWorkspacesLinks(id, workspaceCrossLinksParameter)).then(
      (res) => {
        if (res.status === 200) {
          dispatch(
            setSnackbar({
              open: true,
              snackbarMessage: "L'espace à bien été dissocié",
            })
          );
          setSelectedTarget({});
          setOpenDissociateModal(false);
          history.push(`/workspace/${id}`);
        }
      }
    );
  };

  return (
    <>
      <TableContainer component={Paper}>
        <Table className={classes.table} aria-label="customized table">
          <TableHead>
            <TableRow>
              <StyledTableCell>Associé à</StyledTableCell>
              <StyledTableCell align="right">Propriétaire</StyledTableCell>
              <StyledTableCell align="right">Etablissement</StyledTableCell>
              <StyledTableCell align="right"></StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {workspacesLinks
              ?.sort((a, b) => {
                if (a.workspaceTarget.title && b.workspaceTarget.title) {
                  if (
                    a.workspaceTarget.title.toLowerCase() <
                    b.workspaceTarget.title.toLowerCase()
                  ) {
                    return -1;
                  }
                  if (
                    a.workspaceTarget.title.toLowerCase() >
                    b.workspaceTarget.title.toLowerCase()
                  ) {
                    return 1;
                  }
                  return 0;
                }
                return 0;
              })
              .map((workspace) => (
                <StyledTableRow
                  key={workspace.workspaceTarget.key}
                  //   onClick={() => history.push(`/profile/${user.key}`)}
                  style={{ cursor: "pointer" }}
                >
                  <StyledTableCell component="th" scope="row">
                    {workspace.workspaceTarget.title}
                  </StyledTableCell>
                  <StyledTableCell align="right">
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "flex-end",
                      }}
                    >
                      <Avatar
                        src={workspace.workspaceTarget.owner?.avatar}
                        className="userCard__avatar"
                        color="secondary"
                        style={{ marginRight: 10 }}
                      >
                        {workspace.workspaceTarget.owner?.firstName[0]}
                        {workspace.workspaceTarget.owner?.lastName[0]}
                      </Avatar>
                      {formatUserName(workspace.workspaceTarget.owner)}
                    </div>
                  </StyledTableCell>
                  <StyledTableCell align="right">
                    {workspace.workspaceTarget.buildings.length > 0 && (
                      <p>
                        {workspace.workspaceTarget.buildings.map((building) => (
                          <span>{building.name}</span>
                        ))}
                      </p>
                    )}
                  </StyledTableCell>
                  <StyledTableCell align="right">
                    <ButtonDelete
                      title="Dissocier"
                      onClick={() =>
                        handleOpenDissociateModal(workspace.workspaceTarget)
                      }
                    />
                  </StyledTableCell>
                </StyledTableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      <DeleteModal
        open={openDissociateModal}
        handleClose={handleCloseDissociateModal}
        handleDelete={handleDissociate}
        title="Dissocier l'espace ?"
        text={`Confirmer la dissociation de l'espace ${selectedTarget?.title}`}
      />
    </>
  );
}

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: "#29b6f6",
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

const useStyles = makeStyles({
  table: {
    minWidth: 700,
  },
});

export default WorkspaceLinksPermissions;
