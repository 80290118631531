// REACT
import React from "react";
// MATERIAL UI
import { withStyles, makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
// UTILS
import { EWorkspaceType } from "../../../enums/enums";
// REDUX
import { useSelector } from "react-redux";
import { selectedBuilding } from "../../../redux/buildings/slices";
import BuildingWorkspacesRow from "./BuildingWorkspacesRow";

function BuildingWorkspaces() {
  // REDUX HOOKS
  const building = useSelector(selectedBuilding);
  // MATERIAL UI
  const classes = useStyles();

  return (
    <>
      <TableContainer component={Paper}>
        <Table className={classes.table} aria-label="customized table">
          <TableHead>
            <TableRow>
              <StyledTableCell>Nom de l'espace</StyledTableCell>
              <StyledTableCell align="right">Spécialité</StyledTableCell>
              <StyledTableCell align="right">Type</StyledTableCell>
              <StyledTableCell align="right">Propriétaire</StyledTableCell>
              <StyledTableCell align="right">AutoValidant</StyledTableCell>
              <StyledTableCell align="right">Supprimer</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {building?.workspaces
              .concat()
              .sort((a, b) => {
                if (a.title < b.title) {
                  return -1;
                }
                if (a.title > b.title) {
                  return 1;
                }
                return 0;
              })
              .map(
                (workspace) =>
                  workspace.type !== EWorkspaceType.PERSONAL && (
                    <BuildingWorkspacesRow
                      workspace={workspace}
                      key={workspace.key}
                    />
                  )
              )}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
}

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: "#29b6f6",
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const useStyles = makeStyles({
  table: {
    minWidth: 700,
  },
  removeWorkspace: {
    color: "red",
  },
  row: {
    cursor: "pointer",
    transition: "backgroundColor .3s ease",
    "&:hover": {
      backgroundColor: "whitesmoke",
    },
  },
});

export default BuildingWorkspaces;
