import { createSlice, current } from "@reduxjs/toolkit";

// intialState
const buildingsWorkspacesInitialState = {
  buildings: null,
  building: {},
  isLoading: false,
};

// Slice buildingsWorkspaces
export const buildingsWorkspacesSlice = createSlice({
  name: "buildingsWorkspace", // name in redux dev tools
  initialState: buildingsWorkspacesInitialState,
  reducers: {
    setBuildings: (state, { payload }) => {
      state.buildings = payload;
    },
    setBuilding: (state, { payload }) => {
      state.building = payload;

      const buildingsIndex = state.buildings.findIndex(
        (b) => b.key === state.building.key
      );

      if (buildingsIndex !== -1) {
        // La key workspaces des buildings ayant été vidée on assigne son contenu au building selectionné
        // puisque au fetch initial  des datas le serveur n'a pas pris en compte la suppresion
        state.building.workspaces = state.buildings[buildingsIndex].workspaces;
      }
    },
    pushBuilding: (state, { payload }) => {
      state.buildings.push(payload);
    },
    updateSelectedBuilding: (state, { payload }) => {
      const buildingIndex = state.buildings.findIndex(
        (building) => building.key === payload.key
      );
      if (buildingIndex !== -1) {
        state.buildings[buildingIndex] = payload;
      }
    },
    deleteSelectedBuilding: (state, { payload }) => {
      state.buildings = state.buildings.filter(
        (building) => building.key !== payload
      );
    },
    removeWorkspaceFromBuilding: (state, { payload }) => {
      state.building.workspaces = state.building.workspaces.filter(
        (workspace) => workspace.key !== payload
      );
    },
    removeWorkspaceFromBuildings: (state, { payload }) => {
      //On recherche sur quel buildings on est
      const buildingsIndex = state.buildings.findIndex(
        (b) => b.key === state.building.key
      );
      //A partir du building sur lequel on est on cherche l'index du workspace a supprimer
      const workspaceIndex = state.buildings[
        buildingsIndex
      ].workspaces.findIndex((w) => w.key === payload);

      //On supprime le workspace a l'index de workspaceindex sur le buildingIndex dans buildings
      if (buildingsIndex !== -1 && workspaceIndex !== -1) {
        state.buildings[buildingsIndex].workspaces.splice(workspaceIndex, 1);
      }
    },
    updateWorkspaceInBuildings: (state, { payload }) => {
      //dispatch(updateWorkspaceInBuildings(res.data)) ou res.data est egal au workspace qui viens d'etre crée
      payload.buildings.forEach((b) => {
        //On map sur la key buildings du payload
        const findBuildingIndex = state.buildings.findIndex(
          (building) => building.key === b.key
        );
        //on cherche si une des clé de state.buildings.key match avec l'une des clé key de payload.buildings.key et on renvoi son index si ca match
        if (findBuildingIndex !== -1) {
          //Si il y a eu un match on push dans la key workspaces du state.buildings a l'index qui correspond le workspace
          const workspaceIndex = state.buildings[
            findBuildingIndex
          ].workspaces.findIndex((workspace) => workspace.key === payload.key);

          if (workspaceIndex <= -1) {
            state.buildings[findBuildingIndex].workspaces.push(payload);
          }
        }
      });
    },
    setLoading: (state, { payload }) => {
      state.isLoading = payload;
    },
  },
});

/**
 * Return the state of the buildingsWorkspace
 * @param {*Object} state current buildingsWorkspace state
 * @returns Array DTOBuildings
 */
export const getBuildingsWorkspaces = (state) =>
  state.buildingsWorkspaces.buildings;
export const selectedBuilding = (state) => state.buildingsWorkspaces.building;
export const buildingIsLoading = (state) => state.buildingsWorkspaces.isLoading;
