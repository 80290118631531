// REACT
import React from "react";
// MATERIAL UI
import { Button, makeStyles } from "@material-ui/core";

function ButtonValid({ title, name, onClick, type, disabled, style }) {
  // MATERIAL UI HOOKS
  const classes = useStyles();

  return (
    <Button
      onClick={onClick}
      type={type}
      disabled={disabled}
      classes={{
        root: classes.root,
        label: classes.label,
      }}
      name={name}
      style={style}
    >
      {title}
    </Button>
  );
}

/**
 * Material UI Styles
 */
const useStyles = makeStyles({
  root: {
    background: "linear-gradient(246deg, #29b6f6, #26a69a)",
    borderRadius: 0,
    border: 0,
    color: "white",
    height: 38,
    padding: "0 20px",
    boxShadow: "0 3px 5px 2px rgba(105, 227, 225, .3)",
    "&:disabled": {
      opacity: "0.5",
    },
  },
  label: {
    textTransform: "uppercase",
  },
});

export default ButtonValid;
