// REACT
import React, { useEffect, useState } from "react";
// REACT ROUTER
import { useHistory } from "react-router-dom";
// MATERIAL UI
import { FormControl, NativeSelect } from "@material-ui/core";
// UTILS
import { EWorkspaceType } from "../../enums/enums";
// COMPONENTS
import WorkspaceCard from "../../components/Workspaces/WorkspaceCard/WorkspaceCard";
import CreateWorkspaceCard from "../../components/CreateCard/CreateWorkspaceCard";
import SubHeader from "../../components/SubHeader/SubHeader";
import ButtonValid from "./../../components/Buttons/ButtonValid";
// REDUX
import { useSelector } from "react-redux";
import { getBuildingsWorkspaces } from "../../redux/buildings/slices";
import { allWorkspaces } from "../../redux/workspaces/slices";

function Workspaces() {
  // REDUX HOOKS
  const buildingsWorkspaces = useSelector(getBuildingsWorkspaces);
  const userWorkspaces = useSelector(allWorkspaces);
  // REACT ROUTER HOOKS
  const history = useHistory();
  // REACT STATE
  const [selectedBuilding, setSelectedBuilding] = useState("all");
  const [workspaces, setWorkspaces] = useState([]);

  /**
   * Return array of workspaces or filterd workspaces by building
   */
  useEffect(() => {
    // Filter personal workspace
    const workspaceArrayWithoutPersonnal = userWorkspaces?.filter(
      (workspace) => workspace.type !== EWorkspaceType.PERSONAL
    );

    // Filter building corresponding to selectedBuilding key
    const building = buildingsWorkspaces?.find(
      (building) => building.key === selectedBuilding
    );

    // Set all workspaces if select key is all
    if (selectedBuilding === "all") {
      setWorkspaces(workspaceArrayWithoutPersonnal);
      // Otherwise set filterd workspaces by building key
    } else {
      setWorkspaces(building.workspaces);
    }
  }, [userWorkspaces, selectedBuilding, buildingsWorkspaces]);

  /**
   * Change the state of the current building in select
   * @param {*string} val building key
   */
  const handleChange = (e) => {
    setSelectedBuilding(e.target.value);
  };

  return (
    <>
      <SubHeader modifier="subHeader__workspaces">
        <div className="backoffice__subheader">
          <FormControl variant="outlined" className="select_container">
            {/* <InputLabel htmlFor="outlined-age-native-simple">
              Etablissements
            </InputLabel> */}
            <NativeSelect
              value={selectedBuilding}
              onChange={handleChange}
              label="Etablissements"
              inputProps={{
                name: "building",
                id: "outlined-age-native-simple",
              }}
            >
              <option value="all">Tous</option>
              {buildingsWorkspaces?.map(
                (building) =>
                  building.name && (
                    <option value={building.key} key={building.key}>
                      {building.name}
                    </option>
                  )
              )}
            </NativeSelect>
          </FormControl>
        </div>
        <ButtonValid
          title="Ajouter"
          onClick={() => history.push("/createWorkspace")}
        />
      </SubHeader>
      <div className="workspaces">
        {workspaces
          ?.concat()
          ?.sort((a, b) => {
            if (a.title.toLowerCase() < b.title.toLowerCase()) {
              return -1;
            }
            if (a.title.toLowerCase() > b.title.toLowerCase()) {
              return 1;
            }
            return 0;
          })
          .map(
            (workspace) =>
              workspace.type !== EWorkspaceType.PERSONAL && (
                <WorkspaceCard workspace={workspace} key={workspace.key} />
              )
          )}
        <CreateWorkspaceCard onClick={() => history.push("/createWorkspace")} />
      </div>
    </>
  );
}

export default Workspaces;
