import axios from "axios";
import { BOBUILDINGS } from "../../api/api";
import { setBuildings, setLoading } from "./actions";

/**
 * Allows you to search for a user/users.
 * Sends a call to the api with the search parameters
 * @param {*string} user the user to search
 * @returns DTOUsers {count, groups, users[DTOUser]}
 */
export const getBuildingsWorkspacesForUser =
  () => async (dispatch, getState) => {
    try {
      const buildingsWorkspacesForUser = await axios(
        `${BOBUILDINGS.buildings}${getState().user.user.key}/workspaces`,
        {
          headers: {
            sid: getState().auth.authData.sid,
          },
        }
      );

      if (buildingsWorkspacesForUser.status === 200) {
        dispatch(setBuildings(buildingsWorkspacesForUser.data));
      }
    } catch (err) {
      console.error("SEARCHUSER_ERROR", err);
    }
  };

// GET BUILDING
export const getBuilding = (key) => async (dispatch, getState) => {
  dispatch(setLoading(true));
  try {
    const building = await axios(`${BOBUILDINGS.buildings}${key}`, {
      headers: {
        sid: getState().auth.authData.sid,
      },
    });
    if (building.status === 200) {
      dispatch(setLoading(false));
      return building;
    }
  } catch (error) {
    console.error(error);
  }
};

// CREATE BUILDING
export const createBuilding = (building) => async (dispatch, getState) => {
  try {
    const buildingCreated = await axios.post(BOBUILDINGS.buildings, building, {
      headers: {
        sid: getState().auth.authData.sid,
        key: getState().user.user.key,
        "Content-Type": "multipart/form-data",
      },
    });
    if (buildingCreated.status === 200) {
      return buildingCreated;
    }
  } catch (error) {
    console.error(error);
  }
};

// UPDATE BUILDING
export const updateBuilding = (building, key) => async (dispatch, getState) => {
  try {
    const buildingUpdated = await axios.put(
      `${BOBUILDINGS.buildings}${key}`,
      { ...building },
      {
        headers: {
          sid: getState().auth.authData.sid,
        },
      }
    );
    if (buildingUpdated.status === 200) {
      return buildingUpdated;
    }
  } catch (error) {
    console.error(error);
  }
};

// DELETE BUILDING
export const deleteBuilding = (key) => async (dispatch, getState) => {
  try {
    const buildingDeleted = await axios.delete(
      `${BOBUILDINGS.buildings}${key}`,
      {
        headers: {
          sid: getState().auth.authData.sid,
        },
      }
    );
    if (buildingDeleted.status === 200) {
      return buildingDeleted;
    }
  } catch (error) {
    console.error(error);
  }
};

// UPDATE AVATAR BUILDING
export const updateAvatarBuilding =
  (buildingKey, avatar) => async (dispatch, getState) => {
    try {
      const updateBuilding = await axios.put(
        `${BOBUILDINGS.updateAvatar}${buildingKey}`,
        avatar,
        {
          headers: {
            sid: getState().auth.authData.sid,
            "Content-Type": "multipart/form-data",
          },
        }
      );
      if (updateBuilding.status === 200) {
        return updateBuilding;
      }
    } catch (error) {
      console.error(error);
    }
  };
