// REACT
import { useState, useCallback, useEffect, useRef } from "react";
import { useDispatch } from "react-redux";
// REDUX
import { searchUser } from "../redux/auth/thunks";

const PAGE_SIZE = 40;

const useGetPagedUsersByPage = (ref) => {
  //REDUX HOOK
  const dispatch = useDispatch();
  // REACT HOOKS
  const timeout = useRef(null);
  const observer = useRef();
  // REACt STATE
  const [page, setPage] = useState(0);
  const [search, setSearch] = useState("");
  const [users, setUsers] = useState([]);
  const [hasMore, setHasMore] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loadingPagedUsers, setLoadingPagedUsers] = useState(false);

  // FUNCTION FOR FETCHING THE NEXT PAGE WHEN LINE REF IS REACHED
  const fetchPaged = useCallback(() => {
    dispatch(searchUser(page + 1, search)).then((res) => {
      if (res && res.status === 200) {
        const entities = res.data.entities.map((entity) => entity.entity.user);
        setUsers([...users, ...entities]);
        setPage(page + 1);
        setHasMore(res.data.entities.length === PAGE_SIZE);
        setLoadingPagedUsers(false);
      }
    });
  }, [dispatch, page, users, search]);

  const lineRef = useCallback(
    (node) => {
      if (loadingPagedUsers) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && hasMore && !loadingPagedUsers) {
          setLoadingPagedUsers(true);
          fetchPaged();
        }
      });
      if (node) observer.current.observe(node);
    },
    [loadingPagedUsers, hasMore, fetchPaged]
  );

  useEffect(() => {
    if (search?.trim().length !== 0) {
      setLoading(true);
      timeout.current = setTimeout(() => {
        dispatch(searchUser(page, search)).then((res) => {
          if (res && res.status === 200) {
            const entities = res.data.entities.map(
              (entity) => entity.entity.user
            );
            setUsers([...entities]);
            setHasMore(res.data.entities.length === PAGE_SIZE);
            setLoading(false);
          }
        });
      }, 1000);
    } else {
      setUsers([]);
      setLoading(false);
    }
    return () => {
      clearTimeout(timeout.current);
    };
  }, [search, dispatch]);

  return {
    users,
    loading,
    loadingPagedUsers,
    setSearch,
    onscroll,
    lineRef,
  };
};

export default useGetPagedUsersByPage;
