// REACT
import React, { useEffect, useState } from "react";
// REACT ROUTER
import { Switch, useHistory, useParams } from "react-router-dom";
// MATERIAL UI
import { Avatar, IconButton } from "@material-ui/core";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
// COMPONENTS
import SubHeader from "../../components/SubHeader/SubHeader";
import SidebarProfil from "../../components/Sidebar/SidebarProfil";
import PrivateRoute from "../../components/PrivateRoute/PrivateRoute";
import ProfileForm from "./ProfileForm/ProfileForm";
import ProfileLicense from "./ProfileLicense/ProfileLicense";
// REDUX
import { useDispatch, useSelector } from "react-redux";
import { selectedUser } from "../../redux/user/slices";
import { getUser } from "../../redux/user/thunks";
import { setSelectedUser, setUser } from "../../redux/user/actions";
import Loading from "./../../components/Loading/Loading";
import { CircularProgress } from "@material-ui/core";

function Profile() {
  // REDUX HOOKS
  const dispatch = useDispatch();
  const user = useSelector(selectedUser);
  // REACT ROUTER HOOKS
  const history = useHistory();
  // ID USER
  let { id } = useParams();
  // REACT STATE
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    dispatch(getUser(id)).then((res) => {
      if (res.status === 200) {
        // setUser(res.data);
        dispatch(setSelectedUser(res.data));
        setLoading(false);
      }
    });
  }, [id, dispatch]);

  return (
    <div className="profile">
      {loading ? (
        <div
          style={{
            height: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <CircularProgress />
        </div>
      ) : (
        <>
          <div className="profile__backButton">
            <IconButton onClick={() => history.goBack()}>
              <ArrowBackIcon />
            </IconButton>
          </div>
          <SubHeader modifier="subHeader__profile">
            <div className="subHeader__title">
              <Avatar
                src={user?.avatar}
                className="userCard__avatar"
                color="secondary"
              >
                {user?.initiales}
              </Avatar>
              <h4 style={{ marginLeft: 5 }}>
                {`${user?.firstName} ${user?.lastName}`}{" "}
              </h4>
            </div>
          </SubHeader>
          <div className="profile__layoutContent">
            <SidebarProfil userKey={user?.key} />
            <div className="profile__content">
              <Switch>
                <PrivateRoute
                  exact
                  path="/profile/:id?"
                  render={() => <ProfileForm selectedUser={user} />}
                />
                <PrivateRoute
                  exact
                  path="/profile/:id/license"
                  render={() => <ProfileLicense selectedUser={user} />}
                />
              </Switch>
            </div>
          </div>
        </>
      )}
    </div>
  );
}

export default Profile;
