import { userSlice } from "./slices";

/**
 * Actions of ressourcesSlice
 */
export const {
  setUser,
  setSelectedUser,
  clearUser,
  updateUser,
  updateLicenseUser,
} = userSlice.actions;
