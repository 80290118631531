import React, { useEffect, useState } from "react";
// REACT ROUTER
import { useHistory } from "react-router";
// MATERIAL UI
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormLabel,
  InputLabel,
  Radio,
  RadioGroup,
  Select,
  TextField,
  Divider,
  Button,
  ListItemText,
  MenuItem,
  Typography,
} from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
// COMPONENTS
import ButtonValid from "../../../components/Buttons/ButtonValid";
// UTILS
import { formatUserName } from "../../../utils/formatters";
// REDUX
import { useDispatch, useSelector } from "react-redux";
import {
  getSpecialities,
  getRessources,
} from "../../../redux/ressources/slices";
import { setUserProfile } from "../../../redux/user/thunks";
import { updateUser } from "../../../redux/user/actions";
import { setSnackbar } from "../../../redux/snackbar/actions";
import { profileUser } from "./../../../redux/user/slices";

const MEDICAL_STATUS = 10;

function ProfileForm() {
  // REDUX HOOKS
  const dispatch = useDispatch();
  const currentUser = useSelector(profileUser);
  const specialities = useSelector(getSpecialities);
  const initRessource = useSelector(getRessources);
  // REACT ROUTER
  const history = useHistory();
  // REACT STATE
  const [openConfirmModal, setOpenConfirmModal] = useState(false);
  const [resetPassword, setResetPassword] = useState(false);
  const [loginTypeError, setLoginTypeError] = useState(false);
  const [user, setUser] = useState({
    accountType: "",
    customProStatus: "",
    email: "",
    firstname: "",
    inpp: "",
    intc: "",
    lastname: "",
    loginType: "",
    phoneNumber: "",
    proStatus: "",
    proTitle: "",
    specialities: [],
    validation: "",
    proCateg: "",
  });

  const proStatus = initRessource["pro-status-flat"]?.find(
    (status) => status.key && status.key === user.proStatus
  );

  const handleChange = ({ target: { name, value, checked } }) => {
    const updatedUser = { ...user };
    updatedUser[name] = value;
    setUser(updatedUser);
    setLoginTypeError(false);
  };

  const handleOpenConfirmModal = () => {
    setOpenConfirmModal((conf) => !conf);
  };

  const handleClose = () => {
    setLoginTypeError(false);
    setOpenConfirmModal((conf) => !conf);
  };

  // Create a string with all specialities
  const specialitiesText = (selected) =>
    selected
      ?.map(
        (speciality) =>
          specialities?.find((spec) => spec?.key === speciality)?.label
      )
      ?.join(", ");

  // Multiple select style
  const MenuProps = {
    getContentAnchorEl: null,
    PaperProps: {
      style: {
        maxHeight: 400,
        width: 400,
      },
    },
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (
      user.loginType.trim()?.length === 0 ||
      !user.loginType ||
      user.loginType === "undefined"
    ) {
      setLoginTypeError(true);
      setOpenConfirmModal((conf) => !conf);
      return;
    }

    const userParameter = {
      accountType: +user?.accountType,
      customProStatus:
        user?.customProStatus?.trim()?.length > 0
          ? user?.customProStatus
          : null,
      email: user?.email,
      firstname: user?.firstname,
      inpp: user?.inpp?.trim()?.length > 0 ? user?.inpp : null,
      intc: user?.intc?.trim()?.length > 0 ? user?.intc : null,
      lastname: user?.lastname,
      loginType: +user.loginType,
      phoneNumber:
        user?.phoneNumber?.trim()?.length > 0 ? user?.phoneNumber : null,
      proStatus: user?.proStatus,
      proTitle: user?.proTitle,
      specialities: user?.specialities.length > 0 ? user?.specialities : null,
      validation: +user.validation,
      proCateg: user?.proCateg,
      resetPassword,
    };

    dispatch(setUserProfile(currentUser.key, userParameter)).then((res) => {
      if (res.status === 200) {
        dispatch(updateUser(res.data));
        dispatch(
          setSnackbar({
            open: true,
            snackbarMessage: `Les informations de ${formatUserName(
              currentUser
            )} ont bien été modifié`,
          })
        );
        handleClose();
        history.push(`/profile/${res.data.key}`);
      }
    });
  };

  useEffect(() => {
    setUser({
      accountType: String(currentUser?.accountType),
      customProStatus: currentUser?.customProStatus || "",
      email: currentUser?.email || "",
      firstname: currentUser?.firstName || "",
      inpp: currentUser?.inpp || "",
      intc: currentUser?.intc || "",
      lastname: currentUser?.lastName || "",
      loginType: String(currentUser?.cLogin),
      phoneNumber: currentUser?.userPhoneNumber || "",
      proStatus: currentUser?.proStatus || "",
      proTitle: currentUser?.proTitle || "",
      specialities: currentUser?.specialities || [],
      validation: String(currentUser?.profileStatus),
      proCateg: currentUser?.proCateg,
    });
  }, [currentUser]);

  return (
    <>
      <form className="profile__form">
        <fieldset className="profile__fieldset">
          <div className="profile__fields profile__fields--dual">
            <div className="profile__fields--field">
              <TextField
                error={false}
                label="Prénom"
                id="outlined-error-helper-text"
                placeholder="Entrez un prénom"
                variant="outlined"
                name="firstname"
                value={user.firstname}
                InputLabelProps={{
                  shrink: true,
                }}
                size="small"
                fullWidth
                required
                onChange={handleChange}
              />
            </div>
            <div className="profile__fields--field">
              <TextField
                error={false}
                label="Nom"
                id="outlined-error-helper-text"
                placeholder="Entrez un nom"
                variant="outlined"
                name="lastname"
                value={user.lastname}
                InputLabelProps={{
                  shrink: true,
                }}
                size="small"
                fullWidth
                required
                onChange={handleChange}
              />
            </div>
          </div>
          <div className="profile__fields profile__fields--dual">
            <div className="profile__fields--field">
              <TextField
                error={false}
                label="Adresse email"
                id="outlined-error-helper-text"
                placeholder="Adresse email"
                variant="outlined"
                name="email"
                value={user.email}
                InputLabelProps={{
                  shrink: true,
                }}
                size="small"
                fullWidth
                required
                onChange={handleChange}
              />
            </div>
            <div className="profile__fields--field">
              <TextField
                error={false}
                label="Téléphone"
                id="outlined-error-helper-text"
                placeholder="Ajouter un numéro de téléphone"
                variant="outlined"
                name="phoneNumber"
                value={user.phoneNumber}
                InputLabelProps={{
                  shrink: true,
                }}
                size="small"
                fullWidth
                required
                onChange={handleChange}
              />
            </div>
          </div>
        </fieldset>
        <fieldset className="profile__fieldset">
          <div className="profile__fields profile__fields--dual">
            <FormControl variant="outlined" className="profile__fields--field">
              <InputLabel htmlFor="outlined-age-native-simple">
                Titre professionnel
              </InputLabel>
              <Select
                native
                value={user.proTitle}
                onChange={handleChange}
                label="Titre professionnel"
                inputProps={{
                  name: "proTitle",
                  id: "outlined-age-native-simple",
                }}
                size="small"
              >
                {initRessource["pro-title"]?.map((title) => (
                  <option value={title.key} key={title.key}>
                    {title.label}
                  </option>
                ))}
              </Select>
            </FormControl>
            <FormControl variant="outlined" className="profile__fields--field">
              <InputLabel htmlFor="outlined-age-native-simple">
                Status professionnel
              </InputLabel>
              <Select
                native
                value={user.proStatus}
                onChange={handleChange}
                label="Status professionnel"
                inputProps={{
                  name: "proStatus",
                  id: "outlined-age-native-simple",
                }}
                size="small"
              >
                {initRessource["pro-status"]
                  ?.concat()
                  .sort((a, b) => {
                    if (a.label && b.label) {
                      if (a.label.toLowerCase() < b.label.toLowerCase()) {
                        return -1;
                      }
                      if (a.label.toLowerCase() > b.label.toLowerCase()) {
                        return 1;
                      }
                      return 0;
                    }
                    return 0;
                  })
                  ?.map((status) => (
                    <optgroup label={status.label} key={status.label}>
                      {status.status
                        ?.concat()
                        .sort((a, b) => {
                          if (a.label && b.label) {
                            if (a.label.toLowerCase() < b.label.toLowerCase()) {
                              return -1;
                            }
                            if (a.label.toLowerCase() > b.label.toLowerCase()) {
                              return 1;
                            }
                            return 0;
                          }
                          return 0;
                        })
                        .map((stat) => (
                          <option value={stat.key} key={stat.key}>
                            {stat.label}
                          </option>
                        ))}
                    </optgroup>
                  ))}
              </Select>
            </FormControl>
          </div>
          {proStatus?.external_key === MEDICAL_STATUS && (
            <div className="profile__fields profile__fields">
              <InputLabel>
                Spécialité{user?.specialities?.length > 1 && "s"}
              </InputLabel>
              <Select
                name="specialities"
                multiple
                value={user?.specialities}
                onChange={handleChange}
                MenuProps={MenuProps}
                renderValue={specialitiesText}
                fullWidth
              >
                {specialities
                  ?.concat()
                  ?.sort((a, b) => {
                    if (a.label < b.label) {
                      return -1;
                    }
                    if (a.label > b.label) {
                      return 1;
                    }
                    return 0;
                  })
                  ?.map((specie) => (
                    <MenuItem key={specie?.key} value={specie.key}>
                      <ListItemText> {specie?.label}</ListItemText>
                      <Checkbox
                        checked={user?.specialities?.includes(specie?.key)}
                      />
                    </MenuItem>
                  ))}
              </Select>
            </div>
          )}
          <div className="profile__fields profile__fields--dual">
            <div className="profile__fields--field">
              <TextField
                error={false}
                label="RPPS ( INPP )"
                id="outlined-error-helper-text"
                placeholder="RPPS ( INPP )"
                variant="outlined"
                name="inpp"
                value={user.inpp}
                InputLabelProps={{
                  shrink: true,
                }}
                size="small"
                fullWidth
                required
                onChange={handleChange}
              />
            </div>
            <div className="profile__fields--field">
              <TextField
                error={false}
                label="Code interne ( APH )"
                id="outlined-error-helper-text"
                placeholder="Code interne ( APH )"
                variant="outlined"
                name="intc"
                value={user.intc}
                InputLabelProps={{
                  shrink: true,
                }}
                size="small"
                fullWidth
                required
                onChange={handleChange}
              />
            </div>
          </div>
          <div className="profile__fields profile__fields--dual">
            <div className="profile__fields--field">
              <FormControl component="fieldset">
                <FormLabel component="legend">
                  Choisir un type de login
                </FormLabel>
                <RadioGroup
                  aria-label="loginType"
                  name="loginType"
                  value={user.loginType}
                  onChange={handleChange}
                  row
                >
                  <FormControlLabel
                    value="0"
                    control={<Radio />}
                    label="Email"
                  />
                  <FormControlLabel
                    value="1"
                    control={<Radio />}
                    label="RPPS"
                  />
                  <FormControlLabel
                    value="2"
                    control={<Radio />}
                    label="Code interne ( APH )"
                  />
                </RadioGroup>
                {loginTypeError ? (
                  <Typography className="profile__error">
                    Le type de login ne peut pas être null
                  </Typography>
                ) : null}
              </FormControl>
            </div>
            <div className="profile__fields--field">
              <FormControl component="fieldset">
                <FormLabel component="legend">Validation du profil</FormLabel>
                <RadioGroup
                  aria-label="validation"
                  name="validation"
                  //   value={user.validation}
                  value={user.validation}
                  onChange={handleChange}
                  row
                >
                  <FormControlLabel
                    value="0"
                    control={<Radio />}
                    label="Non validé"
                  />
                  <FormControlLabel
                    value="1"
                    control={<Radio />}
                    label="En attente de validation"
                  />
                  <FormControlLabel
                    value="2"
                    control={<Radio />}
                    label="Refusé"
                  />
                  <FormControlLabel
                    value="3"
                    control={<Radio />}
                    label="Validé"
                  />
                </RadioGroup>
              </FormControl>
            </div>
          </div>
          <div className="profile__fields profile__fields--dual">
            <div className="profile__fields--field">
              <InputLabel htmlFor="outlined-age-native-simple">
                Changer de mot de passe
              </InputLabel>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={resetPassword}
                    onChange={() => setResetPassword((reset) => !reset)}
                    name="resetPassword"
                  />
                }
                label="Oui"
                className="profile__fields--field"
              />
            </div>
            <div className="profile__fields--field">
              <FormControl component="fieldset">
                <FormLabel component="legend">Type de compte</FormLabel>
                <RadioGroup
                  aria-label="accountType"
                  name="accountType"
                  value={user.accountType}
                  onChange={handleChange}
                  row
                >
                  <FormControlLabel
                    value="0"
                    control={<Radio />}
                    label="Standard"
                  />
                  <FormControlLabel
                    value="1"
                    control={<Radio />}
                    label="Standard non visible dans le réseau teamdoc"
                  />
                  <FormControlLabel
                    value="10"
                    control={<Radio />}
                    label="Administrateur d'établissement"
                  />
                </RadioGroup>
              </FormControl>
            </div>
          </div>
        </fieldset>
        <fieldset className="profile__fieldset">
          <ButtonValid
            onClick={handleOpenConfirmModal}
            title="Modifier"
            style={{ width: "100%" }}
          />
        </fieldset>
      </form>
      <Dialog
        open={openConfirmModal}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">Modification de profil</DialogTitle>
        <Divider />
        <DialogContent>
          <div className="profile__dialog">
            <div>
              Notez que si vous avez modifié:
              <ul>
                <li> - L'adresse email</li>
                <li> - RPPS ( INPP )</li>
                <li> - Code interne ( APH )</li>
                <li> - Le type de login</li>
              </ul>
              Un email sera envoyé à l'utilisateur afin qu'il réinitialise son
              mot de passe
            </div>
          </div>
        </DialogContent>
        <Divider />
        <DialogActions>
          <Button onClick={handleClose} style={{ width: "50%" }}>
            Annuler
          </Button>
          <ButtonValid
            onClick={handleSubmit}
            title="Modifier"
            style={{ width: "50%" }}
          />
        </DialogActions>
      </Dialog>
    </>
  );
}

export default ProfileForm;
