// REACT
import React, { useState } from "react";
// REACT ROUTER
import { useHistory } from "react-router";
// MATERIAL UI
import { withStyles, makeStyles } from "@material-ui/core/styles";
import RemoveCircleOutlineIcon from "@material-ui/icons/RemoveCircleOutline";
import { IconButton, TableCell, TableRow } from "@material-ui/core";
// COMPONENTS
import DeleteModal from "../../Modals/DeleteModal";
// UTILS
import { formatUserName, getWorkspaceType } from "../../../utils/formatters";
import { getSpeciality as speciality } from "../../../functions";
// REDUX
import { useDispatch } from "react-redux";
import { deleteWorkspace } from "../../../redux/workspaces/thunks";
import { useSelector } from "react-redux";
import { selectedBuilding } from "../../../redux/buildings/slices";
import { getSpecialities } from "../../../redux/ressources/slices";
import { setSnackbar } from "../../../redux/snackbar/actions";
import {
  removeWorkspaceFromBuilding,
  removeWorkspaceFromBuildings,
} from "../../../redux/buildings/actions";
import {
  removeWorkspace,
  setWorkspace,
} from "../../../redux/workspaces/actions";

function BuildingWorkspacesRow({ workspace }) {
  // REDUX HOOKS
  const dispatch = useDispatch();
  const building = useSelector(selectedBuilding);
  const specialities = useSelector(getSpecialities);
  // REACT ROUTER
  const history = useHistory();
  // MATERIAL UI
  const classes = useStyles();
  // REACT STATE
  const [openRemoveWorkspace, setOpenRemoveWorkspace] = useState(false);

  const handleOpenDeleteWorkspace = (e) => {
    e.stopPropagation();
    setOpenRemoveWorkspace((prevState) => !prevState);
  };

  /**
   * Close the deleteWorkspace Modal
   */
  const handleClose = () => {
    setOpenRemoveWorkspace((prevState) => !prevState);
  };

  /**
   * Function of deleting a workspace, we redirect to the same building in order to update the data of the building
   * @param {*string} workspaceKey
   */
  const handleDelete = (workspaceKey) => {
    dispatch(deleteWorkspace(workspaceKey)).then((res) => {
      if (res.status === 200) {
        dispatch(removeWorkspaceFromBuilding(workspaceKey));
        dispatch(removeWorkspaceFromBuildings(workspaceKey));
        dispatch(removeWorkspace(workspaceKey));
        dispatch(
          setSnackbar({
            open: true,
            snackbarMessage: "L'espace à bien été supprimé",
          })
        );
        // history.push(`/building/${building.key}`);
      }
    });
  };

  const handleSelectWorkspace = () => {
    history.push(`/workspace/${workspace.key}`);
    dispatch(setWorkspace(workspace));
  };

  return (
    <>
      <TableRow
        key={workspace.key}
        onClick={handleSelectWorkspace}
        className={classes.row}
      >
        <StyledTableCell component="th" scope="row">
          {workspace.title}
        </StyledTableCell>
        <StyledTableCell align="right">
          {workspace.specialities.map((sp) => (
            <p>{speciality(sp, specialities)}</p>
          ))}
        </StyledTableCell>
        <StyledTableCell align="right">
          {getWorkspaceType(workspace.type)}
        </StyledTableCell>
        <StyledTableCell align="right">
          {formatUserName(workspace.owner)}
        </StyledTableCell>
        <StyledTableCell align="right">
          {workspace.autoProfileValidation ? "Oui" : "Non"}
        </StyledTableCell>
        <StyledTableCell align="right">
          <IconButton onClick={handleOpenDeleteWorkspace}>
            <RemoveCircleOutlineIcon className={classes.removeWorkspace} />
          </IconButton>
        </StyledTableCell>
      </TableRow>
      <DeleteModal
        title={`Supprimer l'espace ${workspace.title} ?`}
        text={`Confirmez-vous la suppression de l'espace ?`}
        open={openRemoveWorkspace}
        handleClose={handleClose}
        handleDelete={() => handleDelete(workspace.key)}
      />
    </>
  );
}

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: "#29b6f6",
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const useStyles = makeStyles({
  table: {
    minWidth: 700,
  },
  removeWorkspace: {
    cursor: "pointer",
    color: "red",
  },
  row: {
    // cursor: "pointer",
    transition: "backgroundColor .3s ease",
    "&:hover": {
      backgroundColor: "whitesmoke",
    },
  },
});

export default BuildingWorkspacesRow;
