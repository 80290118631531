// REACT
import React, { useCallback } from "react";
// REACT ROUTER
import { useHistory } from "react-router-dom";
// UTILS
import { EWorkspaceType } from "../../../enums/enums";
// IMAGES
import BUILDINGS from "../../../assets/images/buildings.png";

function BuildingCard({ building }) {
  // REACT ROUTER HOOKS
  const history = useHistory();

  /**
   * Return the count of workspaces in building
   */
  const getWorkspacesCountInBuilding = useCallback((building) => {
    return (
      building.workspaces &&
      building.workspaces.filter(
        (workspace) => workspace.type !== EWorkspaceType.PERSONAL
      )
    ).length;
  }, []);

  return (
    <div
      className="buildingCard"
      onClick={() => history.push(`building/${building.key}`)}
    >
      <div className="buildingCard__content">
        <div className="buildingCard__logo">
          <img src={BUILDINGS} alt="" />
        </div>
        <div className="buildingCard__name">{building.name}</div>
        <div className="buildingCard__workspaceCount">
          {building.workspaces ? getWorkspacesCountInBuilding(building) : 0}{" "}
          Espace
          {building.workspaces && building.workspaces.length > 1 ? "s" : ""}
        </div>
      </div>
    </div>
  );
}

export default BuildingCard;
