import axios from "axios";
import { BOUSER } from "../../api/api";

export const getUser = (userKey) => async (dispatch, getState) => {
  try {
    const user = await axios(`${BOUSER.user}${userKey}`, {
      headers: {
        sid: getState().auth.authData.sid,
      },
    });
    if (user.status === 200) {
      return user;
    }
  } catch (error) {
    console.error("FETCH_USER_ERROR");
  }
};

export const setUserProfile =
  (userKey, userParameter) => async (dispatch, getState) => {
    try {
      const updatedUser = await axios.put(
        `${BOUSER.user}${userKey}/profile/`,
        userParameter,
        {
          headers: {
            sid: getState().auth.authData.sid,
          },
        }
      );
      if (updatedUser.status === 200) {
        return updatedUser;
      }
    } catch (error) {
      console.error("FETCH_USER_ERROR");
    }
  };

export const updateLicense =
  (userKey, userParameter) => async (dispatch, getState) => {
    try {
      const updatedLicenseUser = await axios.put(
        `${BOUSER.updateLicense}${userKey}`,
        userParameter,
        {
          headers: {
            sid: getState().auth.authData.sid,
          },
        }
      );
      if (updatedLicenseUser.status === 200) {
        return updatedLicenseUser;
      }
    } catch (error) {
      console.error("FETCH_USER_ERROR");
    }
  };

export const repairAccountlicense = (userKey) => async (dispatch, getState) => {
  try {
    const repairAccount = await axios.post(
      `${BOUSER.user}${userKey}/license/repair/`,
      {},
      {
        headers: {
          sid: getState().auth.authData.sid,
        },
      }
    );
    if (repairAccount.status === 200) {
      return repairAccount;
    }
  } catch (error) {
    console.error("FETCH_USER_ERROR");
  }
};
