// REACT
import React from "react";
// MATERIAL UI
import { CircularProgress } from "@material-ui/core";

function Loading() {
  return (
    <div className="loading">
      <CircularProgress className="loading__spinner" color="inherit" />
    </div>
  );
}

export default Loading;
