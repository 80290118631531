import { createSlice } from "@reduxjs/toolkit";

// intialState
const authInitialState = {
  authData: null,
  qrCode: null,
  error: null,
  isAuthenticated: false,
  api: null,
};

// Slice AuthUser
export const authSlice = createSlice({
  name: "authUser", // name in redux dev tools
  initialState: authInitialState,
  reducers: {
    setAuthData: (state, { payload }) => {
      state.authData = payload.auth;
      state.isAuthenticated = payload.authenticate;
      state.api = payload.api;
    },
    setAuthDataError: (state, { payload }) => {
      state.error = payload;
    },
    clearAuthData: (state) => {
      state.authData = null;
      state.isAuthenticated = false;
      state.api = null;
      localStorage.removeItem("persist:root");
    },
  },
});

/**
 * Return the state of the authData
 * @param {*Object} state current authData state
 * @returns Object
 */
export const authData = (state) => state.auth;
export const getSid = (state) => state.auth.authData.sid;
