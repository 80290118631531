import { ELicence, EProfilStatus } from "../enums/enums";

/**
 * Return user speciality label if found
 * @param {*string} speciality user speciality
 * @param {*Array} specialities Array of specialities
 * @returns string
 */
export function getSpeciality(speciality, specialities) {
  if (specialities && specialities.length) {
    let res = specialities.find((spe) => spe.key === speciality)?.label;
    return res;
  } else return "loading..";
}

export function getProStatus(status, ressources) {
  if (ressources && ressources["pro-status"]) {
    let res = "";
    ressources["pro-status"].forEach((type) => {
      let x = type.status.find((stat) => stat.key === status);
      if (x) {
        res = x;
      }
    });
    return res;
  } else return "loading..";
}

export const getLicenseType = (license) => {
  return license === ELicence.PRO
    ? "B2C"
    : license === ELicence.B2B
    ? "B2B"
    : "ACCES";
};

export const getStatusValidation = (validationStatus) => {
  return EProfilStatus[validationStatus];
};

export const getUnitsCount = (workspace) => {
  return `${workspace?.units.length} unité${
    workspace?.units.length > 1 ? "s" : ""
  }`;
};

// export function formatBytes(a, b = 2) {
//   if (0 === a) return "0 Octet";
//   const c = 0 > b ? 0 : b,
//     d = Math.floor(Math.log(a) / Math.log(1024));
//   return (
//     parseFloat((a / Math.pow(1024, d)).toFixed(c)) +
//     " " +
//     ["Octets", "Ko", "Mo", "Go", "To", "Po", "Eo", "Zo", "Yo"][d]
//   );
// }

export function formatBytes(a, b = 2) {
  if (isNaN(a)) return "0 Octet";
  if (0 === a) return "0 Octet";
  if (a < 0) return "-" + formatBytes(-a, b); // Appel récursif avec la valeur absolue de "a"
  const c = 0 > b ? 0 : b,
    d = Math.floor(Math.log(a) / Math.log(1024));
  return (
    parseFloat((a / Math.pow(1024, d)).toFixed(c)) +
    " " +
    ["Octets", "Ko", "Mo", "Go", "To", "Po", "Eo", "Zo", "Yo"][d]
  );
}

/** Affichage des différentes clés et labels concernant les spécialitées */
// export const getSpecialities =
//   ressources &&
//   ressources["speciality"]
//     ?.reduce(function next(
//       r, // est égale à un array vide le premier tour, puis à un array avec les objets inséré chaque tour
//       { key, label, sub } // récupère la valeur de KEY, LABEL, SUB à chaque tour par desctructuration
//     ) {
//       return [
//         // on copie et retourne la valeur de R à chaque tour avec le spread opérator
//         ...r,
//         // on insère les nouvelles valeurs de KEY, LABEL rencontré à chaque tour (recopié par ...R juste au dessus à chaque tour)
//         { key, label },
//         // si on rencontre un sub null on renvoi un tableau vide
//         // sinon on copie et retourne la valeur de SUB itéré par le reduce qui aura comme valeur de départ la fonction next (qui renvoi ...r et {key , label}) qui va traiter de maniere récursive les sub de la meme maniere que les premier niveau de l'array
//         ...(null === sub ? [] : sub.reduce(next, [])),
//       ];
//     }, [])
//     .sort((a, b) => a.label > b.label);
