// REACT
import React from "react";
// REACT ROUTER
import { NavLink } from "react-router-dom";

function SidebarWorkspace({ workspaceKey }) {
  return (
    <nav className="sidebarWorkspace">
      <ul>
        <li className="sidebarWorkspace__item">
          <NavLink exact to={`/workspace/${workspaceKey}`}>
            Informations
          </NavLink>
        </li>
        <li className="sidebarWorkspace__item">
          <NavLink exact to={`/workspace/${workspaceKey}/units`}>
            Unités
          </NavLink>
        </li>
        <li className="sidebarWorkspace__item">
          <NavLink exact to={`/workspace/${workspaceKey}/beds`}>
            Lits
          </NavLink>
        </li>
        <li className="sidebarWorkspace__item">
          <NavLink exact to={`/workspace/${workspaceKey}/workspaceUsersList`}>
            Utilisateurs
          </NavLink>
        </li>
        <li className="sidebarWorkspace__item">
          <NavLink exact to={`/workspace/${workspaceKey}/permission`}>
            Permission dans l'espace
          </NavLink>
        </li>
        <li className="sidebarWorkspace__item">
          <NavLink exact to={`/workspace/${workspaceKey}/linkPermission`}>
            Permission inter espace
          </NavLink>
        </li>
        <li className="sidebarWorkspace__item">
          <NavLink exact to={`/workspace/${workspaceKey}/property`}>
            Transfert de propriété
          </NavLink>
        </li>
      </ul>
    </nav>
  );
}

export default SidebarWorkspace;
