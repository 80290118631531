// Actions
import axios from "axios";
// API ROUTES
import { RESSOURCES } from "../../api/api";
// snackbar actions
import { isLoading, setInitRessources, setSpeciality } from "./actions";

/**
 * Fetch initRessources asynchronously and fill the redux store
 * @returns Array of initRessources
 */
export const fetchInitRessources = () => async (dispatch) => {
  dispatch(isLoading(true));
  try {
    const initRessources = await axios(RESSOURCES.getInitRessources);
    if (initRessources.data) {
      dispatch(setInitRessources(initRessources.data));
      dispatch(isLoading(false));
    }
  } catch (err) {
    if (err.response) {
      dispatch(isLoading(false));
    }
  }
};

/**
 * Fetch specialities asynchronously and fill the redux store
 * @returns Array of specialities
 */
export const fetchSpeciality = () => async (dispatch) => {
  dispatch(isLoading(true));
  try {
    const speciality = await axios(RESSOURCES.getSpeciality);
    if (speciality.status === 200) {
      dispatch(setSpeciality(speciality.data));
      dispatch(isLoading(false));
    }
  } catch (err) {
    if (err.response) {
      dispatch(isLoading(false));
    }
  }
};
