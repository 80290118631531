import { createSlice } from "@reduxjs/toolkit";

// intialState
const userInitialState = {
  user: null,
  selectedUser: null,
};

// Slice selectedUser
export const userSlice = createSlice({
  name: "selectedUser", // name in redux dev tools
  initialState: userInitialState,
  reducers: {
    setUser: (state, { payload }) => {
      state.user = payload;
    },
    setSelectedUser: (state, { payload }) => {
      state.selectedUser = payload;
    },
    updateUser: (state, { payload }) => {
      state.selectedUser.firstName = payload.firstName;
      state.selectedUser.lastName = payload.lastName;
      state.selectedUser.userPhoneNumber = payload.userPhoneNumber;
      state.selectedUser.inpp = payload.inpp;
      state.selectedUser.intc = payload.intc;
      state.selectedUser.email = payload.email;
      state.selectedUser.proTitle = payload.proTitle;
      state.selectedUser.proStatus = payload.proStatus;
      state.selectedUser.proStatusLong = payload.proStatusLong;
      state.selectedUser.specialities = payload.specialities;
      state.selectedUser.profileStatus = payload.profileStatus;
      state.selectedUser.cLogin = payload.cLogin;
    },
    updateLicenseUser: (state, { payload }) => {
      state.selectedUser.license = payload;
    },
    clearUser: (state) => {
      state.user = null;
    },
  },
});

/**
 * Return the state of the user
 * @param {*Object} state current user state
 * @returns Object
 */
export const selectedUser = (state) => state.user.user;
export const profileUser = (state) => state.user.selectedUser;
