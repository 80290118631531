// REACT
import React, { useEffect, useState } from "react";
// REACT ROUTER
import { useHistory } from "react-router";
// MATERIAL UI
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  InputLabel,
  Select,
  TextField,
} from "@material-ui/core";
// COMPONENTS
import ButtonValid from "../../Buttons/ButtonValid";
import ButtonDelete from "../../Buttons/ButtonDelete";
import DeleteModal from "../../Modals/DeleteModal";
// HOOKS
import useBuildingType from "../../../hooks/useBuildingType";
// REDUX
import { useDispatch } from "react-redux";
import {
  updateAvatarBuilding,
  updateBuilding,
} from "../../../redux/buildings/thunks";
import { deleteBuilding } from "../../../redux/buildings/thunks";
import { useSelector } from "react-redux";
import {
  getBuildingsWorkspaces,
  selectedBuilding,
} from "../../../redux/buildings/slices";
import {
  setBuilding,
  updateSelectedBuilding,
  deleteSelectedBuilding,
} from "../../../redux/buildings/actions";
import { setSnackbar } from "../../../redux/snackbar/actions";
import AvatarBuilding from "../AvatarBuilding/AvatarBuilding";
import useAvatar from "../../../hooks/useAvatar";

function BuildingForm() {
  // REDUX HOOKS
  const dispatch = useDispatch();
  const building = useSelector(selectedBuilding);
  const buildings = useSelector(getBuildingsWorkspaces);

  // REACT ROUTER HOOKS
  const history = useHistory();
  // CUSTOM HOOKS
  const { buildingType } = useBuildingType();
  const {
    saveAvatar,
    hasChanged,
    setHasChanged,
    openPopover,
    anchorImage,
    inputImg,
    url,
    idPopover,
    isOpenFile,
    setUrl,
    handleClick,
    handleClosePopOver,
    handleDeleteImage,
    handleCloseDialog,
    handleSubmitImage,
    onInputChange,
    getBlob,
  } = useAvatar();
  // REACT STATE
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [buildingForm, setBuildingForm] = useState({
    categorie: "",
    display: false,
    parent: "",
    type: "",
    name: "",
    nameShort: "",
    externalCode: "",
    street: "",
    city: "",
    pbox: "",
  });

  /**
   * Update the data of the building,
   * we get the data directly in the store
   */
  useEffect(() => {
    setBuildingForm({
      categorie: "",
      display: building?.display || false,
      parent: building?.parent ? building?.parent.key : "",
      type: building?.type || "",
      name: building?.name || "",
      nameShort: building?.nameShort || building?.name_short || "",
      externalCode: building?.externalCode || building?.external_code || "",
      street: building?.address?.street || "",
      city: building?.address?.city || "",
      pbox: building?.address?.pbox || "",
    });
    if (building?.avatar)
      setUrl(building?.avatarThumb || building?.avatar_thumb);
  }, [building]);

  /**
   * Change the state of the building
   * @param {*string} name fields name
   * @param {*string} value fields value
   */
  const handleChange = ({ target: { name, value, checked } }) => {
    const updatedBuildingForm = { ...buildingForm };
    if (name === "display") {
      updatedBuildingForm[name] = !updatedBuildingForm[name];
    } else {
      updatedBuildingForm[name] = value;
    }
    setBuildingForm(updatedBuildingForm);
    setHasChanged(false);
  };

  /**
   * Close the delete modal
   */
  const handleClose = () => {
    setOpenDeleteModal((prevState) => !prevState);
  };

  /**
   * Function to update a building,
   * after update we redirect to the building to refetch the data
   */
  const handleSubmit = (e) => {
    e.preventDefault();
    const buildingParameters = {
      type: buildingForm.type,
      display: buildingForm.display,
      name: buildingForm.name,
      nameShort: buildingForm.nameShort,
      externalCode:
        buildingForm.externalCode.trim().length > 0
          ? buildingForm.externalCode
          : null,
      parent:
        buildingForm.parent.trim().length > 0 ? buildingForm.parent : null,
      address: JSON.stringify({
        street:
          buildingForm.street.trim().length > 0 ? buildingForm.street : null,
        city: buildingForm.city.trim().length > 0 ? buildingForm.city : null,
        pbox: buildingForm.pbox.trim().length > 0 ? buildingForm.pbox : null,
      }),
    };

    if (saveAvatar) {
      dispatch(updateAvatarBuilding(building?.key, saveAvatar));
    }

    dispatch(updateBuilding(buildingParameters, building?.key)).then((res) => {
      if (res.status === 200) {
        dispatch(updateSelectedBuilding(res.data));
        dispatch(
          setSnackbar({
            open: true,
            snackbarMessage: "L'établissement à bien été modifié",
          })
        );
        history.push(`/building/${res.data.key}`);
      }
    });
  };

  /**
   * Function of deleting a building,
   * after deletion it redirects to the page of selection/creation of building
   */
  const handleDelete = () => {
    dispatch(deleteBuilding(building?.key)).then((res) => {
      if (res.status === 200) {
        dispatch(setBuilding({}));
        dispatch(deleteSelectedBuilding(building?.key));
        dispatch(
          setSnackbar({
            open: true,
            snackbarMessage: "L'établissement à bien été supprimé",
          })
        );
        history.push(`/buildings`);
      }
    });
  };

  return (
    <>
      <AvatarBuilding
        saveAvatar={saveAvatar}
        openPopover={openPopover}
        anchorImage={anchorImage}
        inputImg={inputImg}
        url={url}
        id={idPopover}
        isOwner={true}
        open={isOpenFile}
        handleClick={handleClick}
        handleClosePopOver={handleClosePopOver}
        handleDeleteImage={handleDeleteImage}
        handleCloseDialog={handleCloseDialog}
        handleSubmitImage={handleSubmitImage}
        onInputChange={onInputChange}
        getBlob={getBlob}
      >
        <>Ajouter un avatar a votre établissement</>
        <></>
      </AvatarBuilding>
      <form className="building__form" onSubmit={handleSubmit}>
        <fieldset className="building__fieldset">
          <FormControl variant="outlined" className="createBuilding__fields">
            <InputLabel htmlFor="outlined-age-native-simple">
              Rattacher l'établissement à
            </InputLabel>
            <Select
              native
              value={buildingForm?.parent}
              onChange={handleChange}
              label="Rattacher l'établissement"
              inputProps={{
                name: "parent",
                id: "outlined-age-native-simple",
              }}
              size="small"
            >
              <option aria-label="None" value="" />
              {buildings?.map(
                (building) =>
                  building.name && (
                    <option
                      value={building.key}
                      // selected={building?.parent === building.key}
                      key={building.key}
                    >
                      {building.name}
                    </option>
                  )
              )}
            </Select>
          </FormControl>

          <FormControl variant="outlined" className="building__fields">
            <InputLabel htmlFor="outlined-age-native-simple2">
              Type d'établissement
            </InputLabel>
            <Select
              native
              value={buildingForm?.type}
              onChange={handleChange}
              label="Type d'établissement"
              inputProps={{
                name: "type",
                id: "outlined-age-native-simple2",
              }}
            >
              {buildingType.map((building) => (
                <option value={building.key} key={building.key}>
                  {building.label}
                </option>
              ))}
            </Select>
          </FormControl>

          <div className="building__fields">
            <TextField
              error={false}
              label="Nom"
              id="outlined-error-helper-text"
              placeholder="Nom de l'établissement"
              helperText="* Le nom de l'établissement est obligatoire"
              variant="outlined"
              name="name"
              value={buildingForm?.name}
              InputLabelProps={{
                shrink: true,
              }}
              size="small"
              fullWidth
              required
              onChange={handleChange}
            />
          </div>

          <div className="building__fields">
            <TextField
              error={false}
              label="Nom court"
              id="outlined-error-helper-text"
              placeholder="Nom court de l'établissement"
              helperText="* BCH, GHN, SALP..."
              variant="outlined"
              name="nameShort"
              value={buildingForm?.nameShort}
              InputLabelProps={{
                shrink: true,
              }}
              size="small"
              fullWidth
              required
              onChange={handleChange}
            />
          </div>

          <div className="building__fields">
            <TextField
              error={false}
              label="Code externe"
              id="outlined-error-helper-text"
              placeholder="Code externe"
              helperText="* BCH, GHN, SALP..."
              variant="outlined"
              name="externalCode"
              value={buildingForm?.externalCode}
              InputLabelProps={{
                shrink: true,
              }}
              size="small"
              fullWidth
              onChange={handleChange}
            />
          </div>
        </fieldset>
        <fieldset className="building__fieldset building__fieldset--address">
          <div className="building__fields">
            <TextField
              multiline
              label="Adresse (facultatif )"
              id="outlined-error-helper-text"
              placeholder="Adresse de l'établissement"
              variant="outlined"
              name="street"
              value={buildingForm?.street}
              InputLabelProps={{
                shrink: true,
              }}
              size="small"
              fullWidth
              className="building__fields"
              onChange={handleChange}
            />
          </div>
          <div className="building__fields">
            <TextField
              label="Ville"
              id="outlined-error-helper-text"
              placeholder="Ville"
              variant="outlined"
              name="city"
              value={buildingForm?.city}
              InputLabelProps={{
                shrink: true,
              }}
              size="small"
              fullWidth
              className="building__fields"
              onChange={handleChange}
            />
          </div>
          <div className="building__fields">
            <TextField
              label="Code postal"
              id="outlined-error-helper-text"
              placeholder="Code postal"
              variant="outlined"
              name="pbox"
              value={buildingForm?.pbox}
              InputLabelProps={{
                shrink: true,
              }}
              size="small"
              fullWidth
              className="building__fields"
              onChange={handleChange}
            />
          </div>
          <div className="building__fields">
            <FormControlLabel
              control={
                <Checkbox
                  checked={buildingForm.display}
                  onChange={handleChange}
                  name="display"
                />
              }
              label="Affichable"
              className="building__fields"
            />
          </div>
        </fieldset>
        <div className="building__buttons">
          <ButtonValid type="submit" title="MODIFIER" disabled={hasChanged} />
          <ButtonDelete
            onClick={() => setOpenDeleteModal((p) => !p)}
            title="Supprimer"
          />
        </div>
      </form>
      <DeleteModal
        open={openDeleteModal}
        handleClose={handleClose}
        handleDelete={handleDelete}
        title="Supprimer l'etablissement ?"
        text={`Confirmer la suppression de l'établissement ${building?.name}`}
      />
    </>
  );
}

export default BuildingForm;
