import axios from "axios";
import { BOCHATROOMS } from "../../api/api";

export const createAds = (adsFormData) => async (dispatch, getState) => {
  try {
    const createdAds = await axios.post(
      BOCHATROOMS.createAdvertisementChatroom,
      adsFormData,
      {
        headers: {
          sid: getState().auth.authData.sid,
          "Content-Type": "multipart/form-date",
        },
      }
    );

    if (createdAds.status === 200) {
      return createdAds;
    }
  } catch (err) {
    console.error("createAds", err);
  }
};

export const updateAds = (key, adsData) => async (dispatch, getState) => {
  try {
    const updatedAds = await axios.put(
      BOCHATROOMS.updateAdvertisementChatroom,
      {
        key,
        ...adsData,
      },
      {
        headers: {
          sid: getState().auth.authData.sid,
        },
      }
    );

    if (updatedAds.status === 200) {
      return updatedAds;
    }
  } catch (err) {
    console.error("updateAds", err);
  }
};

export const deleteAds = (adsKey) => async (dispatch, getState) => {
  try {
    const deletedAds = await axios.delete(
      BOCHATROOMS.deleteAdvertisementChatroom,
      {
        headers: {
          sid: getState().auth.authData.sid,
        },
        data: { key: adsKey },
      }
    );

    if (deletedAds.status === 200) {
      return deletedAds;
    }
  } catch (err) {
    console.error("deleteAds", err);
  }
};

export const getAdsList = (adsFormData) => async (dispatch, getState) => {
  try {
    const adsList = await axios(BOCHATROOMS.createAdvertisementChatroom, {
      headers: {
        sid: getState().auth.authData.sid,
      },
    });

    if (adsList.status === 200) {
      return adsList;
    }
  } catch (err) {
    console.error("getAdsList", err);
  }
};
