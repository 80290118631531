// REACT
import React from "react";
// MATERIAL UI
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  InputLabel,
  Select,
  TextField,
} from "@material-ui/core";
// COMPONENTS
import ButtonValid from "../../components/Buttons/ButtonValid";
// HOOKS
import useBuildingType from "../../hooks/useBuildingType";
import { useSelector } from "react-redux";
import { getBuildingsWorkspaces } from "../../redux/buildings/slices";
import AvatarBuilding from "./../Buildings/AvatarBuilding/AvatarBuilding";

function CreateBuildingForm({
  handleSubmit,
  handleChange,
  building,
  saveAvatar,
  openPopover,
  anchorImage,
  inputImg,
  url,
  idPopover,
  isOpenFile,
  handleClick,
  handleClosePopOver,
  handleDeleteImage,
  handleCloseDialog,
  handleSubmitImage,
  onInputChange,
  getBlob,
}) {
  // CUSTOM HOOKS
  const { buildingType } = useBuildingType();
  const buildings = useSelector(getBuildingsWorkspaces);

  return (
    <div className="createBuilding__container">
      <AvatarBuilding
        saveAvatar={saveAvatar}
        openPopover={openPopover}
        anchorImage={anchorImage}
        inputImg={inputImg}
        url={url}
        id={idPopover}
        isOwner={true}
        open={isOpenFile}
        handleClick={handleClick}
        handleClosePopOver={handleClosePopOver}
        handleDeleteImage={handleDeleteImage}
        handleCloseDialog={handleCloseDialog}
        handleSubmitImage={handleSubmitImage}
        onInputChange={onInputChange}
        getBlob={getBlob}
      >
        <>Ajouter un avatar a votre établissement</>
        <></>
      </AvatarBuilding>

      <form onSubmit={handleSubmit} className="createBuilding__form">
        <fieldset className="createBuilding__fieldset">
          <FormControl variant="outlined" className="createBuilding__fields">
            <InputLabel htmlFor="outlined-age-native-simple">
              Rattacher l'établissement à
            </InputLabel>
            <Select
              native
              value={building?.parent}
              onChange={handleChange}
              label="Rattacher l'établissement"
              inputProps={{
                name: "parent",
                id: "outlined-age-native-simple",
              }}
              size="small"
            >
              <option aria-label="None" value="" />
              {buildings?.map(
                (building) =>
                  building.name && (
                    <option value={building.key}>{building.name}</option>
                  )
              )}
            </Select>
          </FormControl>

          <FormControl variant="outlined" className="createBuilding__fields">
            <InputLabel htmlFor="outlined-age-native-simple2">
              Type d'établissement
            </InputLabel>
            <Select
              native
              value={building?.type}
              onChange={handleChange}
              label="Type d'établissement"
              inputProps={{
                name: "type",
                id: "outlined-age-native-simple2",
              }}
            >
              {buildingType.map((building) => (
                <option value={building.key} key={building.key}>
                  {building.label}
                </option>
              ))}
            </Select>
          </FormControl>

          <div className="createBuilding__fields">
            <TextField
              error={false}
              label="Nom"
              id="outlined-error-helper-text"
              placeholder="Nom de l'établissement"
              helperText="* Le nom de l'établissement est obligatoire"
              variant="outlined"
              name="name"
              value={building?.name}
              InputLabelProps={{
                shrink: true,
              }}
              size="small"
              fullWidth
              required
              onChange={handleChange}
            />
          </div>

          <div className="createBuilding__fields">
            <TextField
              error={false}
              label="Nom court"
              id="outlined-error-helper-text"
              placeholder="Nom court de l'établissement"
              helperText="* BCH, GHN, SALP..."
              variant="outlined"
              name="nameShort"
              value={building?.nameShort}
              InputLabelProps={{
                shrink: true,
              }}
              size="small"
              fullWidth
              required
              onChange={handleChange}
            />
          </div>

          <div className="createBuilding__fields">
            <TextField
              label="Code externe"
              id="outlined-error-helper-text"
              placeholder="Code externe"
              variant="outlined"
              name="externalCode"
              helperText="* BCH, GHN, SALP..."
              InputLabelProps={{
                shrink: true,
              }}
              value={building?.externalCode}
              size="small"
              fullWidth
              onChange={handleChange}
            />
          </div>
        </fieldset>
        <fieldset className="createBuilding__fieldset createBuilding__fieldset--address">
          <div className="createBuilding__fields">
            <TextField
              multiline
              label="Adresse (facultatif )"
              id="outlined-error-helper-text"
              placeholder="Adresse de l'établissement"
              variant="outlined"
              name="street"
              value={building?.street}
              InputLabelProps={{
                shrink: true,
              }}
              size="small"
              fullWidth
              className="createBuilding__fields"
              onChange={handleChange}
            />
          </div>
          <div className="createBuilding__fields">
            <TextField
              label="Ville"
              id="outlined-error-helper-text"
              placeholder="Ville"
              variant="outlined"
              name="city"
              value={building?.city}
              InputLabelProps={{
                shrink: true,
              }}
              size="small"
              fullWidth
              className="createBuilding__fields"
              onChange={handleChange}
            />
          </div>
          <div className="createBuilding__fields">
            <TextField
              label="Code postal"
              id="outlined-error-helper-text"
              placeholder="Code postal"
              variant="outlined"
              name="pbox"
              value={building?.pbox}
              InputLabelProps={{
                shrink: true,
              }}
              size="small"
              fullWidth
              className="createBuilding__fields"
              onChange={handleChange}
            />
          </div>
          <div className="building__fields">
            <FormControlLabel
              control={
                <Checkbox
                  checked={building?.display}
                  onChange={handleChange}
                  name="display"
                />
              }
              label="Affichable"
              className="building__fields"
            />
          </div>
        </fieldset>

        <div className="text-center">
          <ButtonValid
            type="submit"
            name="createBuilding"
            title="Enregistrer"
            style={{ width: "100%" }}
          />
        </div>
      </form>
    </div>
  );
}

export default CreateBuildingForm;
