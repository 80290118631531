// REACT
import React from "react";
// REACT ROUTER
import { Switch, NavLink } from "react-router-dom";
// COMPONENTS
import AdvertisementForm from "../../components/AdvertisementForm/AdvertisementForm";
import PrivateRoute from "../../components/PrivateRoute/PrivateRoute";
import AdvertisementList from "../../components/AdvertisementList/AdvertisementList";

const Advertisement = () => {
  return (
    <div className="advertisement">
      <nav className="advertisement__sidebar">
        <ul>
          <li className="sidebarWorkspace__item">
            <NavLink exact to={`/publicite/`}>
              Créer
            </NavLink>
          </li>
          <li className="sidebarWorkspace__item">
            <NavLink exact to={`/publicite/list`}>
              Liste
            </NavLink>
          </li>
        </ul>
      </nav>
      <Switch>
        <PrivateRoute exact path="/publicite" component={AdvertisementForm} />
        <PrivateRoute
          exact
          path="/publicite/list"
          component={AdvertisementList}
        />
      </Switch>
    </div>
  );
};

export default Advertisement;
