// REACT
import React from "react";
// REACT ROUTER DOM
import { useHistory } from "react-router-dom";
// COMPONENTS
import Sidebar from "../Sidebar/Sidebar";
import Header from "../Header/Header";
import Main from "../Main/Main";
// REDUX
import { useSelector } from "react-redux";
import { selectedUser } from "../../redux/user/slices";

/**
 * Layout of BackOffice after user was selected
 * @returns Component
 */
function BackOffice({ children }) {
  // REDUX HOOKS
  const user = useSelector(selectedUser);
  // REACT ROUTER
  const history = useHistory();
  if (!user) {
    history.push("/");
  }
  return (
    <div className="backoffice">
      <Sidebar />
      <Header />
      <Main>{children}</Main>
    </div>
  );
}

export default BackOffice;
