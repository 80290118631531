import React, { useEffect, useState } from "react";
// MATERIAL UI
import {
  Checkbox,
  FormControl,
  InputLabel,
  Select,
  ListItemText,
  TextField,
  MenuItem,
  Button,
} from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
// REDUX
import { useDispatch, useSelector } from "react-redux";
import { getRessources, getSpecialities } from "../../redux/ressources/slices";
import { setSnackbar } from "../../redux/snackbar/actions";
import { deleteAds, updateAds } from "../../redux/advertisement/thunks";

const SelectedAds = ({
  selectedAds,
  setSelectedAds,
  handleFilterListAfterDelete,
  handleUpdateListAfterUpdate,
}) => {
  // REDUX HOOKS
  const dispatch = useDispatch();
  const specialities = useSelector(getSpecialities);
  const initRessource = useSelector(getRessources);
  // REACT STATE
  const [open, setOpen] = useState(false);
  const [submitRequest, setSubmitRequest] = useState(false);
  const [hasChange, setHasChange] = useState(true);
  const [ads, setAds] = useState({
    title: "",
    proStatuses: [],
    specialities: [],
  });

  const MenuProps = {
    getContentAnchorEl: null,
    PaperProps: {
      style: {
        maxHeight: 400,
        width: 400,
      },
    },
  };

  // Create a string with all specialities
  const specialitiesText = (selected) =>
    selected
      ?.map(
        (speciality) =>
          specialities?.find((spec) => spec?.key === speciality)?.label
      )
      ?.join(", ");

  // Create a string with all proStatuses
  const proStatusesText = (selected) =>
    selected
      ?.map(
        (status) =>
          initRessource["pro-status-flat"]?.find((spec) => spec?.key === status)
            ?.label
      )
      ?.join(", ");

  const handleChange = (e) => {
    const updateAds = { ...ads };
    updateAds[e.target.name] = e.target.value;
    setAds(updateAds);
    setHasChange(false);
  };

  const handleClose = () => {
    setOpen((open) => !open);
    setSubmitRequest(false);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (submitRequest) return;
    if (!ads.title) return;

    dispatch(updateAds(selectedAds.key, ads)).then((res) => {
      if (res && res.status === 200) {
        console.log("UPDATE", res);
        dispatch(
          setSnackbar({
            open: true,
            snackbarMessage: "La publicité à bien été mise à jour",
          })
        );
        handleUpdateListAfterUpdate(res.data);
      }
    });
  };

  const handleDelete = (e) => {
    e.preventDefault();

    if (submitRequest) return;
    if (!selectedAds.key) return;

    dispatch(deleteAds(selectedAds.key)).then((res) => {
      if (res && res.status === 200) {
        dispatch(
          setSnackbar({
            open: true,
            snackbarMessage: "La publicité à bien été suprimée",
          })
        );
        handleFilterListAfterDelete(selectedAds.key);
        handleClose();
      }
    });
  };

  useEffect(() => {
    setAds({
      title: selectedAds?.title,
      proStatuses: selectedAds?.proStatuses || [],
      specialities: selectedAds?.specialities || [],
    });
  }, [selectedAds]);

  return (
    <div className="selectedAds">
      <div className="selectedAds__content">
        <TextField
          required
          name="title"
          value={ads?.title}
          label="Titre de la publicité"
          onChange={handleChange}
          className="ads__input"
          fullWidth
          autoFocus
        />
        <FormControl variant="outlined" className="ads__select" fullWidth>
          <InputLabel>Status professionnel</InputLabel>
          <Select
            name="proStatuses"
            multiple
            value={ads?.proStatuses}
            onChange={handleChange}
            MenuProps={MenuProps}
            renderValue={proStatusesText}
            fullWidth
          >
            {initRessource["pro-status"]
              ?.concat()
              .sort((a, b) => a.label.localeCompare(b.label))
              ?.map((status) =>
                status.status
                  ?.concat()
                  .sort((a, b) => a.label.localeCompare(b.label))
                  .map((stat) => (
                    <MenuItem key={stat?.key} value={stat.key}>
                      <ListItemText> {stat?.label}</ListItemText>
                      <Checkbox
                        checked={ads?.proStatuses?.includes(stat?.key)}
                      />
                    </MenuItem>
                  ))
              )}
          </Select>
        </FormControl>

        <FormControl variant="outlined" className="ads__select" fullWidth>
          <InputLabel>Spécialités</InputLabel>
          <Select
            name="specialities"
            multiple
            value={ads?.specialities}
            onChange={handleChange}
            MenuProps={MenuProps}
            renderValue={specialitiesText}
            fullWidth
          >
            {specialities
              ?.concat()
              ?.sort((a, b) => {
                if (a.label < b.label) {
                  return -1;
                }
                if (a.label > b.label) {
                  return 1;
                }
                return 0;
              })
              ?.map((specie) => (
                <MenuItem key={specie?.key} value={specie.key}>
                  <ListItemText> {specie?.label}</ListItemText>
                  <Checkbox
                    checked={ads?.specialities?.includes(specie?.key)}
                  />
                </MenuItem>
              ))}
          </Select>
        </FormControl>
        <div className="selectedAds__buttons">
          <Button
            fullWidth
            variant="contained"
            color="primary"
            onClick={handleSubmit}
            disabled={!ads.title || hasChange}
            type="submit"
          >
            Modifier
          </Button>
          <Button
            fullWidth
            variant="contained"
            className="selectedAds__delete"
            onClick={() => setOpen(true)}
          >
            supprimer
          </Button>
        </div>
      </div>

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{selectedAds?.title}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Confirmer la suppression de la publicité
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="default">
            Annuler
          </Button>
          <Button
            onClick={handleDelete}
            color="primary"
            autoFocus
            disabled={submitRequest}
          >
            Confirmer
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default SelectedAds;
