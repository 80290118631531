// REACT
import React from "react";
// MATERIAL UI
import { Typography, withStyles, makeStyles } from "@material-ui/core";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
// REDUX
import { useSelector } from "react-redux";
import { selectedBuilding } from "../../../redux/buildings/slices";
import BuildingListRow from "./BuildingListRow";

function BuildingList() {
  // REDUX HOOKS
  const building = useSelector(selectedBuilding);
  // MATERIAL UI
  const classes = useStyles();

  return (
    <>
      <Typography style={{ marginBottom: 30 }}>
        Etablissements rattachés à {building?.name}
      </Typography>
      <TableContainer component={Paper}>
        <Table className={classes.table} aria-label="customized table">
          <TableHead>
            <TableRow>
              <StyledTableCell>Nom de l'établissement</StyledTableCell>
              <StyledTableCell align="right">Nom court</StyledTableCell>
              <StyledTableCell align="right">Code externe</StyledTableCell>
              <StyledTableCell align="right">Addresse</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {building.children.map((b) => (
              <BuildingListRow building={b} key={b.key} />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
}

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: "#29b6f6",
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const useStyles = makeStyles({
  table: {
    minWidth: 700,
  },
  removeWorkspace: {
    color: "red",
  },
  row: {
    cursor: "pointer",
    transition: "backgroundColor .3s ease",
    "&:hover": {
      backgroundColor: "whitesmoke",
    },
  },
});

export default BuildingList;
