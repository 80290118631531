import { authSlice } from "./slices";

/**
 * Actions of authSlice
 */
export const {
  setAuthData,
  setAuthDataError,
  clearAuthData,
} = authSlice.actions;
