// REACT
import React from "react";
// MATERIAL UI
import { createMuiTheme, ThemeProvider } from "@material-ui/core/styles";
import { Snackbar } from "@material-ui/core";
// REDUX
import { useDispatch, useSelector } from "react-redux";
import { useSnackbar } from "../../redux/snackbar/slices";
import { setSnackbar } from "../../redux/snackbar/actions";

/**
 * Application theme
 */
const theme = createMuiTheme({
  palette: {
    primary: { main: "#26a69a" },
    secondary: { main: "#29b6f6" },
    error: { main: "#e53935" },
    gradient: { main: "linear-gradient(45deg, #29b6f6, #26a69a)" },
    secondaryStd: "#29b6f6",
    secondary40: "rgba(41, 182, 246, 0.4)",
    greyDarkStd: "#263238",
    greyDark55: "rgba(38, 50, 56, 0.55)",
    greyDark40: "rgba(38, 50, 56, 0.4)",
    greyDark20: "rgba(38, 50, 56, 0.2)",
    greyMediumStd: "#c8c7cc",
    greyLightStd: "#f5f5f5",
    greyLight55: "rgba(245, 245, 245, 0.55)",
  },
  typography: {
    fontFamily: "Overpass",
    color: "rgba(0, 0, 0, 0.54)",
  },
});

/**
 * @param {*Components} children React Components ( Pages )
 * @returns Layout of App
 */
function Layout({ children }) {
  // REDUX HOOKS
  const snackbar = useSelector(useSnackbar);
  const dispatch = useDispatch();

  return (
    <ThemeProvider theme={theme}>
      {children}
      <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        open={snackbar.open}
        onClose={() =>
          dispatch(setSnackbar({ open: false, snackbarMessage: null }))
        }
        autoHideDuration={6000}
        message={snackbar.snackbarMessage}
      />
    </ThemeProvider>
  );
}

export default Layout;
