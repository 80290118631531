// REACT
import React, { useState, useEffect } from "react";
// REACT ROUTER
import { Switch, useHistory, useParams } from "react-router-dom";
// MATERIAL UI
import { CircularProgress, IconButton, Typography } from "@material-ui/core";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
// COMPONENTS
import SubHeader from "../../../components/SubHeader/SubHeader";
import SidebarBuilding from "../../../components/Sidebar/SidebarBuilding";
import PrivateRoute from "../../../components/PrivateRoute/PrivateRoute";
import BuildingForm from "../../../components/Buildings/BuildingForm/BuildingForm";
import BuildingWorkspaces from "../../../components/Buildings/BuildingWorkspaces/BuildingWorkspaces";
import BuildingList from "../../../components/Buildings/BuildingList/BuildingList";
import AddBuilding from "../../../components/Buildings/AddBuilding/AddBuilding";
// REDUX
import { useDispatch, useSelector } from "react-redux";
import { getBuilding } from "../../../redux/buildings/thunks";
import { setBuilding } from "../../../redux/buildings/actions";
import { selectedBuilding } from "./../../../redux/buildings/slices";
import { formatUserName } from "../../../utils/formatters";
// IMAGES
import BUILDINGS from "../../../assets/images/buildings.png";

function Building() {
  // REDUX HOOKS
  const dispatch = useDispatch();
  const building = useSelector(selectedBuilding);
  // REACT ROUTER HOOKS
  const history = useHistory();
  let { id } = useParams();
  // REACT STATE
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    let mounted = true;
    if (mounted && id) {
      setLoading(true);
      dispatch(getBuilding(id)).then((res) => {
        if (res.status === 200) {
          dispatch(setBuilding(res.data));
          setLoading(false);
        }
      });
    }
    return () => {
      mounted = false;
    };
  }, [id, dispatch]);

  const goBack = () => {
    dispatch(setBuilding(null));
    history.push("/buildings");
  };

  return (
    <div className="building">
      <div className="building__backButton">
        <IconButton onClick={goBack}>
          <ArrowBackIcon />
        </IconButton>
      </div>
      <SubHeader modifier="subHeader__building">
        <div className="subHeader__title">
          <img src={BUILDINGS} alt="" />
          <h4>{building && building?.name}</h4>
        </div>
        <p className="subHeader__owner">
          {building &&
            building?.owner &&
            `Propriétaire :  ${formatUserName(building?.owner)}`}
        </p>
      </SubHeader>
      {loading ? (
        <div className="building__loading">
          <CircularProgress />
          <Typography className="building__loadingText">
            Chargement des données de l'établissement
          </Typography>
        </div>
      ) : (
        <div className="building__layoutContent">
          <SidebarBuilding buildingKey={building?.key} />
          <div className="building__content">
            <Switch>
              <PrivateRoute
                exact
                path="/building/:id"
                render={() => <BuildingForm building={building} />}
              />
              <PrivateRoute
                exact
                path="/building/:id/workspaces"
                render={() => <BuildingWorkspaces building={building} />}
              />
              <PrivateRoute
                exact
                path="/building/:id/buildingList"
                render={() => <BuildingList building={building} />}
              />
              <PrivateRoute
                exact
                path="/building/:id/addBuilding"
                render={() => <AddBuilding building={building} />}
              />
            </Switch>
          </div>
        </div>
      )}
    </div>
  );
}

export default Building;
