// REACT
import React, { useState } from "react";
// MATERIAL UI
import {
  Button,
  Checkbox,
  Divider,
  FormControl,
  FormControlLabel,
  IconButton,
  InputLabel,
  Select,
  TextField,
  Tooltip,
  Typography,
} from "@material-ui/core";
import RemoveCircleOutlineIcon from "@material-ui/icons/RemoveCircleOutline";
import ClearIcon from "@material-ui/icons/Clear";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
// REDUX
import { useDispatch, useSelector } from "react-redux";
import { selectedWorkspace } from "../../../../redux/workspaces/slices";
import { getUnitsCount } from "../../../../functions/index";
import Unit from "./Unit/Unit";
import Bed from "./Bed/Bed";
import ButtonValid from "../../../Buttons/ButtonValid";
import {
  deleteUnitsInWorkspace,
  deleteBedsInWorkspace,
  createUnits,
  createBeds,
} from "./../../../../redux/workspaces/thunks";
import {
  addUnitsToWorkspace,
  addBedsToWorkspace,
  updateUnitsInWorkspace,
  updateBedsInWorkspace,
} from "../../../../redux/workspaces/actions";
import { setSnackbar } from "../../../../redux/snackbar/actions";
import { Error } from "@material-ui/icons";

function Units() {
  // REDUX HOOKS
  const dispatch = useDispatch();
  const workspace = useSelector(selectedWorkspace);
  // REACT STATE
  const [deleteBeds, setDeleteBeds] = useState(false);
  const [deleteUnits, setDeleteUnits] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [openAddUnits, setOpenAddUnits] = useState(false);
  const [openAddBeds, setOpenAddBeds] = useState(false);
  const [checked, setChecked] = useState(false);
  const [error, setError] = useState({
    count: false,
    name: false,
  });
  const [selectedUnits, setselectedUnits] = useState([]);
  const [selectedUnitsForDelete, setSelectedUnitsForDelete] = useState([]);
  const [selectedBeds, setselectedBeds] = useState([]);
  const [units, setUnits] = useState({
    count: "",
    name: "",
    startAt: "",
  });
  const [beds, setBeds] = useState({
    count: "",
    name: "",
    startAt: "",
    unit: "",
  });

  /**
   * function to change the state of the units in order to select them for deletion,
   * if we are already in the state of deletion of beds we return false
   */
  const handleDeleteUnits = () => {
    if (!deleteBeds) {
      setDeleteUnits((du) => !du);
    } else {
      return false;
    }
  };

  /**
   * function to cancel the deletion of units.
   * We return an empty units selection table if we cancel
   */
  const handleCancelDeleteUnits = () => {
    setDeleteUnits((du) => !du);
    setselectedUnits([]);
  };

  /**
   * function to change the state of the beds in order to select them for deletion,
   * if we are already in the state of deletion of units we return false
   */
  const handleDeleteBeds = () => {
    if (!deleteUnits) {
      setDeleteBeds((db) => !db);
    } else {
      return false;
    }
  };

  /**
   * function to cancel the deletion of beds.
   * We return an empty beds selection table if we cancel
   */
  const handleCancelDeleteBeds = () => {
    setDeleteBeds((db) => !db);
    setselectedBeds([]);
  };

  /**
   * function to select the beds to be deleted,
   * we check that the bed has not already been selected in which case we remove it from the selection array
   * @param {*string} bedKey
   */
  const handleSelectBedForDelete = (bedKey) => {
    if (selectedBeds.includes(bedKey)) {
      const filteredBed = selectedBeds.filter((b) => b !== bedKey);
      setselectedBeds(filteredBed);
    } else {
      setselectedBeds([...selectedBeds, bedKey]);
    }
  };

  /**
   * function to select the units to be deleted,
   * we check that the unit has not already been selected in which case we remove it from the selection array
   * @param {*string} bedKey
   */
  const handleSelectUnitForDelete = (unitKey, unit) => {
    if (selectedUnits.includes(unitKey)) {
      const filteredUnit = selectedUnits.filter((b) => b !== unitKey);
      const filteredUnitForDelete = selectedUnitsForDelete.filter(
        (b) => b.key !== unit.key
      );
      setselectedUnits(filteredUnit);
      setSelectedUnitsForDelete(filteredUnitForDelete);
    } else {
      setselectedUnits([...selectedUnits, unitKey]);
      setSelectedUnitsForDelete([...selectedUnitsForDelete, unit]);
    }
  };

  /**
   * Open confirm delete modal
   */
  const handleOpenDeleteModal = () => {
    setOpenDeleteModal((modalOpen) => !modalOpen);
  };

  /**
   * Close confirm delete modal
   */
  const handleCloseDeleteModal = () => {
    setOpenDeleteModal((modalOpen) => !modalOpen);
  };

  /**
   * Open confirm add unit modal
   */
  const handleOpenAddUnitsModal = () => {
    setOpenAddUnits((modalOpen) => !modalOpen);
  };

  /**
   * Close confirm add unit modal
   */
  const handleCloseAddsUnitModal = () => {
    setOpenAddUnits((modalOpen) => !modalOpen);
    setUnits({
      count: "",
      name: "",
      startAt: "",
    });
    setError({ name: false, count: false });
  };

  /**
   * Open confirm add modal
   */
  const handleOpenAddBedsModal = () => {
    setOpenAddBeds((modalOpen) => !modalOpen);
  };

  /**
   * Close confirm add modal
   */
  const handleCloseAddsBedsModal = () => {
    setOpenAddBeds((modalOpen) => !modalOpen);
  };

  /**
   * Changes the state of the fields of the units to be added in the workspace
   * @param {*} param0
   */
  const handleChangeUnitToAdd = ({ target: { name, value, checked } }) => {
    const updatedUnits = { ...units };
    if (name === "name") {
      setError(false);
    }
    updatedUnits[name] = value;
    setUnits(updatedUnits);
  };

  /**
   * Changes the state of the fields of the beds to be added in the workspace
   * @param {*} param0
   */
  const handleChangeBedToAdd = ({ target: { name, value, checked } }) => {
    const updatedBeds = { ...beds };
    if (name === "name") {
      setError(false);
    }
    updatedBeds[name] = value;
    setBeds(updatedBeds);
  };

  /**
   * Sends the beds or units to be deleted to the api
   */
  const handleSubmit = (e) => {
    e.preventDefault();
    if (selectedUnits.length > 0) {
      const deletedUnitsParameters = {
        units: selectedUnits,
      };
      dispatch(deleteUnitsInWorkspace(deletedUnitsParameters)).then((res) => {
        if (res.status === 200) {
          dispatch(updateUnitsInWorkspace(selectedUnits));
          setselectedUnits([]);
          setOpenDeleteModal((modalOpen) => !modalOpen);
          handleCancelDeleteUnits();
          dispatch(
            setSnackbar({
              open: true,
              snackbarMessage: "Les unités ont bien étés supprimés",
            })
          );
        }
      });
    } else if (selectedBeds.length > 0) {
      const deletedBedsParameters = {
        beds: selectedBeds,
      };
      dispatch(deleteBedsInWorkspace(deletedBedsParameters)).then((res) => {
        if (res.status === 200) {
          dispatch(updateBedsInWorkspace(selectedBeds));
          setselectedBeds([]);
          setOpenDeleteModal((modalOpen) => !modalOpen);
          handleCancelDeleteBeds();
          dispatch(
            setSnackbar({
              open: true,
              snackbarMessage: "Les lits ont bien étés supprimés",
            })
          );
        }
      });
    }
  };

  /**
   * Sends the units to be created to the api
   */
  const handleSubmitAddUnits = (e) => {
    e.preventDefault();

    if (units.name.trim().length === 0 && units.count.trim().length === 0) {
      setError({ count: true, name: true });
      return;
    } else if (units.name.trim().length === 0) {
      setError({ ...error, name: true });
      return;
    } else if (units.count.trim().length === 0) {
      setError({ ...error, count: true });
      return;
    } else {
      let unitsArray = [];
      let name;
      for (let u = 0; u < +units.count; u++) {
        // IF SUFFIX ADD START INDEX + PADDING IF COUNT IN LESSER THAN 10
        if (checked) {
          if (u < 10) {
            name = `${units.name}${("0" + (+units.startAt + u)).slice(-2)}`;
          } else {
            name = `${units.name}${+units.startAt + u}`;
          }
          // ELSE RETURN ONLY
        } else {
          name = units.name;
        }

        unitsArray.push({
          index: +units.startAt + u,
          name,
        });
      }

      const unitsParameter = {
        units: unitsArray,
      };
      dispatch(createUnits(unitsParameter, workspace.key)).then((res) => {
        if (res.status === 200) {
          console.log("ADD_UNIT", res.data);
          dispatch(addUnitsToWorkspace(res.data));
          handleCloseAddsUnitModal();

          setChecked(false);
          dispatch(
            setSnackbar({
              open: true,
              snackbarMessage: "Les unités ont bien été créés",
            })
          );
        }
      });
    }
  };

  /**
   * Sends the beds to be created to the api
   */
  const handleSubmitAddBeds = (e) => {
    e.preventDefault();

    let bedsArray = [];
    let name;
    for (let u = 0; u < +beds.count; u++) {
      // IF SUFFIX ADD START INDEX + PADDING IF COUNT IN LESSER THAN 10
      if (checked) {
        if (u < 10) {
          name = `${beds.name}${("0" + (+beds.startAt + u)).slice(-2)}`;
        } else {
          name = `${beds.name}${+beds.startAt + u}`;
        }
        // ELSE RETURN ONLY
      } else {
        if (u < 10) {
          name = `${("0" + (+beds.startAt + u)).slice(-2)}`;
        } else {
          name = String(+beds.startAt + u);
        }
      }

      bedsArray.push({
        index: +beds.startAt + u,
        name,
        unit: beds.unit ? beds.unit : null,
      });
    }

    const bedsParameter = {
      beds: bedsArray,
    };

    if (checked) {
      if (beds.name.trim().length === 0) {
        setError(true);
      } else {
        dispatch(createBeds(bedsParameter, workspace.key)).then((res) => {
          if (res.status === 200) {
            dispatch(addBedsToWorkspace(res.data));
            handleOpenAddBedsModal();
            setBeds({
              count: "",
              name: "",
              startAt: "",
              unit: "",
            });
            setChecked(false);
            dispatch(
              setSnackbar({
                open: true,
                snackbarMessage: "Les lits ont bien été créés",
              })
            );
          }
        });
        setError(false);
      }
    } else {
      dispatch(createBeds(bedsParameter, workspace.key)).then((res) => {
        if (res.status === 200) {
          dispatch(addBedsToWorkspace(res.data));
          handleOpenAddBedsModal();
          setBeds({
            count: "",
            name: "",
            startAt: "",
            unit: "",
          });
          setChecked(false);
          dispatch(
            setSnackbar({
              open: true,
              snackbarMessage: "Les lits ont bien été créés",
            })
          );
        }
      });
      setError(false);
    }
  };

  return (
    <>
      <div className="workspaceUnits__units">
        {workspace?.units && workspace?.units.length > 0 ? (
          <>
            <Typography>{getUnitsCount(workspace)}</Typography>
            <Divider />
            <div className="workspaceUnits__unitsContent">
              <div className="workspaceUnits__unitsContent--unit">
                <div className="workspaceUnits__unitsContent--header">
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <Typography>Unité(s)</Typography>
                    <IconButton onClick={handleOpenAddUnitsModal}>
                      <Tooltip title="Ajouter des unités">
                        <AddCircleOutlineIcon color="primary" />
                      </Tooltip>
                    </IconButton>
                  </div>
                  <div className="workspaceUnits__deleteBedsOrUnits">
                    <div className="workspaceUnits__deleteBedsOrUnits workspaceUnits__deleteBedsOrUnits--units">
                      {deleteUnits ? (
                        <Button
                          variant="contained"
                          color="primary"
                          startIcon={<ClearIcon />}
                          onClick={handleCancelDeleteUnits}
                        >
                          Annuler
                        </Button>
                      ) : deleteBeds ? null : (
                        <Button
                          variant="contained"
                          startIcon={<RemoveCircleOutlineIcon />}
                          onClick={handleDeleteUnits}
                          style={{ color: "white", backgroundColor: "#dc004e" }}
                        >
                          Unités
                        </Button>
                      )}
                    </div>
                    <div className="workspaceUnits__deleteBedsOrUnits workspaceUnits__deleteBedsOrUnits--beds">
                      {deleteBeds ? (
                        <Button
                          variant="contained"
                          color="primary"
                          startIcon={<ClearIcon />}
                          onClick={handleCancelDeleteBeds}
                        >
                          Annuler
                        </Button>
                      ) : deleteUnits ? null : (
                        <Button
                          variant="contained"
                          startIcon={<RemoveCircleOutlineIcon />}
                          onClick={handleDeleteBeds}
                          style={{ color: "white", backgroundColor: "#dc004e" }}
                        >
                          Lits
                        </Button>
                      )}
                    </div>
                    {selectedBeds.length > 0 && (
                      <div className="workspaceUnits__deleteBedsOrUnits workspaceUnits__deleteBedsOrUnits--delete">
                        <Button
                          variant="contained"
                          startIcon={<DeleteOutlineIcon />}
                          onClick={handleOpenDeleteModal}
                          style={{ color: "white", backgroundColor: "#dc004e" }}
                        >
                          Supprimer
                        </Button>
                      </div>
                    )}
                    {selectedUnits.length > 0 && (
                      <div className="workspaceUnits__deleteBedsOrUnits workspaceUnits__deleteBedsOrUnits--delete">
                        <Button
                          variant="contained"
                          startIcon={<DeleteOutlineIcon />}
                          onClick={handleOpenDeleteModal}
                          style={{ color: "white", backgroundColor: "#dc004e" }}
                        >
                          Supprimer
                        </Button>
                      </div>
                    )}
                  </div>
                </div>
                {workspace?.units
                  ?.concat()
                  .sort((a, b) => a.index - b.index)
                  .map((unit) => (
                    <React.Fragment key={unit.key}>
                      <Unit
                        unit={unit}
                        handleSelectUnitForDelete={handleSelectUnitForDelete}
                        deleteUnits={deleteUnits}
                        selectedUnits={selectedUnits}
                      />
                      <div className="workspaceUnits__bedsContent">
                        {workspace.beds &&
                          workspace.beds.map(
                            (bed) =>
                              bed.unit === unit.key && (
                                <Bed
                                  key={bed.key}
                                  bed={bed}
                                  handleSelectBedForDelete={
                                    handleSelectBedForDelete
                                  }
                                  deleteBeds={deleteBeds}
                                  selectedBeds={selectedBeds}
                                />
                              )
                          )}
                      </div>
                    </React.Fragment>
                  ))}
              </div>
              <div className="workspaceUnits__unitsContent--bed">
                <div style={{ display: "flex", alignItems: "center" }}>
                  <Typography>Lits non affectés</Typography>
                  <IconButton onClick={handleOpenAddBedsModal}>
                    <Tooltip title="Ajouter des lits">
                      <AddCircleOutlineIcon color="primary" />
                    </Tooltip>
                  </IconButton>
                </div>
                <div className="workspaceUnits__bedsContent">
                  {workspace.beds &&
                    workspace.beds.map(
                      (bed) =>
                        !bed.unit && (
                          <Bed
                            key={bed.key}
                            bed={bed}
                            handleSelectBedForDelete={handleSelectBedForDelete}
                            deleteBeds={deleteBeds}
                            selectedBeds={selectedBeds}
                          />
                        )
                    )}
                </div>
              </div>
            </div>
          </>
        ) : (
          <div className="workspaceUnits__noUnit">
            <div className="workspaceUnits__noUnit--content">
              <Typography>
                Vous n'avez pas encore d'unité dans votre espace
              </Typography>
              <ButtonValid
                title="Ajouter des unités"
                onClick={handleOpenAddUnitsModal}
                style={{ marginTop: 30 }}
              />
            </div>
          </div>
        )}
      </div>

      {/* DELETE MODAL */}
      <Dialog
        open={openDeleteModal}
        onClose={handleCloseDeleteModal}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">
          {selectedBeds.length > 0 && "Lits"}
          {selectedUnits.length > 0 && "Unités"}
        </DialogTitle>
        <DialogContent>
          <div className="unit__dialog">
            {selectedBeds.length > 0 && (
              <Typography>
                Confirmer la suppression des lits selectionnés ?
              </Typography>
            )}
            {selectedUnits.length > 0 && (
              <Typography>
                Confirmer la suppression des unités selectionnées ?
              </Typography>
            )}
          </div>
        </DialogContent>
        <Divider />
        <DialogActions>
          <Button onClick={handleCloseDeleteModal} style={{ width: "50%" }}>
            Annuler
          </Button>
          <ButtonValid
            onClick={handleSubmit}
            title="Confirmer"
            style={{ width: "50%" }}
          />
        </DialogActions>
      </Dialog>
      {/* ADD UNIT MODAL */}
      <Dialog
        open={openAddUnits}
        onClose={handleCloseAddsUnitModal}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">Ajouter des unités</DialogTitle>
        <Divider />
        <DialogContent>
          <div className="unit__addDialog">
            <div className="unit__addDialog--field ">
              <TextField
                error={error.count}
                name="count"
                label="Nombre d'unité à ajouter"
                type="number"
                className="unit__field"
                variant="outlined"
                size="small"
                value={units.count}
                onChange={handleChangeUnitToAdd}
                helperText="Nombre d'unité obligatoire"
                fullWidth
              />
              <TextField
                name="startAt"
                label="Index de départ"
                type="number"
                className="unit__field"
                variant="outlined"
                size="small"
                value={units.startAt}
                onChange={handleChangeUnitToAdd}
                fullWidth
              />
            </div>
            <div className="unit__addDialog--field">
              <FormControlLabel
                control={
                  <Checkbox
                    checked={checked}
                    onChange={() => setChecked((c) => !c)}
                    name="suffix"
                  />
                }
                label="Ajouter un suffixe"
                className="createWorkspace__fields--field"
              />
              <TextField
                error={error.name}
                name="name"
                label="Nom des unités"
                type="text"
                className="unit__field"
                variant="outlined"
                size="small"
                value={units.name}
                onChange={handleChangeUnitToAdd}
                style={{ width: "48%" }}
                required
                helperText="Nom de l'unité obligatoire, si suffixe coché, le nom des unités seront suivi du numéro d'unité"
              />
            </div>
          </div>
        </DialogContent>
        <Divider />
        <DialogActions>
          <Button onClick={handleCloseAddsUnitModal} style={{ width: "50%" }}>
            Annuler
          </Button>
          <ButtonValid
            onClick={handleSubmitAddUnits}
            title="Confirmer"
            style={{ width: "50%" }}
          />
        </DialogActions>
      </Dialog>
      {/* ADD BEDS MODAL */}
      <Dialog
        open={openAddBeds}
        onClose={handleCloseAddsBedsModal}
        aria-labelledby="form-dialog-title"
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle id="form-dialog-title">Ajouter des lits</DialogTitle>
        <Divider />
        <DialogContent>
          <div className="unit__addDialog">
            <div className="unit__addDialog--field ">
              <TextField
                name="count"
                label="Nombre de lits à ajouter"
                type="number"
                className="unit__field"
                variant="outlined"
                size="small"
                value={beds.count}
                onChange={handleChangeBedToAdd}
                fullWidth
              />
              <TextField
                name="startAt"
                label="Index de départ"
                type="number"
                className="unit__field"
                variant="outlined"
                size="small"
                value={beds.startAt}
                onChange={handleChangeBedToAdd}
                fullWidth
              />
            </div>
            <div className="unit__addDialog--field">
              <FormControlLabel
                control={
                  <Checkbox
                    checked={checked}
                    onChange={() => {
                      setChecked((c) => !c);
                      setBeds({
                        ...beds,
                        name: "",
                      });
                    }}
                    name="prefix"
                  />
                }
                label="Ajouter un préfixe ( Nom )"
                className="createWorkspace__fields--field"
              />

              <FormControl
                variant="outlined"
                className="createWorkspace__fields"
                fullWidth
                style={{ width: "48%" }}
              >
                <InputLabel htmlFor="outlined-age-native-simple2">
                  Ajouter dans une unité
                </InputLabel>
                <Select
                  native
                  value={beds.unit}
                  onChange={handleChangeBedToAdd}
                  label="Ajouter dans une unité"
                  inputProps={{
                    name: "unit",
                    id: "outlined-age-native-simple2",
                  }}
                  size="small"
                >
                  <option aria-label="None" value="" />
                  {workspace?.units &&
                    workspace?.units.length > 0 &&
                    workspace?.units.map((unit) => (
                      <option value={unit.key} key={unit.key}>
                        {unit.name}
                      </option>
                    ))}
                </Select>
              </FormControl>
            </div>
            {checked && (
              <div className="unit__addDialog--field">
                <TextField
                  error={error}
                  name="name"
                  label="Nom des lits"
                  type="text"
                  className="unit__field"
                  variant="outlined"
                  size="small"
                  value={beds.name}
                  onChange={handleChangeBedToAdd}
                  helperText="Si prefixe coché, les lits seront préfixés du nom, sinon le nom des lits seront l'index de départ incrémenté (20,21,22...)"
                  style={{ width: "48%" }}
                  required
                />
              </div>
            )}
          </div>
        </DialogContent>
        <Divider />
        <DialogActions>
          <Button onClick={handleCloseAddsBedsModal} style={{ width: "50%" }}>
            Annuler
          </Button>
          <ButtonValid
            onClick={handleSubmitAddBeds}
            title="Confirmer"
            style={{ width: "50%" }}
          />
        </DialogActions>
      </Dialog>
    </>
  );
}

export default Units;
