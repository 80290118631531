// REACT
import React from "react";
// REACT ROUTER
import { Link } from "react-router-dom";
// COMPONENTS
import Navbar from "../Navbar/Navbar";
// IMAGES
import LOGO from "../../assets/images/logo.png";

function Sidebar() {
  const CURRENTAPI = localStorage.getItem("API");

  return (
    <div className="sidebar">
      <div className="sidebar__logo">
        <Link to="/dashboard">
          <img src={LOGO} alt="Accueil" title="Accueil" />
        </Link>
        <p className="sidebar__api">{CURRENTAPI}</p>
      </div>
      <Navbar />
    </div>
  );
}

export default Sidebar;
