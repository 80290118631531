// REACT
import React from "react";
// REACT ROUTER
import { useHistory } from "react-router-dom";
// COMPONENTS
import ButtonValid from "../../components/Buttons/ButtonValid";
import SubHeader from "../../components/SubHeader/SubHeader";
import BuildingCard from "../../components/Buildings/BuildingCard/BuildingCard";
import CreateBuildingCard from "../../components/CreateCard/CreateBuildingCard";
// REDUX
import { useSelector } from "react-redux";
import { getBuildingsWorkspaces } from "../../redux/buildings/slices";
import { CircularProgress } from "@material-ui/core";

function Buildings() {
  // REACT ROUTER HOOKS
  const history = useHistory();
  // REDUX HOOKS
  const buildingsWorkspaces = useSelector(getBuildingsWorkspaces);

  return (
    <>
      <SubHeader modifier="subHeader__buildings">
        <ButtonValid
          title="Ajouter"
          onClick={() => history.push("/createBuilding")}
        />
      </SubHeader>
      {!buildingsWorkspaces ? (
        <CircularProgress />
      ) : (
        <div className="buildings">
          {buildingsWorkspaces
            ?.concat()
            .sort((a, b) => {
              if (a.name && b.name) {
                if (a.name.toLowerCase() < b.name.toLowerCase()) {
                  return -1;
                }
                if (a.name.toLowerCase() > b.name.toLowerCase()) {
                  return 1;
                }
                return 0;
              }
              return 0;
            })
            .map(
              (building) =>
                building.name && (
                  <BuildingCard building={building} key={building.key} />
                )
            )}
          <CreateBuildingCard onClick={() => history.push("/createBuilding")} />
        </div>
      )}
    </>
  );
}

export default Buildings;
