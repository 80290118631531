// REACT
import React from "react";
// REACT ROUTER
import { Route, Redirect } from "react-router-dom";
// REDUX
import { useSelector } from "react-redux";
import { authData } from "../../redux/auth/slices";
import { selectedUser } from "../../redux/user/slices";

/**
 * Permet de créer des routes protégées dans notre application
 *
 * @param {{path: string, component: string}} props
 */
const PrivateRoute = ({ path, component, children, render, other }) => {
  // Est-ce que la personne est authentifiée ou pas
  // IS AUTHENTIFIED
  const authUser = useSelector(authData);
  // On récupère l'utilisateur selectionné :
  // THE SELECTED USER
  const user = useSelector(selectedUser);
  // Si elle est authentifiée, on affiche le composant demandé, sinon on redirige vers le login
  return authUser.isAuthenticated && user ? (
    <Route path={path} component={component} render={render} {...other}>
      {children}
    </Route>
  ) : (
    <Redirect to="/" />
  );
};

export default PrivateRoute;
