// REACT
import React, { useState, useEffect } from "react";
// REACT ROUTER
import { useHistory, useParams } from "react-router-dom";
// MATERIAL UI
import { withStyles, makeStyles } from "@material-ui/core/styles";
import { Avatar, Typography } from "@material-ui/core";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
// UTILS
import {
  getLicenseType,
  getProStatus,
  getSpeciality,
  getStatusValidation,
} from "./../../../functions/index";
// REDUX
import { useDispatch } from "react-redux";
import { selectedWorkspace } from "../../../redux/workspaces/slices";
import { useSelector } from "react-redux";
import { getWorkspaceUsers } from "../../../redux/workspaces/thunks";
import { formatUserName } from "../../../utils/formatters";
import {
  getRessources,
  getSpecialities,
} from "../../../redux/ressources/slices";

function WorkspaceUsersList() {
  // REDUX HOOKS
  const dispatch = useDispatch();
  const workspace = useSelector(selectedWorkspace);
  const specialities = useSelector(getSpecialities);
  const ressources = useSelector(getRessources);
  // REACT ROUTER HOOKS
  const history = useHistory();
  let { id } = useParams();
  // MATERIAL UI HOOKS
  const classes = useStyles();
  // REACT STATE
  const [users, setUsers] = useState([]);

  /**
   * Retrieve users in workspace
   */
  useEffect(() => {
    dispatch(getWorkspaceUsers(id)).then((res) => {
      if (res.status === 200) {
        setUsers(res.data);
      }
    });
  }, [id, dispatch]);

  return (
    <>
      {users.length > 0 ? (
        <TableContainer component={Paper}>
          <Table className={classes.table} aria-label="customized table">
            <TableHead>
              <TableRow>
                <StyledTableCell>Nom</StyledTableCell>
                <StyledTableCell align="right">Spécialité</StyledTableCell>
                <StyledTableCell align="right">
                  Status professionnel
                </StyledTableCell>
                <StyledTableCell align="right">License</StyledTableCell>
                <StyledTableCell align="right">Offre</StyledTableCell>
                <StyledTableCell align="right">
                  Status de validation
                </StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {users
                ?.sort((a, b) => {
                  if (a.lastName && b.lastName) {
                    if (a.lastName.toLowerCase() < b.lastName.toLowerCase()) {
                      return -1;
                    }
                    if (a.lastName.toLowerCase() > b.lastName.toLowerCase()) {
                      return 1;
                    }
                    return 0;
                  }
                  return 0;
                })
                .map(({ user, license }) => (
                  <StyledTableRow
                    key={user.key}
                    onClick={() => history.push(`/profile/${user.key}`)}
                    style={{ cursor: "pointer" }}
                  >
                    <StyledTableCell component="th" scope="row">
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <Avatar
                          src={user?.avatar}
                          className="userCard__avatar"
                          color="secondary"
                          style={{ marginRight: 10 }}
                        >
                          {user?.initiales}
                        </Avatar>
                        {formatUserName(user)}
                      </div>
                    </StyledTableCell>
                    <StyledTableCell align="right">
                      {getSpeciality(user.speciality, specialities)}
                    </StyledTableCell>
                    <StyledTableCell align="right">
                      {getProStatus(user.proStatus, ressources).label}
                    </StyledTableCell>
                    <StyledTableCell align="right">
                      {getLicenseType(license.type)}
                    </StyledTableCell>
                    <StyledTableCell align="right">
                      {license.type === -100
                        ? "Licence d'accès"
                        : license.purchase && license.purchase.title}
                    </StyledTableCell>
                    <StyledTableCell align="right">
                      {getStatusValidation(user.profileStatus)}
                    </StyledTableCell>
                  </StyledTableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        <Typography>Aucun utilisateur dans cet espace</Typography>
      )}
    </>
  );
}

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: "#29b6f6",
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

const useStyles = makeStyles({
  table: {
    minWidth: 700,
  },
});

export default WorkspaceUsersList;
