const DEVICEDID = "deviceIdBo";

/**
 * Create a uniqId if not found
 * @returns string
 */
export function generateUuidV4() {
  // COOKIE NOT FOUND -> CREATE IT AND ADD IT
  var cookie = "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(
    /[xy]/g,
    function (c) {
      var r = (Math.random() * 16) | 0,
        v = c === "x" ? r : (r & 0x3) | 0x8;
      return v.toString(16);
    }
  );
  return cookie;
}

/**
 * Find and return the specified cookie
 * @param {$string} entryName
 * @returns string
 */
export function extractEntryFromCookie(entryName) {
  // SPLIT ENTRIES
  var entrySet = document.cookie.split(";");
  // SEARCH FOR ENTRY
  for (var i = 0; i < entrySet.length; i++) {
    var cleanEntry = entrySet[i].trim();
    if (cleanEntry.startsWith(entryName + "=")) return cleanEntry.split("=")[1];
  }
  return null;
}

/**
 * Checks if the cookie exists
 * @param {*string} s
 * @returns boolean
 */
export function isNullOrEmpty(s) {
  return s === null || s === "undefined" || s === "null" || s === "";
}

/**
 * Return or create deviceId
 * @returns string
 */
export function getDeviceid() {
  // IF COOKIE NOT FOUND -> AUTOMATICALY GENERATED

  // READ COOKIE (DOMAINE TEAMDOC.FR) CONTAIN "webapp=UID;xxxxx=yyyy" - xxx/yyy -> BO, FO etc

  // var entry = extractEntryFromCookie("deviceId");
  var entry = extractEntryFromCookie(DEVICEDID);

  // GENERATE COOKIE IF NOT FOUND
  if (isNullOrEmpty(entry)) entry = generateNewDeviceid();

  // GET & RETURN COOKIE FROM DOCUMENT
  return generateNewDeviceid(); // entry;
}

/**
 * Create a deviceId and store it in COOKIE
 * @returns string
 */
export function generateNewDeviceid() {
  var uuid = generateUuidV4();
  var date = new Date();
  date.setTime(date.getTime() + 38 * 365 * 24 * 60 * 60 * 1000);
  var expires = "; expires=" + date.toGMTString();
  document.cookie = "" + DEVICEDID + "=" + uuid + expires + ";path=/";

  return uuid;
}
