import { getBuildingsWorkspacesForUser } from "./buildings/thunks";
import { fetchInitRessources, fetchSpeciality } from "./ressources/thunks";
import { getWorkspaces } from "./workspaces/thunks";

/**
 * Fill the redux store with specialities and ressources
 * @param {*function} dispatch redux callback
 */
export const hydrateStoreForRessources = async (dispatch) => {
  try {
    // fill store
    await Promise.all([
      dispatch(fetchInitRessources()),
      dispatch(fetchSpeciality()),
    ]);
  } catch (e) {
    console.error("HYDRATESTOREFORRESSOURCE_ERROR", e);
  }
};

/**
 * Fill the redux store with specialities and ressources
 * @param {*function} dispatch redux callback
 */
export const hydrateStore = async (dispatch) => {
  try {
    // fill store
    await Promise.all([
      dispatch(getBuildingsWorkspacesForUser()),
      dispatch(getWorkspaces()),
    ]);
  } catch (e) {
    console.error("HYDRATESTORE_ERROR", e);
  }
};
