// REACT
import React from "react";
// COMPONENTS
import Units from "./Units/Units";

function WorkspaceUnits() {
  return (
    <div className="workspaceUnits">
      <Units />
    </div>
  );
}

export default WorkspaceUnits;
