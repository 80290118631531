// REACT
import React, { useState } from "react";
// REACT ROUTER
import { useHistory } from "react-router";
// COMPONENTS
import CreateBuildingForm from "../../../components/Form/CreateBuildingForm";
// CUSTOM HOOKS
import useAvatar from "./../../../hooks/useAvatar";
// REDUX
import { useDispatch } from "react-redux";
import { createBuilding } from "../../../redux/buildings/thunks";
import { pushBuilding } from "../../../redux/buildings/actions";
import { setSnackbar } from "../../../redux/snackbar/actions";

function CreateBuilding() {
  // REDUX HOOKS
  const dispatch = useDispatch();
  // REACT ROUTER HOOKS
  const history = useHistory();
  // REACT STATE
  const [building, setBuilding] = useState({
    categorie: "",
    display: false,
    type: "",
    name: "",
    nameShort: "",
    externalCode: "",
    parent: "",
    street: "",
    city: "",
    pbox: "",
  });
  // CUSTOM HOOKS
  const {
    saveAvatar,
    openPopover,
    anchorImage,
    inputImg,
    url,
    idPopover,
    isOpenFile,
    selectedFiles,
    blob,
    handleClick,
    handleClosePopOver,
    handleDeleteImage,
    handleCloseDialog,
    handleSubmitImage,
    onInputChange,
    getBlob,
  } = useAvatar();

  /**
   * Change the state of the building
   * @param {*string} name fields name
   * @param {*string} value fields value
   */
  const handleChange = ({ target: { name, value } }) => {
    const updatedBuilding = { ...building };
    if (name === "display") {
      updatedBuilding[name] = !updatedBuilding[name];
    } else {
      updatedBuilding[name] = value;
    }
    updatedBuilding[name] = value;
    setBuilding(updatedBuilding);
  };

  /**
   * Function to create a building,
   * after creation it redirects to the newly created building
   */
  const handleSubmit = (e) => {
    e.preventDefault();

    const adress = {
      street: building.street,
      city: building.city,
      pbox: building.pbox,
    };

    const createBuildingformData = new FormData();
    createBuildingformData.append("type", building.type);
    createBuildingformData.append("display", building.display);
    createBuildingformData.append("name", building.name);
    createBuildingformData.append("nameShort", building.nameShort);
    createBuildingformData.append("externalCode", building.externalCode);
    createBuildingformData.append("address", JSON.stringify(adress));
    if (building.parent.trim().length > 0) {
      createBuildingformData.append("parent", building.parent);
    }
    if (selectedFiles && selectedFiles.length > 0) {
      createBuildingformData.append("avatar", blob);
      for (let selectedFile of selectedFiles) {
        createBuildingformData.append("filename", selectedFile.name);
        createBuildingformData.append("mimetype", selectedFile.type);
      }
    }

    dispatch(createBuilding(createBuildingformData)).then((res) => {
      if (res.status === 200) {
        dispatch(pushBuilding(res.data));
        dispatch(
          setSnackbar({
            open: true,
            snackbarMessage: "L'établissement à bien été créé",
          })
        );
        history.push(`/building/${res.data.key}`);
      }
    });
  };

  return (
    <div className="createBuilding">
      <header className="createBuilding__header">
        <h4 className="createBuilding__header--title">Mon établissement</h4>
        <p className="createBuilding__header--text">
          Cette étape va vous permettre de créer un espace de travail et de
          communication pour tous les membres (médecins, infirmiers, etc.) qui
          en feront parti.
        </p>
      </header>
      <CreateBuildingForm
        handleSubmit={handleSubmit}
        handleChange={handleChange}
        buildings={building}
        saveAvatar={saveAvatar}
        openPopover={openPopover}
        anchorImage={anchorImage}
        inputImg={inputImg}
        url={url}
        idPopover={idPopover}
        isOpenFile={isOpenFile}
        handleClick={handleClick}
        handleClosePopOver={handleClosePopOver}
        handleDeleteImage={handleDeleteImage}
        handleCloseDialog={handleCloseDialog}
        handleSubmitImage={handleSubmitImage}
        onInputChange={onInputChange}
        getBlob={getBlob}
      />
    </div>
  );
}

export default CreateBuilding;
