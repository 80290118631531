// REACT
import React, { useEffect, useState } from "react";
// MATERIAL UI
import { Divider, Typography } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import EditIcon from "@material-ui/icons/Edit";
import ButtonValid from "./../../../../Buttons/ButtonValid";
// REDUX
import { updateUnit } from "./../../../../../redux/units/thunks";
import { useDispatch } from "react-redux";
import { updateUnitInWorkspace } from "../../../../../redux/workspaces/actions";
import { setSnackbar } from "../../../../../redux/snackbar/actions";

function Unit({ unit, handleSelectUnitForDelete, deleteUnits, selectedUnits }) {
  // REDUX HOOKS
  const dispatch = useDispatch();
  // REACT STATE
  const [openEditUnit, setOpenEditUnit] = useState(false);
  const [selectedUnit, setSeletedUnit] = useState({
    index: "",
    name: "",
    key: "",
  });

  /**
   * Update the state of the selected unit
   */
  useEffect(() => {
    setSeletedUnit({
      index: unit.index,
      name: unit.name,
      key: unit.key,
    });
  }, [unit]);

  const handleChange = ({ target: { name, value } }) => {
    const updatedUnit = { ...selectedUnit };
    updatedUnit[name] = value;
    setSeletedUnit(updatedUnit);
  };

  /**
   * Open Modal for edit unit
   */
  const handleOpenEditUnit = () => {
    setOpenEditUnit((u) => !u);
  };

  /**
   * Close Modal
   */
  const handleClose = () => {
    setOpenEditUnit((u) => !u);
  };

  /**
   * Update the selected unit
   */
  const handleSubmit = () => {
    const unit = {
      units: [selectedUnit],
    };

    dispatch(updateUnit(unit)).then((res) => {
      if (res.status === 200) {
        dispatch(updateUnitInWorkspace(selectedUnit));
        handleClose();
        dispatch(
          setSnackbar({
            open: true,
            snackbarMessage: `L'unité ${selectedUnit.name} a bien été modifié`,
          })
        );
      }
    });
  };

  const isSelected = selectedUnits.includes(unit.key);

  return (
    <>
      <div
        className="unit"
        style={{
          backgroundColor: isSelected ? "#ff000047" : "#f4f4f5",
          border: deleteUnits
            ? `1px dashed ${isSelected ? "red" : "black"}`
            : "inherit",
        }}
        onClick={() =>
          deleteUnits
            ? handleSelectUnitForDelete(unit.key, unit)
            : handleOpenEditUnit()
        }
      >
        <Typography className="unit__name">{unit.name}</Typography>
        <EditIcon color="secondary" style={{ fontSize: "1.1rem" }} />
      </div>
      <Dialog
        open={openEditUnit}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">
          Modifier l'unité {unit.name}
        </DialogTitle>
        <DialogContent>
          <div className="unit__dialog">
            <TextField
              name="index"
              label="Position"
              type="text"
              className="unit__field"
              variant="outlined"
              size="small"
              value={selectedUnit.index}
              onChange={handleChange}
            />
            <TextField
              name="name"
              label="Nom de l'unité"
              type="text"
              className="unit__field"
              variant="outlined"
              size="small"
              value={selectedUnit.name}
              onChange={handleChange}
            />
          </div>
        </DialogContent>
        <Divider />
        <DialogActions>
          <Button onClick={handleClose} style={{ width: "50%" }}>
            Annuler
          </Button>
          <ButtonValid
            onClick={handleSubmit}
            title="Modifier"
            style={{ width: "50%" }}
          />
        </DialogActions>
      </Dialog>
    </>
  );
}

export default Unit;
