// REACT
import React from "react";
// REACT ROUTER
import { NavLink } from "react-router-dom";

function SidebarProfil({ userKey }) {
  return (
    <nav className="sidebarProfile">
      <ul>
        <li className="sidebarProfile__item">
          <NavLink exact to={`/profile/${userKey}`}>
            Informations
          </NavLink>
        </li>
        <li className="sidebarProfile__item">
          <NavLink exact to={`/profile/${userKey}/license`}>
            License
          </NavLink>
        </li>
      </ul>
    </nav>
  );
}

export default SidebarProfil;
