import { useState } from "react";

function useAvatar(avatar) {
  //States
  const [openConfirmAvatarChange, setOpenConfirmAvatarChange] = useState(false);
  const [openDeleteAvatarModal, setOpenDeleteAvatarModal] = useState(false);
  const [saveAvatar, setSaveAvatar] = useState(null);
  const [isOpenFile, setOpen] = useState(false);
  const [hasChanged, setHasChanged] = useState(true);
  const [blob, setBlob] = useState(null);
  const [inputImg, setInputImg] = useState("");
  const [selectedFiles, setSelectedFiles] = useState(null);
  const [url, setUrl] = useState(null);
  const [anchorImage, setAnchorImage] = useState(null);
  const openPopover = Boolean(anchorImage);
  const id = openPopover ? "simple-popover" : undefined;

  //Functions
  /**
   * We convert the image in base 64 in order to use it in a canvas
   * We keep in memory the selected image in its original format to extract some data to send it to the API.
   * We open the modal when the image is selected
   */
  const onInputChange = function (initialAvatarSettingEvent) {
    const file = initialAvatarSettingEvent.target.files[0];
    const reader = new FileReader();

    reader.addEventListener(
      "load",
      () => {
        setInputImg(reader.result);
      },
      false
    );
    if (file) {
      reader.readAsDataURL(file);
    }
    setSelectedFiles(initialAvatarSettingEvent.target.files);
    setOpen(true);
  };

  /**
   * We recover via the callback the cropped image that we convert to blob format
   */
  const getBlob = function (blobData) {
    // pass blob up from the ImageCropper component
    setBlob(blobData);
  };

  /**
   * Allow us to close the modal
   */
  const handleCloseDialog = function () {
    setOpen(false);
    setInputImg("");
    setSelectedFiles(null);
    setSaveAvatar(null);
  };

  /**
   * Close the confirmation modal and reset all values
   */
  const handleCloseConfirm = () => {
    setInputImg("");
    setSelectedFiles(null);
    setSaveAvatar(null);
    setOpenConfirmAvatarChange(false);
    /**
     * Lorsque l'on modifie l'image si la modal est close la value est bien reset mais si l'on confirme l'avatar n'est pas encore chargé
     */
    // avatar? setUrl(avatar) : setUrl(null);
  };

  /**Reset avatar on close modal */
  const handleCancel = () => {
    handleCloseConfirm();
    avatar ? setUrl(avatar) : setUrl(null);
  };

  /**
   * Open the confirmation delete modal
   */
  const handleOpenDeleteAvatarConfirmModal = () => {
    setOpenDeleteAvatarModal(true);
    handleClosePopOver();
  };

  /**
   * Allow to open and close menu when cropped image is clicked
   */
  const handleClick = function (updateAvatarSettingEvent) {
    setAnchorImage(updateAvatarSettingEvent.currentTarget);
  };

  const handleClosePopOver = function () {
    setAnchorImage(null);
  };

  /**
   * Allow us to delete the cropped image
   */
  const handleDeleteImage = function () {
    setUrl(null);
    setInputImg("");
    setSelectedFiles(null);
    setSaveAvatar(null);
    handleClosePopOver();
  };

  const handleSubmitImage = function (submitEvent) {
    submitEvent.preventDefault();
    const formData = new FormData();

    for (let selectedFile of selectedFiles) {
      formData.append("file", blob);
      formData.append("filename", selectedFile.name);
      formData.append("mimetype", selectedFile.type);
    }
    setSaveAvatar(formData);
    setHasChanged(false);
    setOpen(false);
    setUrl(URL.createObjectURL(blob));
    setOpenConfirmAvatarChange(true);
  };

  return {
    saveAvatar,
    hasChanged,
    openPopover,
    openConfirmAvatarChange,
    openDeleteAvatarModal,
    anchorImage,
    inputImg,
    url,
    id,
    isOpenFile,
    selectedFiles,
    blob,
    setUrl,
    setInputImg,
    setSelectedFiles,
    setSaveAvatar,
    setHasChanged,
    setOpenDeleteAvatarModal,
    handleClick,
    handleClosePopOver,
    handleCloseConfirm,
    handleCancel,
    handleDeleteImage,
    handleCloseDialog,
    handleOpenDeleteAvatarConfirmModal,
    handleSubmitImage,
    onInputChange,
    getBlob,
  };
}

export default useAvatar;
