import { ressourcesSlice } from "./slices";

/**
 * Actions of ressourcesSlice
 */
export const {
  isLoading,
  setInitRessources,
  setSpeciality,
  clearRessources,
} = ressourcesSlice.actions;
