// REACT
import React from "react";
// REACT ROUTER
import { Switch, useHistory, useParams } from "react-router-dom";
// UTILS
import { formatUserName } from "../../../utils/formatters";
// MATERIAL UI
import { IconButton } from "@material-ui/core";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
// IMAGES
import BUILDINGS from "../../../assets/images/buildings.png";
// COMPONENTS
import SubHeader from "../../../components/SubHeader/SubHeader";
import SidebarWorkspace from "../../../components/Sidebar/SidebarWorkspace";
import PrivateRoute from "../../../components/PrivateRoute/PrivateRoute";
import WorkspaceForm from "../../../components/Workspaces/WorkspaceForm/WorkspaceForm";
import WorkspaceUnits from "../../../components/Workspaces/WorkspaceUnits/WorkspaceUnits";
import WorkspaceBeds from "../../../components/Workspaces/WorkspaceBeds/WorkspaceBeds";
import WorkspaceUsersList from "../../../components/Workspaces/WorkspaceUsersList/WorkspaceUsersList";
import WorkspaceLinksPermissions from "../../../components/Workspaces/WorkspaceLinksPermissions/WorkspaceLinksPermissions";
import WorkspaceProperty from "./../../../components/Workspaces/WorkspaceProperty/WorkspaceProperty";
// REDUX
import { useSelector } from "react-redux";
import { selectedWorkspace } from "../../../redux/workspaces/slices";

function Workspace() {
  // REDUX HOOKS
  const workspace = useSelector(selectedWorkspace);
  // REACT ROUTER HOOKS
  const history = useHistory();
  let { id } = useParams();
  const goBack = () => {
    // dispatch(setWorkspace(null));
    // history.push("/workspaces");
    history.goBack();
  };

  return (
    <div className="workspace">
      <div className="workspace__backButton">
        <IconButton onClick={goBack}>
          <ArrowBackIcon />
        </IconButton>
      </div>
      <SubHeader modifier="subHeader__workspace">
        <div className="subHeader__title">
          <img src={BUILDINGS} alt="" />
          <h4 style={{ marginRight: 5 }}>{workspace && workspace?.title} </h4>
          {workspace?.units ? (
            <span style={{ marginRight: 5 }}>
              {" "}
              - {workspace?.units.length} unité
              {workspace?.units.length > 1 ? "s" : ""}
            </span>
          ) : (
            ""
          )}
          {workspace?.beds ? (
            <span style={{ marginRight: 5 }}>
              {" "}
              - {workspace?.beds.length} lit
              {workspace?.beds.length > 1 ? "s" : ""}
            </span>
          ) : (
            ""
          )}
        </div>
        <p className="subHeader__owner">
          Propriétaire : {workspace && formatUserName(workspace?.owner)}
        </p>
      </SubHeader>
      <div className="workspace__layoutContent">
        <SidebarWorkspace workspaceKey={id} />
        <div className="workspace__content">
          <Switch>
            <PrivateRoute
              exact
              path="/workspace/:id"
              component={WorkspaceForm}
            />
            <PrivateRoute
              exact
              path="/workspace/:id/units"
              component={WorkspaceUnits}
            />
            <PrivateRoute
              exact
              path="/workspace/:id/beds"
              component={WorkspaceBeds}
            />
            <PrivateRoute
              exact
              path="/workspace/:id/workspaceUsersList"
              component={WorkspaceUsersList}
            />
            <PrivateRoute
              exact
              path="/workspace/:id/linkPermission"
              component={WorkspaceLinksPermissions}
            />
            <PrivateRoute
              exact
              path="/workspace/:id/property"
              component={WorkspaceProperty}
            />
          </Switch>
        </div>
      </div>
    </div>
  );
}

export default Workspace;
