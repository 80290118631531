import React, { useState } from "react";
import "react-image-crop/dist/ReactCrop.css";
import Cropper from "react-easy-crop";
import { getCroppedImg } from "./cropImage";
import { Slider } from "@material-ui/core";

function CropAvatar({ getBlob, inputImg }) {
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1.1);

  /* onCropComplete() will occur each time the user modifies the cropped area, 
which isn't ideal. A better implementation would be getting the blob 
only when the user hits the submit button, but this works for now  */
  const onCropComplete = async (_, croppedAreaPixels) => {
    const croppedImage = await getCroppedImg(inputImg, croppedAreaPixels);
    getBlob(croppedImage);
  };

  const handleRatio = (e, ratio) => {
    setZoom(ratio);
  };

  return (
    /* need to have a parent with `position: relative` 
to prevent cropper taking up whole page */
    <>
      <div
        className="cropper"
        style={{ position: "absolute", top: 0, bottom: 50, left: 0, right: 0 }}
      >
        <Cropper
          image={inputImg}
          crop={crop}
          zoom={zoom}
          zoomWithScroll
          aspect={1}
          onCropChange={setCrop}
          onCropComplete={onCropComplete}
          onZoomChange={setZoom}
          cropShape="rect"
        />
      </div>
      <Slider
        defaultValue={zoom}
        aria-labelledby="discrete-slider-small-steps"
        step={0.1}
        min={1}
        max={2}
        onChange={handleRatio}
        style={{ position: "absolute", bottom: 0 }}
      />
    </>
  );
}

export default CropAvatar;
