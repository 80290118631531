// REACT
import React, { useState } from "react";
// REACT ROUTER
import { useHistory } from "react-router";
// MATERIAL UI
import {
  Checkbox,
  Chip,
  Divider,
  Drawer,
  FormControl,
  FormControlLabel,
  IconButton,
  InputLabel,
  ListItemText,
  makeStyles,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@material-ui/core";
import AddCircleOutlineOutlinedIcon from "@material-ui/icons/AddCircleOutlineOutlined";
// UTILS
import { ELicence, EWorkspaceType } from "../../../enums/enums";
// COMPONENTS
import ButtonValid from "../../../components/Buttons/ButtonValid";
// REDUX
import { useDispatch, useSelector } from "react-redux";
import { getBuildingsWorkspaces } from "../../../redux/buildings/slices";
import { updateWorkspaceInBuildings } from "../../../redux/buildings/actions";

import { selectedUser } from "../../../redux/user/slices";
import { createWorkspace } from "../../../redux/workspaces/thunks";
import { pushCreatedWorkspace } from "../../../redux/workspaces/actions";
import { getSpecialities } from "../../../redux/ressources/slices";
import { setSnackbar } from "../../../redux/snackbar/actions";

function CreateWorkspace() {
  // REDUX HOOKS
  const dispatch = useDispatch();
  const buildingsWorkspaces = useSelector(getBuildingsWorkspaces);
  const specialities = useSelector(getSpecialities);
  const user = useSelector(selectedUser);
  // REACT ROUTER HOOKS
  const history = useHistory();
  // MATERIAL UI
  const classes = useStyles();
  // REACT STATE
  const [state, setState] = useState(false);
  const [workspace, setWorkspace] = useState({
    type: "1",
    title: "",
    priority: "",
    buildings: [],
    // speciality: "",
    specialities: [],
    medicalFormModels: "",
    emailFilters: "",
    emailList: "",
    autoRegistration: false,
    autoProfileValidation: false,
    proProfilFilter: "",
  });

  const WORKSPACEISB2B =
    workspace.type === String(EWorkspaceType.B2B) ||
    workspace.type === EWorkspaceType.B2B;

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState(open);
  };

  /**
   * Change the state of the current building in select
   * @param {*string} val building key
   */
  const handleChangeBuilding = (building) => {
    const findBuildingIndex = workspace.buildings.findIndex(
      (b) => b.key === building.key
    );
    if (findBuildingIndex > -1) {
      const filteredBuildings = workspace.buildings.filter(
        (b) => b.key !== building.key
      );
      setWorkspace({
        ...workspace,
        buildings: filteredBuildings,
      });
    } else {
      setWorkspace({
        ...workspace,
        buildings: [...workspace.buildings, building],
      });
    }
  };

  /**
   * Change the state of the current building in select
   * @param {*string} val building key
   */
  const handleChange = (e) => {
    const updatedWorkspace = { ...workspace };
    updatedWorkspace[e.target.name] = e.target.value;
    if (e.target.name === "autoRegistration") {
      updatedWorkspace["autoRegistration"] = !workspace.autoRegistration;
    }
    if (e.target.name === "autoProfileValidation") {
      updatedWorkspace["autoProfileValidation"] =
        !workspace.autoProfileValidation;
    }
    setWorkspace(updatedWorkspace);
  };

  const handleDelete = (key) => {
    const findBuildingIndex = workspace.buildings.findIndex(
      (b) => b.key === key
    );
    if (findBuildingIndex > -1) {
      const filteredBuildings = workspace.buildings.filter(
        (b) => b.key !== key
      );
      setWorkspace({
        ...workspace,
        buildings: filteredBuildings,
      });
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const priority = parseInt(workspace.priority);
    const type = parseInt(workspace.type);
    const emailFilters = workspace.emailFilters
      ? [workspace.emailFilters.split(";").join(",")]
      : null;
    const emailList = workspace.emailList
      ? [workspace.emailList.split(";").join(",")]
      : null;
    const medicalFormModels = workspace.medicalFormModels
      ? [workspace.medicalFormModels.split(";").join(",")]
      : null;
    const buildings = workspace.buildings.map((building) => building.key);
    const specialities =
      workspace.specialities.length > 0 ? [...workspace.specialities] : null;

    const workspaceParameter = {
      autoRegistration: workspace.autoRegistration,
      autoProfileValidation: workspace.autoProfileValidation,
      buildings: buildings,
      // speciality:
      //   workspace.speciality.trim().length > 0 ? workspace.speciality : null,
      specialities: specialities,
      emailFilters: emailFilters,
      emailList: emailList,
      medicalFormModels: medicalFormModels,
      priority,
      proProfilFilter: workspace.proProfilFilter,
      title: workspace.title,
      type,
    };

    dispatch(createWorkspace(workspaceParameter)).then((res) => {
      if (res.status === 200) {
        dispatch(pushCreatedWorkspace(res.data));
        dispatch(updateWorkspaceInBuildings(res.data));
        dispatch(
          setSnackbar({
            open: true,
            snackbarMessage: "L'espace a bien été créé",
          })
        );
        history.push("/workspaces");
      }
    });
  };

  //Create a string with all specialities
  const specialitiesText = (selected) =>
    selected
      ?.map(
        (speciality) =>
          specialities?.find((spec) => spec?.key === speciality)?.label
      )
      ?.join(", ");

  //Multiple select style
  const MenuProps = {
    getContentAnchorEl: null,
    PaperProps: {
      style: {
        maxHeight: 400,
        width: 400,
      },
    },
  };

  return (
    <div className="createWorkspace">
      <header className="createWorkspace__header">
        <h4 className="createWorkspace__header--title">Créer un espace</h4>
        <p className="createWorkspace__header--text">
          Cette étape va vous permettre de créer un espace de travail pour tous
          les membres (médecins, infirmiers, etc.) qui en feront parti.
        </p>
      </header>
      <div className="createWorkspace__container">
        <form onSubmit={handleSubmit} className="createWorkspace__form">
          <fieldset className="createWorkspace__fieldset">
            {user?.license?.type === ELicence.B2B ? (
              <FormControl
                variant="outlined"
                className="createWorkspace__fields"
              >
                <InputLabel htmlFor="outlined-age-native-simple">
                  Type d'espace
                </InputLabel>
                <Select
                  native
                  value={workspace.type}
                  onChange={handleChange}
                  label="Type d'espace"
                  inputProps={{
                    name: "type",
                    id: "outlined-age-native-simple",
                  }}
                  size="small"
                >
                  <option value="1">PRO</option>
                  <option value="2">B2B</option>
                </Select>
              </FormControl>
            ) : (
              <div className="createWorkspace__fields">
                <TextField
                  disabled
                  id="outlined-disabled"
                  variant="outlined"
                  value="PRO"
                  size="small"
                  fullWidth
                />
                <TextField value={workspace.type} type="hidden" name="type" />
              </div>
            )}

            <InputLabel>
              Spécialité{workspace?.specialities?.length > 1 && "s"} de l'espace
            </InputLabel>
            <Select
              name="specialities"
              multiple
              value={workspace?.specialities}
              onChange={handleChange}
              MenuProps={MenuProps}
              renderValue={specialitiesText}
              fullWidth
            >
              {specialities
                ?.concat()
                ?.sort((a, b) => {
                  if (a.label < b.label) {
                    return -1;
                  }
                  if (a.label > b.label) {
                    return 1;
                  }
                  return 0;
                })
                ?.map((specie) => (
                  <MenuItem key={specie?.key} value={specie.key}>
                    <ListItemText> {specie?.label}</ListItemText>
                    <Checkbox
                      checked={workspace?.specialities?.includes(specie?.key)}
                    />
                  </MenuItem>
                ))}
            </Select>

            {/* <FormControl variant="outlined" className="createWorkspace__fields">
              <InputLabel id="demo-mutiple-checkbox-label">
                Spécialité
              </InputLabel>
              <Select
                native
                value={workspace.speciality}
                onChange={handleChange}
                label="Spécialité"
                inputProps={{
                  name: "speciality",
                  id: "outlined-age-native-simple",
                }}
                size="small"
              >
                <option value="">Pas de spécialité</option>
                {specialities
                  ?.concat()
                  .sort((a, b) => {
                    if (a.label && b.label) {
                      if (a.label.toLowerCase() < b.label.toLowerCase()) {
                        return -1;
                      }
                      if (a.label.toLowerCase() > b.label.toLowerCase()) {
                        return 1;
                      }
                      return 0;
                    }
                    return 0;
                  })
                  .map((speciality) => (
                    <option value={speciality.key} key={speciality.key}>
                      {speciality.label}
                    </option>
                  ))}
              </Select>
            </FormControl> */}

            <div className="createWorkspace__fields">
              <div style={{ display: "flex", alignItems: "center" }}>
                <InputLabel id="demo-mutiple-checkbox-label">
                  Etablissements
                </InputLabel>
                <IconButton onClick={toggleDrawer("right", true)}>
                  <AddCircleOutlineOutlinedIcon color="primary" />
                </IconButton>
              </div>
              <FormControl className="createWorkspace__fields">
                <div className="createWorkspace__chips">
                  {workspace.buildings.map((building) => (
                    <Chip
                      label={building.name}
                      onDelete={() => handleDelete(building.key)}
                      value={building.key}
                      key={building.key}
                      clickable={true}
                      color="primary"
                      variant="outlined"
                      style={{ marginBottom: "5px", marginRight: "5px" }}
                    />
                  ))}
                </div>
              </FormControl>
            </div>

            <div className="createWorkspace__fields">
              <TextField
                error={false}
                label="Espace"
                id="outlined-error-helper-text"
                placeholder="Nom de l'espace"
                helperText="* Le nom de l'espace est obligatoire"
                variant="outlined"
                name="title"
                value={workspace.title}
                InputLabelProps={{
                  shrink: true,
                }}
                size="small"
                fullWidth
                required
                onChange={handleChange}
              />
            </div>

            <div className="createWorkspace__fields createWorkspace__fields--dual">
              {WORKSPACEISB2B && (
                <TextField
                  label="Niveau de priorité"
                  id="outlined-error-helper-text"
                  placeholder="Niveau de priorité dans la vue"
                  variant="outlined"
                  name="priority"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  value={workspace.priority}
                  size="small"
                  className="createWorkspace__fields--field"
                  onChange={handleChange}
                />
              )}
              <TextField
                label="Model de fiche patient"
                id="outlined-error-helper-text"
                placeholder="Model de fiche patient à rendre disponible"
                variant="outlined"
                name="medicalFormModels"
                value={workspace.medicalFormModels}
                InputLabelProps={{
                  shrink: true,
                }}
                size="small"
                className="createWorkspace__fields--field"
                onChange={handleChange}
              />
            </div>
            {WORKSPACEISB2B && (
              <>
                <div className="createWorkspace__fields createWorkspace__fields--dual">
                  <TextField
                    multiline
                    label="Filtre email"
                    id="outlined-error-helper-text"
                    placeholder="Filtre les emails autorisés dans l'espace"
                    variant="outlined"
                    name="emailFilters"
                    value={workspace.emailFilters}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    helperText="(Ex: @toto.fr ; @titi.fr)"
                    size="small"
                    className="createWorkspace__fields--field"
                    onChange={handleChange}
                  />
                  <TextField
                    multiline
                    label="Liste email"
                    id="outlined-error-helper-text"
                    placeholder="Liste d'emails à autoriser dans l'espace"
                    variant="outlined"
                    name="emailList"
                    value={workspace.emailList}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    helperText="(Ex: @toto.fr,1 ; @titi.fr,0)"
                    size="small"
                    className="createWorkspace__fields--field"
                    onChange={handleChange}
                  />
                </div>

                <div className="createWorkspace__fields createWorkspace__fields--dual">
                  <div className="createWorkspace__fields--field">
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={workspace.autoRegistration}
                          onChange={handleChange}
                          name="autoRegistration"
                        />
                      }
                      label="Auto enregistrement"
                    />
                    {WORKSPACEISB2B && user?.profileLevel === 2 && (
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={workspace.autoProfileValidation}
                            onChange={handleChange}
                            name="autoProfileValidation"
                          />
                        }
                        label="Auto validant"
                      />
                    )}
                  </div>
                  <TextField
                    multiline
                    label="Profil professionnel"
                    id="outlined-error-helper-text"
                    placeholder="Liste de profils à autoriser dans l'espace"
                    variant="outlined"
                    name="proProfilFilter"
                    value={workspace.proProfilFilter}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    helperText="(Ex: ....)"
                    size="small"
                    className="createWorkspace__fields--field"
                    onChange={handleChange}
                  />
                </div>
              </>
            )}
          </fieldset>
          <div className="text-center">
            <ButtonValid
              type="submit"
              name="createWorkspace"
              title="Enregistrer"
              style={{ width: "100%" }}
            />
          </div>
        </form>
        <Drawer
          anchor="right"
          open={state}
          onClose={toggleDrawer("right", false)}
          className={classes.drawer}
        >
          <Typography className={classes.drawerTitle}>
            Selectionner les établissements à ajouter ou retirer
          </Typography>
          <Divider />
          <div className={classes.drawerContent}>
            {buildingsWorkspaces.map(
              (building, index) =>
                building.name && (
                  <MenuItem
                    key={index}
                    value={building.key}
                    onClick={() => handleChangeBuilding(building)}
                  >
                    <Checkbox
                      checked={workspace.buildings
                        .map((b) => b.key)
                        .includes(building.key)}
                    />
                    <ListItemText primary={building.name} />
                  </MenuItem>
                )
            )}
          </div>
          {/* <Button className={classes.drawerButton}>Ajouter</Button> */}
        </Drawer>
      </div>
    </div>
  );
}

const useStyles = makeStyles(({ palette }) => ({
  drawer: {
    position: "relative",
  },
  drawerTitle: {
    padding: 10,
    width: 300,
    textAlign: "center",
    margin: "0 auto",
  },
  drawerContent: {
    overflowY: "auto",
  },
  drawerButton: {
    position: "absolute",
    width: "100%",
    bottom: 0,
    backgroundColor: palette.primary.main,
    color: "white",
    transition: "color .3s ease",
    "&:hover": {
      color: palette.primary.main,
    },
  },
}));

export default CreateWorkspace;
