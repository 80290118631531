import { CircularProgress } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { getAdsList } from "../../redux/advertisement/thunks";
import SelectedAds from "../SelectedAds/SelectedAds";
import List from "@material-ui/core/List";
import Ads from "../Ads/Ads";

const AdvertisementList = () => {
  // REDUX HOOK
  const dispatch = useDispatch();
  // REACT STATE
  const [adsList, setAdsList] = useState([]);
  const [isPending, setIsPending] = useState(false);
  const [selectedAds, setSelectedAds] = useState(null);

  const handleFilterListAfterDelete = (adsKey) => {
    const updateAdsList = [...adsList];
    const index = updateAdsList.findIndex((ads) => ads.key === adsKey);
    updateAdsList.splice(index, 1);
    setAdsList(updateAdsList);
    setSelectedAds(null);
  };
  const handleUpdateListAfterUpdate = (ads) => {
    const updateAdsList = [...adsList];
    const index = updateAdsList.findIndex(
      (updateAds) => updateAds.key === ads.key
    );
    updateAdsList[index] = ads;
    setAdsList(updateAdsList);
    setSelectedAds(null);
  };

  useEffect(() => {
    let mounted = true;
    if (mounted) {
      setIsPending(true);
      dispatch(getAdsList()).then((response) => {
        if (response && response.status === 200) {
          setAdsList(response.data.chatrooms);
          setIsPending(false);
        }
      });
    }

    return () => {
      mounted = false;
    };
  }, [dispatch]);

  if (isPending) {
    return (
      <div className="advertisementListLoader">
        <CircularProgress />
      </div>
    );
  }

  return (
    <div className="advertisementList">
      <List className="advertisementList__list">
        {adsList.map((ads) => (
          <Ads key={ads.key} ads={ads} setSelectedAds={setSelectedAds} />
        ))}
      </List>
      {selectedAds ? (
        <SelectedAds
          selectedAds={selectedAds}
          setSelectedAds={setSelectedAds}
          handleFilterListAfterDelete={handleFilterListAfterDelete}
          handleUpdateListAfterUpdate={handleUpdateListAfterUpdate}
        />
      ) : null}
    </div>
  );
};

export default AdvertisementList;
