// REACT
import React from "react";
// REACT ROUTER
import { useHistory } from "react-router-dom";
// IMAGES
import WORKSPACEICON from "../../../assets/images/workspace.png";
// REDUX
import { useDispatch } from "react-redux";
import { setWorkspace } from "../../../redux/workspaces/actions";

function WorkspaceCard({ workspace }) {
  // REDUX HOOKS
  const dispatch = useDispatch();
  // REACT ROUTER HOOKS
  const history = useHistory();

  const handleSelectWorkspace = () => {
    history.push(`/workspace/${workspace.key}`);
    dispatch(setWorkspace(workspace));
  };

  return (
    <div onClick={handleSelectWorkspace} className="workspaceCard">
      <div className="workspaceCard__content">
        <div className="workspaceCard__logo">
          <img src={WORKSPACEICON} width="60px" height="60px" alt="" />
        </div>
        <div className="workspaceCard__name">
          {workspace.title.substring(0, 40)}
        </div>
      </div>
    </div>
  );
}

export default WorkspaceCard;
