// REACT
import React from "react";
// REACT ROUTER
import { useLocation, useHistory } from "react-router-dom";
// MATERIAL UI
import { Typography } from "@material-ui/core";
// COMPONENTS
import UserCard from "../UserCard/UserCard";
// REDUX
import { selectedUser } from "../../redux/user/slices";
import { authData } from "../../redux/auth/slices";
import { useSelector } from "react-redux";

function Header() {
  // REACT ROUTER HOOKS
  const location = useLocation();
  const history = useHistory();
  // REDUX HOOKS
  const user = useSelector(selectedUser);
  const authUser = useSelector(authData);

  const getLocation = () => {
    if (location.pathname.includes("buildings")) {
      return "Etablissements";
    }
    if (location.pathname.includes("building")) {
      return "Etablissement";
    }
    if (location.pathname.includes("workspaces")) {
      return "Espaces";
    }
    if (location.pathname.includes("workspace")) {
      return "Espace";
    }
    if (location.pathname.includes("profile")) {
      return "Profil utilisateur";
    }
    if (location.pathname.includes("associations")) {
      return "Association d'espaces";
    }
  };

  return (
    <header className="header">
      <div className="header__left">
        <Typography variant="h6">
          {/* {location.pathname.replace("/", "").toUpperCase()} */}
          {getLocation()}
        </Typography>
        <div className="header__devInfo">
          <Typography style={{ fontSize: "0.9rem" }}>
            SID : {authUser?.authData?.sid}
          </Typography>
          <Typography style={{ fontSize: "0.9rem" }}>
            CUser : {user?.key}
          </Typography>
        </div>
      </div>
      <div className="header__right">
        <UserCard
          user={user}
          onClick={() => history.push(`/profile/${user?.key}`)}
        />
      </div>
    </header>
  );
}

export default Header;
