// NPM
import { sha256 } from "js-sha256";
import axios from "axios";
// CONFIG
import { generateUuidV4 } from "../../config/device";
import { BOAUTHENTICATION, BOUSER } from "../../api/api";
import { API_URL, settings } from "../../config/settings";
// REDUX ACTIONS
import { clearAuthData } from "./actions";
import { clearRessources } from "../ressources/actions";
import { clearUser } from "../user/actions";
import { BOSESSION } from "./../../api/api";

/**
 * Allows us to make a call to the api with the user's data
 * @param {*Object} login
 * @returns DTOAuthentication
 */
export const login =
  ({ login, password, api }) =>
  async (dispatch) => {
    // Current deviceId
    const uuid = generateUuidV4();
    // Generate sha256 token
    const token1 = sha256(uuid);
    const token3 = sha256(login + password);
    const token2 = sha256(token1 + token3);
    const version = `${settings.version}${settings.number}`;
    // Set selected API for other calls
    localStorage.setItem("API", api);

    try {
      const auth = await axios.post(
        `${API_URL[api]}${BOAUTHENTICATION.authenticate}`,
        {
          login,
          deviceUid: uuid,
          token1,
          token2,
          version,
        }
      );
      if (auth.status === 200) {
        // Set selected API for other calls
        localStorage.setItem("SID", auth.data.sid);
        return auth;
      }
    } catch (err) {
      console.error("AUTHENTICATE_ERROR", err);
      return err;
    }
  };

/**
 * Allows you to search for a user/users.
 * Sends a call to the api with the search parameters
 * @param {*string} user the user to search
 * @returns DTOUsers {count, groups, users[DTOUser]}
 */
export const searchUser = (page, filter) => async (dispatch, getState) => {
  try {
    const search = await axios.post(
      BOUSER.search,
      {
        context: 7,
        filter: filter,
        page,
      },
      {
        headers: {
          sid: getState().auth.authData.sid,
        },
      }
    );

    if (search.status === 200) {
      return search;
    }
  } catch (err) {
    console.error("SEARCHUSER_ERROR", err);
  }
};

/**
 * Delete the active session and delete the data in the localStorage
 * @returns void
 */
export const logout = () => async (dispatch, getState) => {
  dispatch(clearAuthData());
  dispatch(clearRessources());
  dispatch(clearUser());
};
