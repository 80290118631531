import { createSlice, current } from "@reduxjs/toolkit";

const workspaceInitialState = {
  workspaces: null,
  workspace: {},
};

export const workspaceSlice = createSlice({
  name: "workspace",
  initialState: workspaceInitialState,
  reducers: {
    setWorkspaces: (state, { payload }) => {
      state.workspaces = payload;
    },
    setWorkspace: (state, { payload }) => {
      state.workspace = payload;
    },
    pushCreatedWorkspace: (state, { payload }) => {
      state.workspaces.push(payload);
    },
    updateUnitInWorkspace: (state, { payload }) => {
      const unitIndex = state.workspace.units.findIndex(
        (unit) => unit.key === payload.key
      );
      if (unitIndex !== -1) {
        state.workspace.units[unitIndex] = payload;
      }
    },
    updateUnitsInWorkspace: (state, { payload }) => {
      state.workspace.units = state.workspace.units.filter(
        (unit) => !payload.includes(unit.key)
      );
    },
    addUnitsToWorkspace: (state, { payload }) => {
      state.workspace.units
        ? state.workspace.units.push(...payload)
        : (state.workspace.units = payload);
    },
    addBedsToWorkspace: (state, { payload }) => {
      state.workspace.beds
        ? state.workspace.beds.push(...payload)
        : (state.workspace.beds = payload);
    },
    updateBedInWorkspace: (state, { payload }) => {
      const bedIndex = state.workspace.beds.findIndex(
        (bed) => bed.key === payload.key
      );
      if (bedIndex !== -1) {
        state.workspace.beds[bedIndex] = payload;
      }
    },
    updateBedsInWorkspace: (state, { payload }) => {
      state.workspace.beds = state.workspace.beds.filter(
        (bed) => !payload.includes(bed.key)
      );
    },
    removeWorkspace: (state, { payload }) => {
      state.workspaces = state.workspaces.filter(
        (workspace) => workspace.key !== payload
      );
    },
    updateCurrentWorkspace: (state, { payload }) => {
      state.workspace = { ...state.workspace, ...payload };
      const workspaceIndex = state.workspaces.findIndex(
        (wk) => wk.key === payload.key
      );

      if (workspaceIndex !== -1) {
        state.workspaces[workspaceIndex] = {
          ...state.workspaces[workspaceIndex],
          ...payload,
        };
      }
    },
  },
});

export const selectedWorkspace = (state) => state.workspace.workspace;
export const allWorkspaces = (state) => state.workspace.workspaces;
