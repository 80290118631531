import React, { useState } from "react";
// MATERIAL UI
import {
  Checkbox,
  FormControl,
  InputLabel,
  Select,
  ListItemText,
  TextField,
  MenuItem,
  Button,
} from "@material-ui/core";
// COMPONENTS
import AvatarBuilding from "../../components/Buildings/AvatarBuilding/AvatarBuilding";
// HOOKS
import useAvatar from "../../hooks/useAvatar";
// REDUX
import { useDispatch, useSelector } from "react-redux";
import { getRessources, getSpecialities } from "../../redux/ressources/slices";
import { setSnackbar } from "../../redux/snackbar/actions";
import { createAds } from "../../redux/advertisement/thunks";

const AdvertisementForm = () => {
  // REDUX HOOKS
  const dispatch = useDispatch();
  const specialities = useSelector(getSpecialities);
  const initRessource = useSelector(getRessources);
  // CUSTOM HOOKS
  const {
    saveAvatar,
    openPopover,
    anchorImage,
    inputImg,
    url,
    idPopover,
    isOpenFile,
    selectedFiles,
    blob,
    handleClick,
    handleClosePopOver,
    handleDeleteImage,
    handleCloseDialog,
    handleSubmitImage,
    handleCloseConfirm,
    onInputChange,
    getBlob,
  } = useAvatar();
  // REACT STATE
  const [ads, setAds] = useState({
    title: "",
    proStatuses: [],
    specialities: [],
  });

  const MenuProps = {
    getContentAnchorEl: null,
    PaperProps: {
      style: {
        maxHeight: 400,
        width: 400,
      },
    },
  };

  // Create a string with all specialities
  const specialitiesText = (selected) =>
    selected
      ?.map(
        (speciality) =>
          specialities?.find((spec) => spec?.key === speciality)?.label
      )
      ?.join(", ");

  // Create a string with all proStatuses
  const proStatusesText = (selected) =>
    selected
      ?.map(
        (status) =>
          initRessource["pro-status-flat"]?.find((spec) => spec?.key === status)
            ?.label
      )
      ?.join(", ");

  const handleChange = (e) => {
    const updateUser = { ...ads };
    updateUser[e.target.name] = e.target.value;
    setAds(updateUser);
  };

  const resetField = () => {
    setAds({
      title: "",
      proStatuses: [],
      specialities: [],
    });
    handleCloseConfirm();
    handleDeleteImage();
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (!ads.title) return;

    const proStatuses = ads.proStatuses.length > 0 ? ads.proStatuses : null;
    const specialities = ads.specialities.length > 0 ? ads.specialities : null;

    const createAdsData = new FormData();
    createAdsData.append("title", ads.title);
    if (proStatuses)
      proStatuses.forEach((proStatus) =>
        createAdsData.append("proStatuses", proStatus)
      );
    if (specialities)
      specialities.forEach((speciality) =>
        createAdsData.append("specialities", speciality)
      );
    if (selectedFiles && selectedFiles.length > 0) {
      createAdsData.append("avatar", blob);
      for (let selectedFile of selectedFiles) {
        createAdsData.append("filename", selectedFile.name);
        createAdsData.append("mimetype", selectedFile.type);
      }
    }

    dispatch(createAds(createAdsData)).then((res) => {
      if (res && res.status === 200) {
        dispatch(
          setSnackbar({
            open: true,
            snackbarMessage: "La publicité à bien été créé",
          })
        );

        resetField();
      }
    });
  };

  return (
    <form onSubmit={handleSubmit} className="advertisementForm">
      <AvatarBuilding
        saveAvatar={saveAvatar}
        openPopover={openPopover}
        anchorImage={anchorImage}
        inputImg={inputImg}
        url={url}
        id={idPopover}
        isOwner={true}
        open={isOpenFile}
        handleClick={handleClick}
        handleClosePopOver={handleClosePopOver}
        handleDeleteImage={handleDeleteImage}
        handleCloseDialog={handleCloseDialog}
        handleSubmitImage={handleSubmitImage}
        onInputChange={onInputChange}
        getBlob={getBlob}
      >
        <>Ajouter un avatar a votre publicité</>
        <></>
      </AvatarBuilding>
      <TextField
        required
        name="title"
        value={ads?.title}
        label="Titre de la publicité"
        onChange={handleChange}
        className="ads__input"
        fullWidth
        autoFocus
      />

      <FormControl variant="outlined" className="ads__select" fullWidth>
        <InputLabel>Status professionnel</InputLabel>
        <Select
          name="proStatuses"
          multiple
          value={ads?.proStatuses}
          onChange={handleChange}
          MenuProps={MenuProps}
          renderValue={proStatusesText}
          fullWidth
        >
          {initRessource["pro-status"]
            ?.concat()
            ?.sort((a, b) => a.label.localeCompare(b.label))
            ?.map((status) =>
              status.status
                ?.concat()
                ?.sort((a, b) => a.label.localeCompare(b.label))
                ?.map((stat) => (
                  <MenuItem key={stat?.key} value={stat.key}>
                    <ListItemText> {stat?.label}</ListItemText>
                    <Checkbox checked={ads?.proStatuses?.includes(stat?.key)} />
                  </MenuItem>
                ))
            )}
        </Select>
      </FormControl>

      <FormControl variant="outlined" className="ads__select" fullWidth>
        <InputLabel>Spécialités</InputLabel>
        <Select
          name="specialities"
          multiple
          value={ads?.specialities}
          onChange={handleChange}
          MenuProps={MenuProps}
          renderValue={specialitiesText}
          fullWidth
        >
          {specialities
            ?.concat()
            ?.sort((a, b) => {
              if (a.label < b.label) {
                return -1;
              }
              if (a.label > b.label) {
                return 1;
              }
              return 0;
            })
            ?.map((specie) => (
              <MenuItem key={specie?.key} value={specie.key}>
                <ListItemText> {specie?.label}</ListItemText>
                <Checkbox checked={ads?.specialities?.includes(specie?.key)} />
              </MenuItem>
            ))}
        </Select>
      </FormControl>
      <Button
        fullWidth
        variant="contained"
        color="primary"
        disabled={!ads.title}
        type="submit"
      >
        Créer
      </Button>
    </form>
  );
};

export default AdvertisementForm;
