export const EWorkspaceType = {
  PERSONAL: 0,
  PRO: 1,
  B2B: 2,
};

/**
 * return license type
 * Pro = 20
 * B2B = 30
 */
export const ELicence = {
  PRO: 20,
  B2B: 30,
};

export const EWorkspaceTypeValue = {
  0: "PERSONAL",
  1: "PRO",
  2: "B2B",
};

export const EProfilStatus = {
  0: "Pas encore validé",
  1: "En cours de validation",
  2: "Refusé",
  3: "Validé",
};
