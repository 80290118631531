// REACT
import React from "react";
// REACT ROUTER
import { useHistory } from "react-router";
// MATERIAL UI
import { withStyles, makeStyles } from "@material-ui/core/styles";
import { TableCell, TableRow } from "@material-ui/core";

function BuildingListRow({ building }) {
  // REACT ROUTER
  const history = useHistory();
  // MATERIAL UI
  const classes = useStyles();

  return (
    <>
      <StyledTableRow
        key={building?.key}
        onClick={() => history.push(`/building/${building?.key}`)}
        className={classes.row}
      >
        <StyledTableCell component="th" scope="row">
          {building?.name}
        </StyledTableCell>
        <StyledTableCell align="right">
          {building?.nameShort || building?.name_short}
        </StyledTableCell>
        <StyledTableCell align="right">
          {building?.externalCode || building?.external_code}
        </StyledTableCell>
        <StyledTableCell align="right">
          {`${building?.address?.street} ${building?.address?.city} ${building?.address?.pbox}`}
        </StyledTableCell>
      </StyledTableRow>
    </>
  );
}

const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: "#29b6f6",
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const useStyles = makeStyles({
  table: {
    minWidth: 700,
  },
  removeWorkspace: {
    color: "red",
  },
  row: {
    cursor: "pointer",
    transition: "backgroundColor .3s ease",
    "&:hover": {
      backgroundColor: "whitesmoke",
    },
  },
});

export default BuildingListRow;
