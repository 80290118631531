import React, { useState } from "react";
// MATERIAL UI
import {
  Divider,
  Typography,
  FormControl,
  InputLabel,
  Select,
} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import ButtonValid from "./../../../../Buttons/ButtonValid";
// REDUX
import { useDispatch, useSelector } from "react-redux";
import { selectedWorkspace } from "../../../../../redux/workspaces/slices";
import { updateBedInWorkspace } from "../../../../../redux/workspaces/actions";
import { updateBed } from "../../../../../redux/workspaces/thunks";
import { setSnackbar } from "../../../../../redux/snackbar/actions";

function Bed({ bed, handleSelectBedForDelete, deleteBeds, selectedBeds }) {
  // REDUX HOOKS
  const dispatch = useDispatch();
  const workspace = useSelector(selectedWorkspace);
  // REACT STATE
  const [openEditBed, setOpenEditBed] = useState(false);
  const [selectedBed, setSelectedBed] = useState({ ...bed });

  /**
   * Update the state of the selected unit
   */
  const handleChange = ({ target: { name, value } }) => {
    const updatedBed = { ...selectedBed };
    updatedBed[name] = value;
    setSelectedBed(updatedBed);
  };

  /**
   * Open Modal for edit unit
   */
  const handleOpenEditBed = () => {
    setOpenEditBed((beds) => !beds);
  };

  /**
   * Close Modal
   */
  const handleClose = () => {
    setOpenEditBed((beds) => !beds);
  };

  /**
   * Update the selected bed
   */
  const handleSubmit = (e) => {
    e.preventDefault();
    const bedParameter = {
      beds: [selectedBed],
    };
    dispatch(updateBed(bedParameter)).then((res) => {
      if (res.status === 200) {
        dispatch(updateBedInWorkspace(...res.data));
        handleClose();
        dispatch(
          setSnackbar({
            open: true,
            snackbarMessage: `Le lit ${selectedBed.name} a bien été modifié`,
          })
        );
      }
    });
  };

  const isSelected = selectedBeds.includes(bed.key);

  return (
    <>
      <div
        className="bed"
        style={{
          borderLeft: `2px solid ${bed.available ? "#26a69a" : "#e53935"}`,
          backgroundColor: isSelected ? "#ff000047" : "#f4f4f5",
          border: deleteBeds
            ? `1px dashed ${isSelected ? "red" : "black"}`
            : "inherit",
        }}
        onClick={() =>
          deleteBeds ? handleSelectBedForDelete(bed.key) : handleOpenEditBed()
        }
      >
        <Typography className="bed__info">{bed.index}</Typography>
        <Divider orientation="vertical" />
        <div className="bed__info bed__info--image"></div>
        <Divider orientation="vertical" />
        <Typography className="bed__info">{bed.name}</Typography>
      </div>
      <Dialog
        open={openEditBed}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">
          Modifier le lit {bed.name}
        </DialogTitle>
        <DialogContent>
          <div className="unit__bedDialog">
            <div className="unit__addDialog--field ">
              <TextField
                name="index"
                label="Position"
                type="text"
                className="unit__field"
                variant="outlined"
                size="small"
                value={selectedBed.index}
                onChange={handleChange}
              />
              <TextField
                name="name"
                label="Nom de l'unité"
                type="text"
                className="unit__field"
                variant="outlined"
                size="small"
                value={selectedBed.name}
                onChange={handleChange}
              />
            </div>
            <div className="unit__addDialog--field ">
              <FormControl
                variant="outlined"
                className="createWorkspace__fields"
                fullWidth
                style={{ width: "48%" }}
              >
                <InputLabel htmlFor="outlined-age-native-simple">
                  Ajouter dans une unité
                </InputLabel>
                <Select
                  native
                  value={selectedBed.unit}
                  onChange={handleChange}
                  label="Ajouter dans une unité"
                  inputProps={{
                    name: "unit",
                    id: "outlined-age-native-simple",
                  }}
                  size="small"
                >
                  <option aria-label="None" value="" />
                  {workspace?.units &&
                    workspace?.units.length > 0 &&
                    workspace?.units.map((unit) => (
                      <option value={unit.key} key={unit.key}>
                        {unit.name}
                      </option>
                    ))}
                </Select>
              </FormControl>
              <FormControl
                variant="outlined"
                className="createWorkspace__fields"
                fullWidth
                style={{ width: "48%" }}
              >
                <InputLabel htmlFor="outlined-age-native-simple2">
                  Disponible
                </InputLabel>
                <Select
                  native
                  value={selectedBed.available}
                  onChange={handleChange}
                  label="Disponible"
                  inputProps={{
                    name: "available",
                    id: "outlined-age-native-simple2",
                  }}
                  size="small"
                >
                  <option value={true}>Oui</option>
                  <option value={false}>Non</option>
                </Select>
              </FormControl>
            </div>
            <div className="unit__addDialog--field ">
              <TextField
                name="comment"
                label="Commentaire"
                type="text"
                className="unit__field"
                variant="outlined"
                value={selectedBed.comment}
                onChange={handleChange}
                multiline
                fullWidth
              />
            </div>
          </div>
        </DialogContent>
        <Divider />
        <DialogActions>
          <Button onClick={handleClose} style={{ width: "50%" }}>
            Annuler
          </Button>
          <ButtonValid
            onClick={handleSubmit}
            title="Modifier"
            style={{ width: "50%" }}
          />
        </DialogActions>
      </Dialog>
    </>
  );
}

export default Bed;
