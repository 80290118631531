import React from "react";
import { Plus } from "../../assets/svg/Plus";

function CreateWorkspaceCard({ onClick }) {
  return (
    <div className="createWorkspaceCard" onClick={onClick}>
      <div className="createWorkspaceCard__container">
        <div className="createWorkspaceCard__svg">
          <Plus />
        </div>
      </div>
    </div>
  );
}

export default CreateWorkspaceCard;
