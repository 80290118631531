import { workspaceSlice } from "./slices";

export const {
  setWorkspace,
  setWorkspaces,
  pushCreatedWorkspace,
  updateUnitInWorkspace,
  updateUnitsInWorkspace,
  addUnitsToWorkspace,
  addBedsToWorkspace,
  updateBedInWorkspace,
  updateBedsInWorkspace,
  removeWorkspace,
  updateCurrentWorkspace,
} = workspaceSlice.actions;
