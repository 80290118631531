import { createSlice } from "@reduxjs/toolkit";

// intialState
const snackbarInitialState = {
  open: false,
  snackbarMessage: null,
};

// Snackbar Slice
export const snackbarSlice = createSlice({
  name: "snackbar", // name in redux dev tools
  initialState: snackbarInitialState,
  reducers: {
    setSnackbar: (state, { payload }) => {
      state.open = payload.open;
      state.snackbarMessage = payload.snackbarMessage;
    },
  },
});

/**
 * Return the state of the snackbar
 * @param {*Object} state current snackbar state
 * @returns Object
 */
export const useSnackbar = (state) => state.snackbar;
