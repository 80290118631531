// REACT
import React, { useState } from "react";
// COMPONENTS
import Bed from "../WorkspaceUnits/Units/Bed/Bed";
// MATERIAL UI
import {
  Divider,
  Typography,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  IconButton,
  InputLabel,
  Select,
  TextField,
  Tooltip,
} from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import RemoveCircleOutlineIcon from "@material-ui/icons/RemoveCircleOutline";
import ClearIcon from "@material-ui/icons/Clear";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
// COMPONENTS
import ButtonValid from "../../Buttons/ButtonValid";
// REDUX
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { selectedWorkspace } from "../../../redux/workspaces/slices";
import {
  createBeds,
  deleteBedsInWorkspace,
} from "../../../redux/workspaces/thunks";
import {
  addBedsToWorkspace,
  updateBedsInWorkspace,
} from "../../../redux/workspaces/actions";
import { setSnackbar } from "../../../redux/snackbar/actions";

function WorkspaceBeds() {
  // REDUX HOOKS
  const dispatch = useDispatch();
  const workspace = useSelector(selectedWorkspace);
  // REACT STATE
  const [selectedBeds, setselectedBeds] = useState([]);
  const [deleteBeds, setDeleteBeds] = useState(false);
  const [openAddBeds, setOpenAddBeds] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [error, setError] = useState(false);
  const [checked, setChecked] = useState(false);
  const [beds, setBeds] = useState({
    count: "",
    name: "",
    startAt: "",
    unit: "",
  });

  /**
   * function to change the state of the beds in order to select them for deletion,
   * if we are already in the state of deletion of units we return false
   */
  const handleDeleteBeds = () => {
    setDeleteBeds((db) => !db);
  };

  /**
   * function to cancel the deletion of beds.
   * We return an empty beds selection table if we cancel
   */
  const handleCancelDeleteBeds = () => {
    setDeleteBeds((db) => !db);
    setselectedBeds([]);
  };

  /**
   * function to select the beds to be deleted,
   * we check that the bed has not already been selected in which case we remove it from the selection array
   * @param {*string} bedKey
   */
  const handleSelectBedForDelete = (bedKey) => {
    if (selectedBeds.includes(bedKey)) {
      const filteredBed = selectedBeds.filter((b) => b !== bedKey);
      setselectedBeds(filteredBed);
    } else {
      setselectedBeds([...selectedBeds, bedKey]);
    }
  };

  /**
   * Open confirm add modal
   */
  const handleOpenAddBedsModal = () => {
    setOpenAddBeds((modalOpen) => !modalOpen);
  };

  /**
   * Open confirm delete modal
   */
  const handleOpenDeleteModal = () => {
    setOpenDeleteModal((modalOpen) => !modalOpen);
  };

  /**
   * Close confirm add modal
   */
  const handleCloseAddsBedsModal = () => {
    setOpenAddBeds((modalOpen) => !modalOpen);
  };

  /**
   * Close confirm delete modal
   */
  const handleCloseDeleteModal = () => {
    setOpenDeleteModal((modalOpen) => !modalOpen);
  };

  /**
   * Changes the state of the fields of the beds to be added in the workspace
   * @param {*} param0
   */
  const handleChangeBedToAdd = ({ target: { name, value, checked } }) => {
    const updatedBeds = { ...beds };
    if (name === "name") {
      setError(false);
    }
    updatedBeds[name] = value;
    setBeds(updatedBeds);
  };

  /**
   * Sends the beds to be created to the api
   */
  const handleSubmitAddBeds = (e) => {
    e.preventDefault();

    let bedsArray = [];
    let name;
    for (let u = 0; u < +beds.count; u++) {
      // IF SUFFIX ADD START INDEX + PADDING IF COUNT IN LESSER THAN 10
      if (checked) {
        if (u < 10) {
          name = `${beds.name}${("0" + (+beds.startAt + u)).slice(-2)}`;
        } else {
          name = `${beds.name}${+beds.startAt + u}`;
        }
        // ELSE RETURN ONLY
      } else {
        if (u < 10) {
          name = `${("0" + (+beds.startAt + u)).slice(-2)}`;
        } else {
          name = String(+beds.startAt + u);
        }
      }

      bedsArray.push({
        index: +beds.startAt + u,
        name,
        unit: beds.unit ? beds.unit : null,
      });
    }

    const bedsParameter = {
      beds: bedsArray,
    };

    if (checked) {
      if (beds.name.trim().length === 0) {
        setError(true);
      } else {
        dispatch(createBeds(bedsParameter, workspace.key)).then((res) => {
          if (res.status === 200) {
            dispatch(addBedsToWorkspace(res.data));
            handleOpenAddBedsModal();
            setBeds({
              count: "",
              name: "",
              startAt: "",
              unit: "",
            });
            setChecked(false);
            dispatch(
              setSnackbar({
                open: true,
                snackbarMessage: "Les lits ont bien été créés",
              })
            );
          }
        });
        setError(false);
      }
    } else {
      dispatch(createBeds(bedsParameter, workspace.key)).then((res) => {
        if (res.status === 200) {
          dispatch(addBedsToWorkspace(res.data));
          handleOpenAddBedsModal();
          setBeds({
            count: "",
            name: "",
            startAt: "",
            unit: "",
          });
          setChecked(false);
          dispatch(
            setSnackbar({
              open: true,
              snackbarMessage: "Les lits ont bien été créés",
            })
          );
        }
      });
      setError(false);
    }
  };

  /**
   * Sends the beds or units to be deleted to the api
   */
  const handleSubmit = (e) => {
    e.preventDefault();
    if (selectedBeds.length > 0) {
      const deletedBedsParameters = {
        beds: selectedBeds,
      };
      dispatch(deleteBedsInWorkspace(deletedBedsParameters)).then((res) => {
        if (res.status === 200) {
          dispatch(updateBedsInWorkspace(selectedBeds));
          setselectedBeds([]);
          setOpenDeleteModal((modalOpen) => !modalOpen);
          handleCancelDeleteBeds();
          dispatch(
            setSnackbar({
              open: true,
              snackbarMessage: "Les lits ont bien étés supprimés",
            })
          );
        }
      });
    }
  };

  const affectedBeds =
    workspace?.beds && workspace?.beds.filter((bed) => bed.unit);
  const unAffectedBeds =
    workspace?.beds && workspace?.beds.filter((bed) => !bed.unit);

  return (
    <>
      <div className="workspaceBeds">
        {workspace?.beds && workspace?.beds?.length > 0 ? (
          <>
            <div className="workspaceBeds__header">
              <div className="workspaceBeds__header--addBed">
                <Typography>Ajouter des lits</Typography>
                <IconButton onClick={handleOpenAddBedsModal}>
                  <Tooltip title="Ajouter des unités">
                    <AddCircleOutlineIcon color="primary" />
                  </Tooltip>
                </IconButton>
              </div>
              <div className="workspaceUnits__deleteBedsOrUnits workspaceUnits__deleteBedsOrUnits--beds">
                {deleteBeds ? (
                  <Button
                    variant="contained"
                    color="primary"
                    startIcon={<ClearIcon />}
                    onClick={handleCancelDeleteBeds}
                  >
                    Annuler
                  </Button>
                ) : (
                  <Button
                    variant="contained"
                    startIcon={<RemoveCircleOutlineIcon />}
                    onClick={handleDeleteBeds}
                    style={{ color: "white", backgroundColor: "#dc004e" }}
                  >
                    Lits
                  </Button>
                )}
                {selectedBeds.length > 0 && (
                  <div className="workspaceUnits__deleteBedsOrUnits workspaceUnits__deleteBedsOrUnits--delete">
                    <Button
                      variant="contained"
                      startIcon={<DeleteOutlineIcon />}
                      onClick={handleOpenDeleteModal}
                      style={{ color: "white", backgroundColor: "#dc004e" }}
                    >
                      Supprimer
                    </Button>
                  </div>
                )}
              </div>
            </div>
            {affectedBeds.length > 0 && (
              <Typography>Lits affectés {affectedBeds.length}</Typography>
            )}
            <Divider />
            <div className="workspaceBeds__container">
              {workspace?.beds
                ?.concat()
                .sort((a, b) => {
                  if (a.name && b.name) {
                    if (a.name.toLowerCase() < b.name.toLowerCase()) {
                      return -1;
                    }
                    if (a.name.toLowerCase() > b.name.toLowerCase()) {
                      return 1;
                    }
                    return 0;
                  }
                  return 0;
                })
                .map(
                  (bed) =>
                    bed.unit && (
                      <Bed
                        key={bed.key}
                        bed={bed}
                        handleSelectBedForDelete={handleSelectBedForDelete}
                        deleteBeds={deleteBeds}
                        selectedBeds={selectedBeds}
                      />
                    )
                )}
            </div>
            {unAffectedBeds.length > 0 && (
              <Typography>Lits non affectés {unAffectedBeds.length}</Typography>
            )}
            <Divider />
            <div className="workspaceBeds__container">
              {workspace?.beds
                ?.concat()
                .sort((a, b) => {
                  if (a.name && b.name) {
                    if (a.name.toLowerCase() < b.name.toLowerCase()) {
                      return -1;
                    }
                    if (a.name.toLowerCase() > b.name.toLowerCase()) {
                      return 1;
                    }
                    return 0;
                  }
                  return 0;
                })
                .map(
                  (bed) =>
                    !bed.unit && (
                      <Bed
                        key={bed.key}
                        bed={bed}
                        handleSelectBedForDelete={handleSelectBedForDelete}
                        deleteBeds={deleteBeds}
                        selectedBeds={selectedBeds}
                      />
                    )
                )}
            </div>
          </>
        ) : (
          <div className="workspaceBeds__noBed">
            <div className="workspaceBeds__noBed--content">
              <Typography>
                Vous n'avez pas encore de lit dans votre espace
              </Typography>
              <ButtonValid
                title="Ajouter des lits"
                onClick={handleOpenAddBedsModal}
                style={{ marginTop: 30 }}
              />
            </div>
          </div>
        )}
      </div>
      <Dialog
        open={openAddBeds}
        onClose={handleCloseAddsBedsModal}
        aria-labelledby="form-dialog-title"
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle id="form-dialog-title">Ajouter des lits</DialogTitle>
        <Divider />
        <DialogContent>
          <div className="unit__addDialog">
            <div className="unit__addDialog--field ">
              <TextField
                name="count"
                label="Nombre de lits à ajouter"
                type="number"
                className="unit__field"
                variant="outlined"
                size="small"
                value={beds.count}
                onChange={handleChangeBedToAdd}
                fullWidth
              />
              <TextField
                name="startAt"
                label="Index de départ"
                type="number"
                className="unit__field"
                variant="outlined"
                size="small"
                value={beds.startAt}
                onChange={handleChangeBedToAdd}
                fullWidth
              />
            </div>
            <div className="unit__addDialog--field">
              <FormControlLabel
                control={
                  <Checkbox
                    checked={checked}
                    onChange={() => {
                      setChecked((c) => !c);
                      setBeds({
                        ...beds,
                        name: "",
                      });
                    }}
                    name="prefix"
                  />
                }
                label="Ajouter un préfixe ( Nom )"
                className="createWorkspace__fields--field"
              />

              <FormControl
                variant="outlined"
                className="createWorkspace__fields"
                fullWidth
                style={{ width: "48%" }}
              >
                <InputLabel htmlFor="outlined-age-native-simple2">
                  Ajouter dans une unité
                </InputLabel>
                <Select
                  native
                  value={beds.unit}
                  onChange={handleChangeBedToAdd}
                  label="Ajouter dans une unité"
                  inputProps={{
                    name: "unit",
                    id: "outlined-age-native-simple2",
                  }}
                  size="small"
                >
                  <option aria-label="None" value="" />
                  {workspace?.units &&
                    workspace?.units.length > 0 &&
                    workspace?.units.map((unit) => (
                      <option value={unit.key} key={unit.key}>
                        {unit.name}
                      </option>
                    ))}
                </Select>
              </FormControl>
            </div>
            {checked && (
              <div className="unit__addDialog--field">
                <TextField
                  error={error}
                  name="name"
                  label="Nom des lits"
                  type="text"
                  className="unit__field"
                  variant="outlined"
                  size="small"
                  value={beds.name}
                  onChange={handleChangeBedToAdd}
                  helperText="Si prefixe coché, les lits seront préfixés du nom, sinon le nom des lits seront l'index de départ incrémenté (20,21,22...)"
                  style={{ width: "48%" }}
                  required
                />
              </div>
            )}
          </div>
        </DialogContent>
        <Divider />
        <DialogActions>
          <Button onClick={handleCloseAddsBedsModal} style={{ width: "50%" }}>
            Annuler
          </Button>
          <ButtonValid
            onClick={handleSubmitAddBeds}
            title="Confirmer"
            style={{ width: "50%" }}
          />
        </DialogActions>
      </Dialog>
      {/* DELETE MODAL */}
      <Dialog
        open={openDeleteModal}
        onClose={handleCloseDeleteModal}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">
          {selectedBeds.length > 0 && "Lits"}
        </DialogTitle>
        <DialogContent>
          <div className="unit__dialog">
            {selectedBeds.length > 0 && (
              <Typography>
                Confirmer la suppression des lits selectionnés ?
              </Typography>
            )}
          </div>
        </DialogContent>
        <Divider />
        <DialogActions>
          <Button onClick={handleCloseDeleteModal} style={{ width: "50%" }}>
            Annuler
          </Button>
          <ButtonValid
            onClick={handleSubmit}
            title="Confirmer"
            style={{ width: "50%" }}
          />
        </DialogActions>
      </Dialog>
    </>
  );
}

export default WorkspaceBeds;
