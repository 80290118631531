// REACT
import React, { useRef } from "react";
// REACT ROUTER DOM
import { useHistory } from "react-router-dom";
// MATERIAL UI
import { Tooltip } from "@material-ui/core";
import PowerSettingsNewIcon from "@material-ui/icons/PowerSettingsNew";
import SearchIcon from "@material-ui/icons/Search";
// COMPONENTS
import UserCard from "../../components/UserCard/UserCard";
import Loading from "../../components/Loading/Loading";
// HOOKS
import useGetPagedUsersByPage from "../../hooks/useGetPagedUsersByPage";
// REDUX
import { useDispatch } from "react-redux";
import { setUser } from "../../redux/user/actions";
import { logout } from "../../redux/auth/thunks";
import { getUser } from "../../redux/user/thunks";

/**
 * User selection page
 * @returns Components
 */
function SelectUser() {
  // REDUX HOOK
  const dispatch = useDispatch();
  // REACT ROUTER HOOKS
  const history = useHistory();

  // REACT HOOKS
  const scrollRef = useRef(null);
  // CUSTOM HOOKS
  const { users, loading, loadingPagedUsers, setSearch, lineRef } =
    useGetPagedUsersByPage(scrollRef);

  /**
   * Disconnect logged user
   */
  const disconnect = () => {
    dispatch(logout());
    history.push("/");
  };

  /**
   * Set search state
   */
  const handleSearch = (e) => {
    setSearch(e.target.value);
  };

  const handleSelectUser = (user) => {
    dispatch(getUser(user.key)).then((res) => {
      if (res.status === 200) {
        dispatch(setUser(res.data));
        history.push("/dashboard");
      }
    });
  };

  return (
    <div className="selectUser">
      <div className="selectUser__searchContainer">
        <SearchIcon className="selectUser__search" />
        <input
          type="search"
          name=""
          id=""
          onChange={handleSearch}
          className="selectUser__input"
          placeholder="Rechercher un utilisateur..."
        />
      </div>
      <div className="selectUser__usersResult">
        {loading ? (
          <Loading />
        ) : (
          <>
            {users?.map((user) => (
              <UserCard
                key={user.key}
                user={user}
                speciality
                onClick={() => handleSelectUser(user)}
                lineRef={lineRef}
              />
            ))}
            {loadingPagedUsers && <Loading />}
          </>
        )}
      </div>
      <div className="selectUser__logout">
        <Tooltip title="Déconnexion" placement="left" style={{ zIndex: 999 }}>
          <PowerSettingsNewIcon
            onClick={disconnect}
            style={{ color: "#fff", fontSize: "2rem" }}
          />
        </Tooltip>
      </div>
    </div>
  );
}

export default SelectUser;
