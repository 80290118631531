import React from "react";

export const Plus = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="36"
      height="36"
      viewBox="0 0 16 16"
    >
      <path
        fill="#FFF"
        fillRule="nonzero"
        d="M7.257.25V3h1.5v4.25H13v1.5H8.75V13h-1.5V8.75H3v-1.5h4.25z"
      />
    </svg>
  );
};
