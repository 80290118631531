// REACT
import React, { useEffect, useRef, useState } from "react";
// COMPONENTS
import SubHeader from "../../components/SubHeader/SubHeader";
// MATERIAL UI
import SearchIcon from "@material-ui/icons/Search";
import ClearIcon from "@material-ui/icons/Clear";
//REDUX
import { useDispatch } from "react-redux";
import { searchWorkspace } from "../../redux/workspaces/thunks";
import WorkspaceToLink from "../../components/Workspaces/WorkspaceToLink/WorkspaceToLink";
import ButtonValid from "../../components/Buttons/ButtonValid";
import Loading from "./../../components/Loading/Loading";
import { CircularProgress } from "@material-ui/core";
import { updateWorkspacesCrossLinks } from "./../../redux/workspaces/thunks";
import { setSnackbar } from "../../redux/snackbar/actions";

function Associations() {
  // REDUX HOOKS
  const dispatch = useDispatch();
  // REACT STATE
  const [search, setSearch] = useState("");
  const [workspaces, setWorkspaces] = useState([]);
  const [selectedWorkspaces, setSelectedWorkspaces] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loadingSubmit, setLoadingSubmit] = useState(false);

  let timeout = useRef(null);
  useEffect(() => {
    let mounted = true;
    if (mounted) {
      if (search.trim().length !== 0) {
        setLoading(true);
        timeout.current = setTimeout(() => {
          dispatch(searchWorkspace(search)).then((res) => {
            if (res.status === 200) {
              setWorkspaces(res.data.workspaces);
              setLoading(false);
            }
          });
        }, 1000);
      } else {
        setWorkspaces([]);
        setLoading(false);
      }
    }

    return () => {
      mounted = false;
      clearTimeout(timeout.current);
    };
  }, [search, dispatch]);

  const handleSelectWorkspace = (workspace) => {
    const selectedWorkspace = selectedWorkspaces.findIndex(
      (sw) => sw.key === workspace.key
    );
    if (selectedWorkspace !== -1) {
      const filteredWorkspaces = selectedWorkspaces.filter(
        (sw) => sw.key !== workspace.key
      );

      setSelectedWorkspaces(filteredWorkspaces);
    } else {
      setSelectedWorkspaces([...selectedWorkspaces, workspace]);
    }
  };

  const handleAssociateWorkspaces = () => {
    const selectedWorkspaceKey = selectedWorkspaces.map(
      (workspace) => workspace.key
    );
    const workspaceCrossLinksParameter = {
      links: selectedWorkspaceKey,
    };
    setLoadingSubmit(true);
    dispatch(updateWorkspacesCrossLinks(workspaceCrossLinksParameter)).then(
      (res) => {
        if (res.status === 200) {
          dispatch(
            setSnackbar({
              open: true,
              snackbarMessage: "Les espaces ont bien étés associés entre eux",
            })
          );
          setWorkspaces([]);
          setSelectedWorkspaces([]);
          setLoadingSubmit(false);
        }
      }
    );
  };

  return (
    <div className="associations">
      <SubHeader modifier="subHeader__association">
        <div className="subHeader__title">
          <div className="associations__searchInput">
            {loading ? (
              <CircularProgress size={25} style={{ marginRight: 5 }} />
            ) : (
              <SearchIcon color="primary" />
            )}

            <input
              type="search"
              name=""
              id=""
              value={search}
              onChange={(e) => setSearch(e.target.value)}
              placeholder="Rechercher les espaces à associer..."
            />
          </div>
        </div>
      </SubHeader>
      <div className="associations__workspaces">
        <div className="associations__workspacesToLink">
          {workspaces.map((workspace) => (
            <WorkspaceToLink
              workspace={workspace}
              handleSelectWorkspace={handleSelectWorkspace}
              selectedWorkspaces={selectedWorkspaces}
            />
          ))}
        </div>
        <div className="associations__selectedWorkspaces">
          {selectedWorkspaces.length > 0 && (
            <div style={{ position: "sticky", top: 0 }}>
              {loadingSubmit ? (
                <CircularProgress />
              ) : (
                <ButtonValid
                  title="Associer les espaces selectionnés"
                  style={{ width: "100%" }}
                  onClick={handleAssociateWorkspaces}
                />
              )}
            </div>
          )}
          {selectedWorkspaces.map((workspace) => (
            <div
              className="associations__workspace"
              onClick={() => handleSelectWorkspace(workspace)}
            >
              <p>{workspace.title}</p>
              <ClearIcon style={{ color: "red" }} />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default Associations;
