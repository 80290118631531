// REACT
import React from "react";
// REACT ROUTER
import { NavLink } from "react-router-dom";

function SidebarBuilding({ buildingKey }) {
  return (
    <nav className="sidebarBuilding">
      <ul>
        <li className="sidebarBuilding__item">
          <NavLink exact to={`/building/${buildingKey}`}>
            Informations
          </NavLink>
        </li>
        <li className="sidebarBuilding__item">
          <NavLink exact to={`/building/${buildingKey}/workspaces`}>
            Espaces
          </NavLink>
        </li>
        <li className="sidebarBuilding__item">
          <NavLink exact to={`/building/${buildingKey}/buildingList`}>
            Etablissements rattachés
          </NavLink>
        </li>
        <li className="sidebarBuilding__item">
          <NavLink exact to={`/building/${buildingKey}/addBuilding`}>
            Ajouter un établissement
          </NavLink>
        </li>
      </ul>
    </nav>
  );
}

export default SidebarBuilding;
