import React from "react";

function WorkspaceToLink({
  workspace,
  handleSelectWorkspace,
  selectedWorkspaces,
}) {
  const isSelected = selectedWorkspaces.find((sw) => sw.key === workspace.key);

  return (
    <div
      onClick={() => handleSelectWorkspace(workspace)}
      className="workspaceToLink"
      style={{
        background: !!isSelected ? "#29b6f6" : "",
        color: !!isSelected ? "#fff" : "inherit",
      }}
    >
      <p className="workspaceToLink__name">{workspace.title} </p>
      {workspace.buildingNames && (
        <span
          className="workspaceToLink__buildings"
          style={{
            background: !!isSelected ? "#29b6f6" : "",
            color: !!isSelected ? "#fff" : "#80808062",
          }}
        >
          ({workspace.buildingNames.map((building) => building).join(", ")})
        </span>
      )}
    </div>
  );
}

export default WorkspaceToLink;
