import React, { useState } from "react";

//COMPONENTS
import CropAvatar from "../../CropAvatar/CropAvatar";

// MATERIAL UI
import {
  makeStyles,
  Typography,
  IconButton,
  Popover,
  DialogContentText,
  Button,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import PhotoCameraIcon from "@material-ui/icons/PhotoCamera";
import AddAPhotoIcon from "@material-ui/icons/AddAPhoto";
import CheckIcon from "@material-ui/icons/Check";
import { withStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";

function AvatarBuilding({
  openPopover,
  openConfirmAvatarChange,
  openDeleteAvatarModal,
  setOpenDeleteAvatarModal,
  anchorImage,
  inputImg,
  url,
  id,
  open,
  handleClick,
  handleClosePopOver,
  handleDeleteImage,
  handleCloseDialog,
  handleOpenDeleteAvatarConfirmModal,
  handleCloseConfirm,
  handleCancel,
  handleSubmitImage,
  handleSubmitUpdateAvatar,
  handleSubmitDeleteImage,
  onInputChange,
  getBlob,
  children,
}) {
  // REACT STATE
  const [displayAvatarHelpText, setDisplayAvatarHelpText] = useState("none");
  // MATERIAL HOOKS
  const classes = useStyles({ displayAvatarHelpText });

  return (
    <>
      {url ? (
        <div className={classes.formInputFile}>
          <div
            className={classes.containerOverlay}
            onMouseOver={() => setDisplayAvatarHelpText("flex")}
            onMouseLeave={() => setDisplayAvatarHelpText("none")}
          >
            <div className={classes.overlay} onClick={handleClick}>
              <Typography className={classes.overlayText}>
                Cliquez pour changer ou supprimer l'avatar
              </Typography>
            </div>
            <img
              src={url}
              alt=""
              className={classes.inputFileLabel}
              aria-describedby={id}
            />
          </div>

          <Popover
            id={id}
            open={openPopover}
            anchorEl={anchorImage}
            onClose={handleClosePopOver}
            anchorOrigin={{
              vertical: "center",
              horizontal: "right",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "left",
            }}
            classes={{ paper: classes.paper }}
          >
            <Typography>
              <form>
                <label htmlFor="file" className={classes.typography}>
                  Modifier l'avatar
                </label>
                <input
                  type="file"
                  id="file"
                  accept="image/*"
                  onChange={(e) => {
                    //here
                    onInputChange(e);
                    handleClosePopOver();
                  }}
                  className={classes.inputFile}
                />
              </form>
            </Typography>
            <Typography
              className={classes.typography}
              onClick={
                !!handleOpenDeleteAvatarConfirmModal
                  ? handleOpenDeleteAvatarConfirmModal
                  : handleDeleteImage
              }
            >
              Supprimer l'avatar
            </Typography>
          </Popover>
        </div>
      ) : (
        <form className={classes.formInputFile}>
          <label htmlFor="file" className={classes.inputFileLabel}>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                flexDirection: "column",
              }}
            >
              <PhotoCameraIcon />
              <Typography style={{ width: 100, textAlign: "center" }}>
                {children[0]}
              </Typography>
            </div>
          </label>
          <input
            type="file"
            id="file"
            accept="image/*"
            onChange={onInputChange}
            className={classes.inputFile}
          />
        </form>
      )}
      {/*Moddal to choose img and resiez with crop avatar */}
      <Dialog
        onClose={handleCloseDialog}
        aria-labelledby="customized-dialog-title"
        open={open}
        maxWidth="lg"
      >
        <DialogTitle id="customized-dialog-title" onClose={handleCloseDialog}>
          <div style={{ display: "flex", alignItems: "center" }}>
            <form>
              <label
                htmlFor="file"
                style={{ cursor: "pointer" }}
                className={classes.textPhotoIcon}
              >
                <AddAPhotoIcon style={{ marginRight: 10 }} />
                Selectionner une autre image
              </label>
              <input
                type="file"
                id="file"
                accept="image/*"
                onChange={onInputChange}
                className={classes.inputFile}
              />
            </form>
          </div>
        </DialogTitle>
        <DialogContent dividers style={{ width: 600 }}>
          <div style={{ position: "relative", width: "100%", height: "350px" }}>
            {inputImg && <CropAvatar getBlob={getBlob} inputImg={inputImg} />}
          </div>
        </DialogContent>
        <DialogActions>
          <IconButton
            onClick={handleSubmitImage}
            style={{ background: "#26a69a", color: "#fff" }}
          >
            <CheckIcon />
          </IconButton>
        </DialogActions>
      </Dialog>
      {/**Confirm Modal Update Avatar */}
      <Dialog
        open={openConfirmAvatarChange}
        onClose={handleCloseConfirm}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Changer d'avatar"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Confirmez-vous le changement d'avatar {children[1]} ?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancel} color="primary">
            Annuler
          </Button>
          <Button onClick={handleSubmitUpdateAvatar} color="primary" autoFocus>
            Confirmer
          </Button>
        </DialogActions>
      </Dialog>
      {/**Confirm Modal Delete Avatar */}
      <Dialog
        open={openDeleteAvatarModal}
        onClose={() => setOpenDeleteAvatarModal(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Supprimer l'avatar"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Confirmez-vous la suppression de l'avatar {children[1]} ?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => setOpenDeleteAvatarModal(false)}
            color="primary"
          >
            Annuler
          </Button>
          <Button onClick={handleSubmitDeleteImage} color="primary" autoFocus>
            Confirmer
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

/**
 * Component style with material ui
 */
const useStyles = makeStyles(({ palette }) => {
  return {
    "@keyframes avatarHelp": {
      "0%": {
        opacity: 0,
      },
      "100%": {
        opacity: 1,
      },
    },
    textPhotoIcon: {
      transition: "all ease 0.2s",
      "&:hover": {
        filter: "brightness(0%)",
      },
    },
    inputContainer: {
      padding: "10px 10px 0 10px",
    },
    inputLabel: {
      color: palette.greyDarkStd,
      fontFamily: "Overpass",
      fontSize: "0.85rem",
      padding: 10,
      borderBottom: "1px solid #0000001a",
    },
    formInputFile: {
      height: 210,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      color: "#fff",
      margin: "15px 0",
      padding: "3px",
      borderRadius: "100%",
    },
    inputFileLabel: {
      cursor: "pointer",
      width: 200,
      height: "100%",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      borderRadius: "100%",
      background: "#ccc",
      objectFit: "cover",
    },
    inputFile: {
      display: "none",
    },
    typography: {
      padding: 5,
      width: "100%",
      cursor: "pointer",
      "&:hover": {
        background: "#eee",
      },
    },
    paper: {
      padding: "10px 0",
    },
    popoverOverlay: {
      pointerEvents: "none",
    },
    paperOverlay: {
      pointerEvents: "auto",
      padding: "0.5rem",
    },
    containerOverlay: {
      position: "relative",
      cursor: "pointer",
      height: "100%",
    },
    overlay: {
      position: "absolute",
      top: 0,
      bottom: 0,
      right: 0,
      left: 0,
      background: "rgba(0,0,0,0.8)",
      borderRadius: "50%",
      alignItems: "center",
      justifyContent: "center",
      animation: "$avatarHelp .6s ease",
      display: ({ displayAvatarHelpText }) =>
        displayAvatarHelpText && displayAvatarHelpText,
    },
    overlayText: {
      textAlign: "center",
    },
  };
});

const styles = (theme) => ({
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

export default AvatarBuilding;
