// REACT
import React, { useState } from "react";
// CONFIG
import { WHITE_LIST } from "../../config/settings";
// MATERIAL UI
import {
  FormControl,
  FormControlLabel,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  Radio,
  RadioGroup,
  TextField,
  Typography
} from "@material-ui/core";
import { Visibility, VisibilityOff } from "@material-ui/icons";
import ButtonValid from "../../components/Buttons/ButtonValid";
// REACT ROUTER DOM
import { useHistory } from "react-router-dom";
// REDUX
import { useDispatch } from "react-redux";
import { login } from "../../redux/auth/thunks";
import { setAuthData } from "../../redux/auth/actions";

/**
 * Login page
 * @returns Component
 */
function Login() {
  // REDUX HOOKS
  const dispatch = useDispatch();
  // REACT ROUTER HOOKS
  const history = useHistory();
  // REACT HOOKS
  const [disabled, setDisabled] = useState(true);
  const [error, setError] = useState(null);
  const [identifier, setTidentifier] = useState({
    login: "",
    password: "",
    api: "preprod",
    showPassword: false,
  });

  /**
   * Function that allow to show the password
   */
  const handleClickShowPassword = () => {
    setTidentifier({ ...identifier, showPassword: !identifier.showPassword });
  };

  /**
   * Function that allow to prevent form submition when show password is clicked
   */
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  /**
   *  Function that allow to change value of different fields
   * @param {*string} name name of field
   * @param {*string} value value of field
   */
  const handleChange = ({ currentTarget: { name, value } }) => {
    // We make copy of current state to work in an immutable way
    const updatedLogin = { ...identifier };
    // If field is login
    if (name === "login") {
      // update login state
      updatedLogin[name] = value;
      // we check if the value is contained in WHITE_LIST, if it is we enable radio button
      if (WHITE_LIST.includes(value)) {
        setDisabled(false);
      } else {
        // if not we disable them, and set the radio button to preprod
        setDisabled(true);
        setTidentifier({ ...identifier, api: "preprod" });
      }
    }
    // update state for other fields
    updatedLogin[name] = value;
    setTidentifier(updatedLogin);
  };

  /**
   * Send data to api
   * @param {*Event} e Submition event
   */
  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(login(identifier))
      .then((res) => {
        if (res.status === 200) {
          dispatch(
            setAuthData({
              auth: res.data,
              authenticate: true,
              api: identifier.api,
            })
          );
          history.replace("/select-user");
        } else {
          setError(true);
        }
      })
      .catch((err) => {
        console.log("ERROR", err);
        setError(err.message);
      });
  };

  return (
    <div className="login">
      <div className="login__container">
        {/* <div className="logo">
			        <img src="./public/img/logo-login.png" className="img-responsive" alt="Logo login page">
			    </div> */}
        <form className="login__form" onSubmit={handleSubmit}>
          <h5>
            Bienvenue sur le Back-Office
            <br />
            de Team’Doc !
          </h5>
          {error && (
            <Typography
              style={{ color: "red", textAlign: "center", margin: "5px 0" }}
            >
              Identifiant incorrect
            </Typography>
          )}
          <div className="customInput">
            <TextField
              label="Login"
              type="text"
              variant="outlined"
              name="login"
              value={identifier.login}
              onChange={handleChange}
              className="login__input"
            />
          </div>
          <FormControl className="customInput" variant="outlined">
            <InputLabel htmlFor="outlined-adornment-password">
              Mot de passe
            </InputLabel>
            <OutlinedInput
              label="Mot de passe"
              type={identifier.showPassword ? "text" : "password"}
              variant="outlined"
              name="password"
              value={identifier.password}
              onChange={handleChange}
              className="login__input"
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                  >
                    {identifier.showPassword ? (
                      <Visibility />
                    ) : (
                      <VisibilityOff />
                    )}
                  </IconButton>
                </InputAdornment>
              }
              labelWidth={70}
            />
          </FormControl>
          <RadioGroup
            row
            aria-label="position"
            name="position"
            defaultValue="top"
            value={identifier.api}
            onChange={handleChange}
            style={{ justifyContent: "space-between", marginBottom: 15 }}
          >
            <FormControlLabel
              value="dev"
              control={
                <Radio
                  name="api"
                  value="dev"
                  color="primary"
                  disabled={disabled}
                />
              }
              label="Dev"
            />
            <FormControlLabel
              value="preprod"
              control={
                <Radio
                  name="api"
                  value="preprod"
                  color="primary"
                  disabled={disabled}
                />
              }
              label="Preprod"
            />
            <FormControlLabel
              value="prod"
              control={
                <Radio
                  name="api"
                  value="prod"
                  color="primary"
                  disabled={disabled}
                />
              }
              label="Prod"
            />
          </RadioGroup>
          {/* <Button type="submit" variant="contained" fullWidth color="primary">
            Se connecter
          </Button> */}
          <ButtonValid
            type="submit"
            title="Se connecter"
            style={{ width: "100%" }}
          />
        </form>
      </div>
    </div>
  );
}

export default Login;
