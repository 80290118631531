import axios from "axios";
import { BOWORKSPACES } from "../../api/api";
import { setWorkspaces } from "./actions";
import { BOUNITS, BOBEDS } from "./../../api/api";

export const createWorkspace = (workspace) => async (dispatch, getState) => {
  try {
    const workspaceCreated = await axios.post(
      BOWORKSPACES.workspaces,
      { ...workspace },
      {
        headers: {
          sid: getState().auth.authData.sid,
          key: getState().user.user.key,
        },
      }
    );
    if (workspaceCreated.status === 200) {
      return workspaceCreated;
    }
  } catch (error) {
    console.error(error);
  }
};

export const updateWorkspace =
  (workspaceKey, workspace) => async (dispatch, getState) => {
    try {
      const workspaceUpdated = await axios.put(
        `${BOWORKSPACES.workspaces}${workspaceKey}`,
        workspace,
        {
          headers: {
            sid: getState().auth.authData.sid,
          },
        }
      );
      if (workspaceUpdated.status === 200) {
        return workspaceUpdated;
      }
    } catch (error) {
      console.error(error);
    }
  };

export const deleteWorkspace = (workspaceKey) => async (dispatch, getState) => {
  try {
    const workspaceDeleted = await axios.delete(
      `${BOWORKSPACES.workspaces}${workspaceKey}`,
      {
        headers: {
          sid: getState().auth.authData.sid,
        },
      }
    );
    if (workspaceDeleted.status === 200) {
      return workspaceDeleted;
    }
  } catch (error) {
    console.error(error);
  }
};

export const getWorkspaces = () => async (dispatch, getState) => {
  try {
    const workspaces = await axios(BOWORKSPACES.workspaces, {
      headers: {
        sid: getState().auth.authData.sid,
        key: getState().user.user.key,
      },
    });

    if (workspaces.status === 200) {
      dispatch(setWorkspaces(workspaces.data));
      return workspaces;
    }
  } catch (error) {
    console.error(error);
  }
};

export const deleteUnitsInWorkspace = (units) => async (dispatch, getState) => {
  try {
    const deletedUnits = await axios.delete(`${BOUNITS.deleteUnits}`, {
      headers: {
        sid: getState().auth.authData.sid,
      },
      data: units,
    });
    if (deletedUnits.status === 200) {
      return deletedUnits;
    }
  } catch (error) {
    console.error(error);
  }
};

export const deleteBedsInWorkspace = (beds) => async (dispatch, getState) => {
  try {
    const deletedBeds = await axios.delete(BOBEDS.deleteBeds, {
      headers: {
        sid: getState().auth.authData.sid,
      },
      data: beds,
    });
    if (deletedBeds.status === 200) {
      return deletedBeds;
    }
  } catch (error) {
    console.error(error);
  }
};

export const createUnits =
  (unitsParameter, workspaceKey) => async (dispatch, getState) => {
    try {
      const units = await axios.post(
        `${BOUNITS.units}${workspaceKey}/units`,
        unitsParameter,
        {
          headers: {
            sid: getState().auth.authData.sid,
          },
        }
      );
      if (units.status === 200) {
        return units;
      }
    } catch (error) {
      console.error(error);
    }
  };

export const createBeds =
  (bedsParameter, workspaceKey) => async (dispatch, getState) => {
    try {
      const beds = await axios.post(
        `${BOBEDS.beds}${workspaceKey}/beds`,
        bedsParameter,
        {
          headers: {
            sid: getState().auth.authData.sid,
          },
        }
      );
      if (beds.status === 200) {
        return beds;
      }
    } catch (error) {
      console.error(error);
    }
  };

export const updateUnit = (unit) => async (dispatch, getState) => {};

export const updateBed = (bedParameter) => async (dispatch, getState) => {
  try {
    const updatedBed = await axios.put(BOBEDS.beds, bedParameter, {
      headers: {
        sid: getState().auth.authData.sid,
      },
    });
    if (updatedBed.status === 200) {
      return updatedBed;
    }
  } catch (error) {
    console.error(error);
  }
};

export const getWorkspaceUsers =
  (workspaceKey) => async (dispatch, getState) => {
    try {
      const users = await axios(
        `${BOWORKSPACES.workspaces}${workspaceKey}/users`,
        {
          headers: {
            sid: getState().auth.authData.sid,
          },
        }
      );
      if (users.status === 200) {
        return users;
      }
    } catch (error) {
      console.error(error);
    }
  };

export const searchWorkspace = (workspace) => async (dispatch, getState) => {
  try {
    const workspaces = await axios(BOWORKSPACES.search, {
      headers: {
        sid: getState().auth.authData.sid,
      },
      params: {
        keywords: workspace,
        page: 0,
        size: 50,
      },
    });
    if (workspaces.status === 200) {
      return workspaces;
    }
  } catch (error) {
    console.error(error);
  }
};

export const updateWorkspacesCrossLinks =
  (linksParameter) => async (dispatch, getState) => {
    try {
      const links = await axios.put(
        BOWORKSPACES.updateWorkspacesCrossLinks,
        linksParameter,
        {
          headers: {
            sid: getState().auth.authData.sid,
          },
        }
      );
      if (links.status === 200) {
        return links;
      }
    } catch (error) {
      console.error(error);
    }
  };

export const getWorkspaceLinks = (workspace) => async (dispatch, getState) => {
  try {
    const workspaceCrossLinks = await axios(
      `${BOWORKSPACES.getWorkspaceLinks}${workspace}`,
      {
        headers: {
          sid: getState().auth.authData.sid,
        },
      }
    );
    if (workspaceCrossLinks.status === 200) {
      return workspaceCrossLinks;
    }
  } catch (error) {
    console.error(error);
  }
};

export const deleteWorkspacesLinks =
  (workspaceKey, linksParameter) => async (dispatch, getState) => {
    try {
      const links = await axios.delete(
        `${BOWORKSPACES.updateWorkspacesCrossLinks}${workspaceKey}`,
        {
          headers: {
            sid: getState().auth.authData.sid,
          },
          data: linksParameter,
        }
      );
      if (links.status === 200) {
        return links;
      }
    } catch (error) {
      console.error(error);
    }
    // axios
    //   .delete(
    //     `${BOWORKSPACES.updateWorkspacesCrossLinks}${workspaceKey}`,
    //     { ...linksParameter },
    //     {
    //       headers: {
    //         sid: getState().auth.authData.sid,
    //       },
    //     }
    //   )
    //   .then((res) => {
    //     if (res.status === 200) {
    //       return res;
    //     }
    //   });
  };

export const changeWorkspaceOwner =
  (workspace, owner) => async (dispatch, getState) => {
    try {
      const newOwner = await axios.post(
        `${BOWORKSPACES.workspaces}${workspace}/change/${owner}`,
        {},
        {
          headers: {
            sid: getState().auth.authData.sid,
          },
        }
      );
      if (newOwner.status === 200) {
        return newOwner;
      }
    } catch (error) {
      console.error(error);
    }
  };
