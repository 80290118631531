import axios from "axios";
import { BOUNITS } from "../../api/api";

export const updateUnit = (unit) => async (dispatch, getState) => {
  try {
    const updatedUnit = await axios.put(BOUNITS.units, unit, {
      headers: {
        sid: getState().auth.authData.sid,
      },
    });
    if (updatedUnit.status === 200) {
      return updatedUnit;
    }
  } catch (error) {
    console.error(error);
  }
};
