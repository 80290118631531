import { createSlice } from "@reduxjs/toolkit";

// intialState
const ressourcesInitialState = {
  ressourcesLoading: false,
  initRessources: null,
  specialities: null,
};

// Slice ressources
export const ressourcesSlice = createSlice({
  name: "ressources", // name in redux dev tools
  initialState: ressourcesInitialState,
  reducers: {
    isLoading: (state, { payload }) => {
      state.ressourcesLoading = payload;
    },
    setInitRessources: (state, { payload }) => {
      state.initRessources = payload;
    },
    setSpeciality: (state, { payload }) => {
      state.specialities = payload;
    },
    clearRessources: (state) => {
      state.ressourcesLoading = null;
      state.specialities = null;
    },
  },
});

/**
 * Return the state of the specialities
 * @param {*Object} state current specialities state
 * @returns Object
 */
export const getSpecialities = (state) => state.ressources.specialities;
/**
 * Return the state of the initRessources
 * @param {*Object} state current initRessources state
 * @returns Object
 */
export const getRessources = (state) => state.ressources.initRessources;
